import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Alert({ color, title, icon, children, className }) {
    return (
        <div
            className={`p-4 bg-${color}-100 text-${color}-700 rounded-md shadow text-left ${className}`}
        >
            <div className="flex flex-row space-x-4 items-center">
                {icon && (
                    <div className="flex-shrink-0">
                        <FontAwesomeIcon fixedWidth icon={icon} />
                    </div>
                )}
                <div className="flex-grow">
                    {title && <div className="mb-1 font-bold">{title}</div>}
                    {children}
                </div>
            </div>
        </div>
    );
}

Alert.defaultProps = {
    color: "red",
    title: null,
    icon: null,
    className: ""
};

Alert.propTypes = {
    color: PropTypes.string.isRequired,
};

export default Alert;
