import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import moment from "moment";
import toast from "react-hot-toast";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";
import Container from "../../components/Layout/Container";
import CourseRosterToggle from "../../components/CellRenderers/Courses/CourseRosterToggle";
import getDatesBetween from "../../util/getDatesBetween";
import { get } from "lscache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button/Button";
import RosterExport from "../../components/RosterExport/RosterExport";

registerLocale("en-gb", enGb);

function CoursesRoster(props) {
  const [courseInformation, setCourseInformation] = useState(null);
  const [course, setCourse] = useState(null);
  const [tableApi, setTableApi] = useState(null);

  const startDate = course ? moment(course.course.startDate) : moment('10/10/2010');
  const duration = course ? course.curriculum.duration : 3;
  const endDate = course ? moment(course.course.startDate).add(duration, "d") : moment('10/10/2010').add(duration, "d");

  const dateArray = getDatesBetween(startDate, endDate, false);


  const getCourse = () => {
    axios
      .get(
        `${process.env.REACT_APP_API}/courses/${props.match.params.id}.json`
      )
      .then(({ data }) => {
        setCourseInformation(data);
        setCourse(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(getCourse, [props.match.params.id]);

  const formatStudentList = () => {
    if (course) {
      return course.students.map((student) => {
        return {
          id: student.id,
          student_id: student.user_id,
          user_id: student.uid,
          course_id: course.course.id,
          student_name: student.name,
          company_name: student.company,
          email: student.email,
          roster: course.roster.filter((r) => r.user_id === student.user_id).map((r) => {
            return {
              id: r.id,
              date: r.date,
              am: r.am,
              pm: r.pm,
            }
          }),
        };
      });
    } else {
      return [];
    }
  };

  const [formattedStudentList, setFormattedStudentList] = useState(formatStudentList());

  useEffect(() => {
    console.log("attempting to redraw rows...");
    setFormattedStudentList(formatStudentList());
    setTimeout(() => {
      tableApi?.redrawRows();
    }, 100);
  }, [course]);

  const formatColumns = () => {

    let columnDefs = [
      {
        headerName: "Student",
        field: "student_name",
        sort: "asc",
      },
      {
        headerName: "Company",
        field: "company_name",
      }
    ];

    dateArray.forEach((value) => {
      columnDefs.push({
        headerName: value,
        headerComponentParams: {
          template:
            '<div class="ag-cell-label-container" role="presentation">' +
            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
            "    <span>" +
            '      <div class="block">' +
            '        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
            "      </div>" +
            '      <div class="flex justify-between border-t border-gray-700 mt-1 pt-1">' +
            "        <div>AM</div>" +
            "        <div>PM</div>" +
            "      </div>" +
            "    </span>" +
            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
            "  </div>" +
            "</div>",
        },
        field: "am",
        cellRendererFramework: CourseRosterToggle,
        cellRendererParams: {
          date: value,
          getCourse: getCourse,
        },
      });
    });

    return columnDefs;
  };

  /**
   * Set up the gridOptions for the table
   */
  const gridOptions = {
    getRowNodeId(data) {
      return data.id;
    },
    paginationPageSize: 50,
    columnDefs: formatColumns(),
  };

  const gridWaiting = {
    getRowNodeId(data) {
      return data.id;
    },
    paginationPageSize: 25,
    columnDefs: [
      {
        headerName: "Email",
        field: "email"
      }
    ]

  }

  return (
    <div className="w-full">
      <Helmet>
        <title>Course Roster</title>
      </Helmet>

      <Breadcrumbs
        noMarginBottom
        crumbs={[
          {
            name: "Home",
            to: "/",
          },
          {
            name: "Course Management",
            to: "/courses",
          },
          {
            name: "Roster",
            to: `/courses/roster/:id`,
          },
        ]}
      />

      {course ?
        <div>
          <div className="bg-teal-100 text-teal-700 w-full p-10 text-center">
            <h1 className="font-extrabold text-2xl mb-4">
              <FontAwesomeIcon
                fixedWidth
                icon={faGraduationCap}
                className="mr-3"
              />
              {courseInformation.curriculum.title}
            </h1>
            <h2 className="text-lg font-semibold leading-3">
              {courseInformation.course.customer_name}
            </h2>
            <h3>Course Roster</h3>
          </div>
          <div className="mt-4">

            <Container>
              <Panel>
                <div className="flex my-3">
                  <div className="flex-grow">
                    <h1 className="text-xl font-extrabold">
                      Registered users and attendance
                    </h1>
                  </div>
                  <div className="flex-shrink-0 flex flex-row space-x-2 my-auto">
                    <RosterExport roster={formattedStudentList} dateArray={dateArray} title={course.curriculum.title} />
                  </div>
                </div>
                <Table
                  insidePanel={true}
                  gridOptions={gridOptions}
                  rowData={formattedStudentList}
                  captureApi={setTableApi}
                />
              </Panel>
              <Panel>
                <div className="flex-grow">
                  <h1 className="text-xl font-extrabold">
                    Users who did not complete registration
                  </h1>
                  <Table
                    insidePanel={true}
                    gridOptions={gridWaiting}
                    rowData={courseInformation.waiting_registration}
                  />
                </div>

              </Panel>
            </Container>
          </div>
        </div>
        : <></>}

    </div>
  );
}

export default CoursesRoster;
