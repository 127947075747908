import React, { useState } from "react";
import { Redirect } from "react-router";
import axios from "axios";
import Button from "../../Button/Button";
import Input from "../../Form/Input";
import Panel from "../../Panel/Panel";
import Alert from "../../Alert/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegisterValidator from "../../Form/Validators/RegisterValidator";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

function RegistrationForm({ email, token }) {
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isUserIdTaken, setIsUserIdTaken] = useState(false);

    const formatFormParams = () => {
        return {
            username,
            firstName,
            surname,
            company,
            password,
            confirmPassword,
        };
    };

    const submitForm = (params) => {
        axios
            .post(`${process.env.REACT_APP_API}/user/enrol.json`, {
                token,
                username,
                firstName,
                surname,
                company,
                password,
                password2: params.confirmPassword,
            })
            .then(({ data }) => {
                if (
                    "status" in data &&
                    data.status === "Registration Completed"
                ) {
                    window.location.replace("/?message=registered");
                }

                if (
                    "status" in data &&
                    data.status === "UserID already taken"
                ) {
                    setIsUserIdTaken(true);
                }
            });
    };

    return (
        <div className="mt-5">
            <Panel>
                <div className="text-center flex flex-col space-y-3">
                    <div>
                        <p>Welcome, {email}</p>
                        <p className="text-gray-700 text-sm mt-1">
                            Please enter your details below to register. Please
                            note that the information provided here will be used
                            to generate future training completion certificates.
                        </p>
                    </div>
                    <div>
                        <p className="text-left text-sm font-semibold ml-1 mb-1">
                            Username
                        </p>
                        <Input
                            name="username"
                            id="username"
                            type="text"
                            required
                            placeholder="Username"
                            onChange={setUsername}
                            value={username}
                            autoComplete="off"
                        />
                        {isUserIdTaken && (
                            <Alert color="red" className="mt-3">
                                That username is already taken. Please try
                                another.
                            </Alert>
                        )}
                    </div>
                    <div>
                        <p className="text-left text-sm font-semibold ml-1 mb-1">
                            First Name
                        </p>
                        <Input
                            name="firstName"
                            id="firstName"
                            type="text"
                            required
                            placeholder="First Name"
                            onChange={setFirstName}
                            value={firstName}
                            autoComplete="off"
                        />
                    </div>
                    <div>
                        <p className="text-left text-sm font-semibold ml-1 mb-1">
                            Surname
                        </p>
                        <Input
                            name="surname"
                            id="surname"
                            type="text"
                            required
                            placeholder="Surname"
                            onChange={setSurname}
                            value={surname}
                            autoComplete="off"
                        />
                    </div>
                    <div>
                        <p className="text-left text-sm font-semibold ml-1 mb-1">
                            Company
                        </p>
                        <Input
                            name="company"
                            id="company"
                            type="text"
                            required
                            placeholder="Company"
                            onChange={setCompany}
                            value={company}
                            autoComplete="off"
                        />
                    </div>
                    <div>
                        <p className="text-left text-sm font-semibold ml-1 mb-1">
                            Password
                        </p>
                        <Input
                            name="password"
                            id="password"
                            type="password"
                            required
                            placeholder="Password"
                            onChange={setPassword}
                            value={password}
                            autoComplete="off"
                        />
                        <p className="text-left text-sm text-gray-600 ml-1 mt-1">
                            Minimum of eight characters. Must contain one of
                            each of the following: Uppercase letter, lowercase
                            letter, number & special character.
                        </p>
                    </div>
                    <div>
                        <p className="text-left text-sm font-semibold ml-1 mb-1">
                            Confirm Password
                        </p>
                        <Input
                            name="confirmPassword"
                            id="confirmPassword"
                            type="password"
                            required
                            placeholder="Confirm Password"
                            onChange={setConfirmPassword}
                            value={confirmPassword}
                            autoComplete="off"
                        />
                    </div>
                </div>

                <div className="mt-5">
                    <Button
                        color="gray"
                        block
                        size="md"
                        onClick={() =>
                            RegisterValidator(formatFormParams()) &&
                            submitForm(formatFormParams())
                        }
                    >
                        <div className="sm:text-sm">
                            <span className="absolute left-0 inset-y pl-3">
                                <FontAwesomeIcon
                                    icon={faUserPlus}
                                    className="text-gray-600"
                                />
                            </span>
                            Register
                        </div>
                    </Button>
                </div>
            </Panel>
        </div>
    );
}

export default RegistrationForm;
