import React from "react";
import toast from "react-hot-toast";

function RegisterValidator(params) {
    let errors = [];
    let validated = true;

    /**
     * Username checks
     */
    if (!params.username) {
        errors.push(<li key={1}>Username is required.</li>);
        validated = false;
    }

    if (params.username && params.username.length < 4) {
        errors.push(
            <li key={2}>Username must be at least four (4) characters.</li>
        );
        validated = false;
    }

    if (params.username && !/^[a-z0-9]+$/i.test(params.username)) {
        errors.push(
            <li key={3}>Username cannot contain special characters.</li>
        );
        validated = false;
    }

    /**
     * Firstname checks
     */
    if (!params.firstName) {
        errors.push(<li key={4}>First name is required.</li>);
        validated = false;
    }

    if (params.firstName && params.firstName.length < 2) {
        errors.push(
            <li key={5}>First name must be at least two (2) characters.</li>
        );
        validated = false;
    }

    /**
     * Surname checks
     */
    if (!params.surname) {
        errors.push(<li key={6}>Surname is required.</li>);
        validated = false;
    }

    if (params.surname && params.surname.length < 2) {
        errors.push(
            <li key={7}>Surname must be at least two (2) characters.</li>
        );
        validated = false;
    }

    /**
     * Company checks
     */
    if (!params.company) {
        errors.push(<li key={8}>Company is required.</li>);
        validated = false;
    }

    if (params.company && params.company.length < 2) {
        errors.push(
            <li key={9}>Company must be at least two (2) characters.</li>
        );
        validated = false;
    }

    /**
     * Password checks
     */
    if (!params.password) {
        errors.push(<li key={10}>Password is required.</li>);
        validated = false;
    }

    if (params.password && params.password.length < 8) {
        errors.push(
            <li key={11}>Password must be at least eight (8) characters.</li>
        );
        validated = false;
    }

    if (
        params.password &&
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i.test(
            params.password
        )
    ) {
        errors.push(<li key={12}>Password does not meet requirements.</li>);
        validated = false;
    }

    /**
     * Confirm Password checks
     */
    if (!params.confirmPassword) {
        errors.push(<li key={13}>Confirm Password is required.</li>);
        validated = false;
    }

    if (params.confirmPassword !== params.password) {
        errors.push(<li key={14}>Passwords do not match.</li>);
        validated = false;
    }

    if (!validated) {
        toast.error((t) => (
            <div>
                <div>Please address the following errors:</div>
                <div className="text-sm mt-1">
                    <ul className="list-inside list-disc">{errors}</ul>
                </div>
            </div>
        ));
    }

    return validated;
}

export default RegisterValidator;
