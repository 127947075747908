import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Table({ insidePanel, gridOptions, rowData, ...props }) {
    const [api, setApi] = useState(null);

    const onGridReady = (params) => {
        setApi(params.api);

        if (props.captureApi !== null) {
            props.captureApi(params.api);
        }

        if (props.sizeColumnsToFit) {
            params.api.sizeColumnsToFit();
        }

        window.addEventListener('resize', function () {
            setTimeout(function () {
              params.api.sizeColumnsToFit();
            })
        })

    };

    useEffect(() => {
        if (api) {
            api.resetRowHeights();
        }
    }, [rowData, api]);

    return (
        <div>
            <div
                className={`ag-theme-alpine ${insidePanel ? "-mx-4" : ""} mb-1`}
            >
                <AgGridReact
                    popupParent={document.querySelector("body")}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    rowData={rowData}
                    domLayout={props.domLayout}
                    enableCellTextSelection={props.enableCellTextSelection}
                    immutableData={props.pagination}
                    pagination={props.pagination}
                    defaultColDef={props.defaultColDef}
                    isExternalFilterPresent={props.isExternalFilterPresent}
                    doesExternalFilterPass={props.doesExternalFilterPass}
                />
            </div>
            <p className="text-gray-600 text-xs text-right mb-1">
                Filter the table by clicking the{" "}
                <FontAwesomeIcon icon={faBars} className="mx-1" /> icon while
                hovering over a column heading.
            </p>
        </div>
    );
}

Table.defaultProps = {
    insidePanel: true,
    domLayout: "autoHeight",
    enableCellTextSelection: true,
    pagination: true,
    animateRows: true,
    defaultColDef: {
        flex: 1,
        suppressMovable: true,
        sortable: true,
        enableCellChangeFlash: false,
        resizable: true,
        filter: true,
        wrapText: false,
        autoHeight: true,
        filterParams: {
            clearButton: true,
            buttons: ['reset'],
        },
    },
    sizeColumnsToFit: true,
    isExternalFilterPresent: false,
    doesExternalFilterPass: true,
    captureApi: null,
};

export default Table;
