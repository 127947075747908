import Badge from "../../Badge/Badge";

function StatusIndicator(props) {
    return (
        <Badge
            text={props.value}
            color={props.value === "running" ? "green" : "red"}
        />
    );
}

export default StatusIndicator;
