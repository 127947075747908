import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCertificate,
    faChalkboard,
    faChalkboardTeacher,
    faEdit,
    faKey,
    faUserAlt,
    faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import StackedLabel from "../../components/Label/StackedLabel";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";

import CoursesTaken from "../../components/Pages/Users/CoursesTaken";
import CoursesTaught from "../../components/Pages/Users/CoursesTaught";
import axios from "axios";
import LabVMTable from "../../components/Pages/Vms/LabVMTable";
import propTypes from "prop-types";


export default function UserProfile({ token }) {
    /**
     * Extract the profile from the token
     */
    const profile = token.profile;
    const fullName = `${profile.firstname} ${profile.surname}`;

    const [user, setUser] = useState(null);
    const [coursesTaken, setCoursesTaken] = useState(null);
    const [isInstructor, setIsInstructor] = useState(false);
    const [coursesTaught, setCoursesTaught] = useState(null);

    const [timezone, setTimezone] = useState(null);
    const [timeformat, setTimeformat] = useState(null);

    const [firstName, setFirstName] = useState("");
    const [firstNameHasError, setFirstNameHasError] = useState(false);

    const [surname, setSurname] = useState("");
    const [surnameHasError, setSurnameHasError] = useState(false);

    const [company, setCompany] = useState("");
    const [companyHasError, setCompanyHasError] = useState(false);

    const getUserInfo = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/user/profile.json`
            )
            .then(({ data }) => {
                setUser(data.user);
                setFirstName(data.user.firstname);
                setSurname(data.user.surname);
                setCompany(data.user.company === null ? "" : data.user.company);
                setCoursesTaken(data.courses);
                setIsInstructor(data.user.instructor);
                setCoursesTaught(data.coursesTaught);
                setTimezone(data.user.timezone);
                setTimeformat(data.user.timeformat);

            });
    };

    useEffect(getUserInfo, []);

    return (
        <Container>
            <Helmet>
                <title>Profile: {fullName}</title>
            </Helmet>

            {/* User Profile  */}
            <Panel>
                <h1 className="text-xl font-bold mb-4">
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faUserAlt}
                        className="text-gray-600 mr-4"
                    />
                    {fullName}
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                    <StackedLabel
                        label="First Name"
                        value={profile.firstname}
                    />
                    <StackedLabel label="Surname" value={profile.surname} />
                    <StackedLabel label="Username" value={profile.username} />
                    <StackedLabel label="Email" value={profile.email} />
                    <StackedLabel label="Company" value={profile.company} />
                    <StackedLabel
                        label="Time Format"
                        value={
                            <pre className="font-mono">
                                {timeformat}
                            </pre>
                        }
                    />
                    <StackedLabel
                        label="Timezone"
                        value={timezone}
                    />

                </div>

                <div className="text-center sm:text-right">
                    <Button
                        size="sm"
                        color="teal"
                        noRoundingRight
                        isLink
                        to={`/user/profile/edit`}
                    >
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faEdit}
                            className="mr-2"
                        />
                        Edit Profile
                    </Button>
                    <Button
                        size="sm"
                        color="orange"
                        noRoundingLeft
                        isLink
                        to={`/user/change_password`}
                    >
                        Change Password
                    </Button>
                </div>
            </Panel>

            {/* SSH Key Panel */}
            {token.claims.includes("staff") && (
                <Panel>
                    <h1 className="text-xl font-bold">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faKey}
                            className="text-gray-600 mr-4"
                        />
                        SSH Keys
                    </h1>
                </Panel>
            )}
            <Panel>
                <h1 className="text-xl font-bold mb-4">
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faChalkboard}
                        className="text-gray-600 mr-4"
                    />
                    Courses Taken
                </h1>
                <CoursesTaken coursesTaken={coursesTaken} token={token} />
            </Panel>

            {isInstructor && (
                <Panel>
                    <h1 className="text-xl font-bold mb-4">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faChalkboardTeacher}
                            className="text-gray-600 mr-4"
                        />
                        Courses Taught
                    </h1>
                    <CoursesTaught coursesTaught={coursesTaught} />
                </Panel>
            )}
            {token.claims.includes("vm_lab_user") ?
                <LabVMTable token={token} />
                : <></>
            }
        </Container>

    );
}
