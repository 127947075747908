function getDatesBetween(startDate, endDate, inclusive = true) {
    let now = startDate.clone(),
        dates = [];

    while (inclusive ? now.isSameOrBefore(endDate) : now.isBefore(endDate)) {
        dates.push(now.format("YYYY-MM-DD"));
        now.add(1, "d");
    }

    return dates;
}

export default getDatesBetween;
