import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../Button/Button";
import Input from "../../Form/Input";
import Modal from "../../Modal/Modal";
import Tippy from "@tippyjs/react";


function TemplateName(props) {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [editName, setEditName] = useState(props.data.description);

    const updateName = () => {
        let updatePromise = axios
            .put(`${process.env.REACT_APP_API}/vms/${props.data.id}.json`, {
                description: editName,
            })
            .then(() => setInfoModalOpen(false))
            .then(() => props.refreshVms())
            .catch((error) => console.error(error));

        toast.promise(updatePromise, {
            loading: "Updating VM...",
            success: "Updated VM",
            error: "Failed updating VM",
        });
    };

    return (
        <div>
            <div className="flex items-center space-x-2">
                <img
                    className="h-5 w-5"
                    alt="Logo"
                    src={
                        props.data.icon
                            ? `/images/${props.data.icon}.${props.data.icon === "kali" ? "svg" : "png"
                            }`
                            : "/images/unknown.png"
                    }
                />
                <div>
                    {props.showModal ? (
                        <div
                            className="text-teal-500 hover:text-teal-700 cursor-pointer"
                            onClick={() => setInfoModalOpen(true)}
                        >
                            <Tippy placement="top" content={props.value}>
                                <div>
                                    {props.value}
                                </div>
                            </Tippy>
                        </div>
                    ) : <Tippy placement="top" content={props.value}>
                        <div>
                            {props.value}
                        </div>
                    </Tippy>

                    }
                </div>
            </div>
            <Modal
                isOpen={infoModalOpen}
                setIsOpen={setInfoModalOpen}
                headerContent={`${props.data.description} Information`}
                footerContent={
                    <Button
                        color="teal"
                        disabled={
                            props.data.description === editName ||
                            editName === ""
                        }
                        onClick={() => updateName()}
                    >
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faSave}
                            className="mr-2"
                        />
                        Edit
                    </Button>
                }
                capMaxSize={false}
            >
                <div>
                    <div className="flex flex-col divide-y divide-gray-200 mb-4">
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">VM Name</div>
                            <div className="w-1/2">
                                {props.data.description}
                            </div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Type</div>
                            <div className="w-1/2">{props.data.vm_type} {props.data.vm_type === "FireEye" ?
                                <>({props.data.fe_model})</> :
                                <></>}
                            </div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Template Name</div>
                            <div className="w-1/2">{props.data.vm_template_name}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Template ID</div>
                            <div className="w-1/2">{props.data.vm_template_id}</div>
                        </div>

                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Status</div>
                            <div className="w-1/2">{props.data.status}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Last Boot</div>
                            <div className="w-1/2">{props.data.lastboot}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">IP Address</div>
                            <div className="w-1/2">{props.data.ipaddress}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">MAC Address</div>
                            <div className="w-1/2">{props.data.macaddress}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Node</div>
                            <div className="w-1/2">{props.data.node}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">VMID</div>
                            <div className="w-1/2">{props.data.vmid}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Username</div>
                            <div className="w-1/2">{props.data.username}</div>
                        </div>
                        <div className="flex flex-row py-2">
                            <div className="w-1/2 font-bold">Password</div>
                            <div className="w-1/2">{props.data.password}</div>
                        </div>
                        {props.data.licenses && (
                            props.data.licenses.map((license, index) => 
                            <div className="flex flex-row py-2">
                              <div className="w-1/2 font-bold">{license.license_type}</div>
                              <div className="w-1/2">{license.license_key}</div>
                            </div>    
                                )
                    )}
                    </div>

                    <label className="text-gray-600 block mb-1">
                        Edit VM Name
                    </label>
                    <Input
                        ariaLabel="VM Name"
                        name="name"
                        id="name"
                        type="text"
                        autoComplete="off"
                        placeholder="VM Name..."
                        value={editName}
                        onChange={setEditName}
                    />
                </div>
            </Modal>
        </div>
    );
}

TemplateName.defaultProps = {
    showModal: false,
};

export default TemplateName;
