import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

function Toggle({ isEnabled, onClick, isLoading, size, disabled }) {
    const getSizing = (size) => {
        switch (size) {
            case "md":
                return {
                    toggleWidth: "w-10",
                    toggleHeight: "w-6",
                    dotSize: "w-4 h-4",
                    translateSize: "translate-x-4",
                };
            case "sm":
                // code block
                return {
                    toggleWidth: "w-6",
                    toggleHeight: "w-4",
                    dotSize: "w-2 h-2",
                    translateSize: "translate-x-2",
                };
            default:
                return {
                    toggleWidth: "w-10",
                    toggleHeight: "w-6",
                    dotSize: "w-4 h-4",
                    translateSize: "translate-x-4",
                };
        }
    };

    const sizes = getSizing(size);

    return (
        <div className="flex items-center space-x-2">
            <div
                className={`${sizes.toggleWidth} ${
                    sizes.toggleHeight
                } flex items-center rounded-full cursor-pointer p-1 duration-300 ease-in-out ${
                    disabled ? 
                    isEnabled ? "bg-green-200" : "bg-gray-300"
                    : isEnabled ? "bg-green-400" : "bg-gray-300"
                }`}
                onClick={!disabled && onClick}
            >
                <div
                    className={`bg-white ${
                        sizes.dotSize
                    } rounded-full shadow-md transform duration-300 ease-in-out ${
                        isEnabled ? sizes.translateSize : ""
                    }`}
                ></div>
            </div>
            {isLoading && (
                <div className="w-4">
                    <FontAwesomeIcon
                        fixedWidth
                        spin
                        size="xs"
                        icon={faCircleNotch}
                        className="text-gray-600"
                    />
                </div>
            )}
        </div>
    );
}

Toggle.defaultProps = {
    isEnabled: false,
    onClick: null,
    isLoading: false,
    size: "md",
    disabled: false,
};

export default Toggle;
