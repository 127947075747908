import React from "react";
import { Toaster } from "react-hot-toast";

export default function ToastComponent() {
    return (
        <Toaster
            position="bottom-right"
            toastOptions={{
                duration: 5000,
                success: {
                    iconTheme: {
                        primary: "#6A823A",
                    },
                },
                error: {
                    iconTheme: {
                        primary: "#B2282C",
                    },
                },
            }}
        />
    );
}
