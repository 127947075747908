import React from "react";
import { Link, withRouter } from "react-router-dom";
import PropType from "prop-types";
import Container from "./Container";
import NavLink from "../Link/NavLink";
import logo from "../../images/Trellix_LOGO_Color_Graphite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faQuestionCircle,
    faSignOutAlt,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            dropdownOpen: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                dropdownOpen: false,
            });
        }
    }

    logout() {
        this.props.removeToken();
        window.location.reload(false);
    }

    toggleMenu() {
        this.setState({
            menuOpen: !this.state.menuOpen,
        });
    }

    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    render() {
        const profile = this.props.token.profile;

        return (
            <div className="bg-white box-border border-b-2 border-gray-200 h-16">
                <Container>
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <button
                                onClick={this.toggleMenu.bind(this)}
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    {!this.state.menuOpen ? (
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    ) : (
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    )}
                                </svg>
                            </button>
                        </div>
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start h-full">
                            <div className="flex-shrink-0 flex items-center">
                                <Link to="/">
                                    <img
                                        className="block h-6 w-auto"
                                        src={logo}
                                        alt="Trellix"
                                    />
                                </Link>
                            </div>
                            <div className="hidden sm:block sm:ml-6">
                                <div className="flex h-full">
                                    {/* Page links in here... */}
                                    <NavLink
                                        to="/"
                                        pathname={this.props.location.pathname}
                                    >
                                        Home
                                    </NavLink>
                                    <NavLink
                                        to="/student/course_resources"
                                        pathname={this.props.location.pathname}
                                    >
                                        Course Resources
                                    </NavLink>
                                    { this.props.token.claims.includes("vm_lab_user") ? 
                                    <NavLink
                                        to="/vms"
                                        pathname={this.props.location.pathname}
                                    >
                                        VM Lab
                                    </NavLink>
                                    : <></>
                                    }
                                </div>
                            </div>
                            <div className="hidden sm:block sm:ml-auto">
                                <div className="flex h-full">
                                    <NavLink
                                        external
                                        to={`${process.env.REACT_APP_PORTAL_DOMAIN}/studentdocs`}
                                        pathname={this.props.location.pathname}
                                    >
                                        <FontAwesomeIcon
                                            icon={faQuestionCircle}
                                            className="text-gray-500 mr-2"
                                        />
                                        Help
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6">
                            <div className="relative" ref={this.setWrapperRef}>
                                <div>
                                    <button
                                        onClick={this.toggleDropdown}
                                        className="px-3 py-1 text-sm text-gray-700 hover:bg-gray-200 rounded-md focus:outline-none"
                                        id="user-menu"
                                        aria-haspopup="true"
                                    >
                                        <span className="inline-block sm:hidden">
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faUserCircle}
                                            />
                                        </span>
                                        <span className="hidden sm:inline-block">{`${profile.firstname} ${profile.surname}`}</span>
                                        <FontAwesomeIcon
                                            icon={faCaretDown}
                                            className="ml-2"
                                        />
                                    </button>
                                </div>
                                {this.state.dropdownOpen && (
                                    <div
                                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 z-50"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="user-menu"
                                    >
                                        <Link
                                            onClick={this.toggleDropdown}
                                            to="/user/profile"
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faUserCircle}
                                                className="mr-4"
                                            />
                                            Your Profile
                                        </Link>
                                        <button
                                            onClick={this.logout.bind(this)}
                                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            role="menuitem"
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faSignOutAlt}
                                                className="mr-4"
                                            />
                                            Sign out
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

Header.propTypes = {
    removeToken: PropType.func.isRequired,
    token: PropType.object.isRequired,
};

export default withRouter(Header);
