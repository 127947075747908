import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import moment from "moment";
import toast from "react-hot-toast";
import Modal from "../../Modal/Modal";
import Table from "../../Table/Table";
import CourseRosterToggle from "../../CellRenderers/Courses/CourseRosterToggle";
import getDatesBetween from "../../../util/getDatesBetween";
registerLocale("en-gb", enGb);

function CourseRosterModal({
    courseRosterModalOpen,
    setCourseRosterModalOpen,
    course,
    getCourse,
}) {
    const [tableApi, setTableApi] = useState(null);

    const formatStudentList = () => {
        return course.students.map((student) => {
            return {
                id: student.id,
                student_id: student.user_id,
                course_id: course.course.id,
                student_name: student.name,
                company_name: student.company,
                roster: course.roster,
            };
        });
    };

    const [formattedStudentList, setFormattedStudentList] = useState(
        formatStudentList()
    );

    useEffect(() => {
        console.log("attempting to redraw rows...");
        setFormattedStudentList(formatStudentList());
        setTimeout(() => {
            tableApi?.redrawRows();
        }, 100);
    }, [course]);

    const formatColumns = () => {
        const startDate = moment(course.course.startDate);
        const duration = course.curriculum.duration;
        const endDate = moment(course.course.startDate).add(duration, "d");

        const dateArray = getDatesBetween(startDate, endDate, false);

        let columnDefs = [
            {
                headerName: "Student",
                field: "student_name",
                sort: "asc",
            },
            { 
                headerName: "Company",
                field: "company_name",
            }
        ];

        dateArray.forEach((value) => {
            columnDefs.push({
                headerName: value,
                headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                        "    <span>" +
                        '      <div class="block">' +
                        '        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                        "      </div>" +
                        '      <div class="flex justify-between border-t border-gray-700 mt-1 pt-1">' +
                        "        <div>AM</div>" +
                        "        <div>PM</div>" +
                        "      </div>" +
                        "    </span>" +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        "  </div>" +
                        "</div>",
                },
                field: "am",
                cellRendererFramework: CourseRosterToggle,
                cellRendererParams: {
                    date: value,
                    getCourse: getCourse,
                },
            });
        });

        return columnDefs;
    };

    /**
     * Set up the gridOptions for the table
     */
    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 25,
        columnDefs: formatColumns(),
    };

    return (
        <Modal
            size="xl"
            isOpen={courseRosterModalOpen}
            setIsOpen={setCourseRosterModalOpen}
            closeWording={"Done"}
            closeButtonColor={"green"}
            headerContent={`Roster for ${course.curriculum.title} with ${course.course.customer_name}`}
        >
            <Table
                insidePanel={true}
                gridOptions={gridOptions}
                rowData={formattedStudentList}
                captureApi={setTableApi}
            />
        </Modal>
    );
}

export default CourseRosterModal;
