import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button/Button";
import Tippy from "@tippyjs/react";

function PhysicalActions(props) {
    return (
        <Tippy content="Web UI">
            <a
                target="_blank"
                href={`${process.env.REACT_APP_API}/appliance/${props.data.id}`}
            >
                <Button color="teal" disabled={!props.data.https}>
                    <FontAwesomeIcon fixedWidth icon={faGlobe} />
                </Button>
            </a>
        </Tippy>
    );
}

export default PhysicalActions;
