import React, { useEffect, useState } from "react";
import axios from "axios";
import moment, { duration } from "moment";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faCogs,
    faPlus,
    faServer,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../../Layout/Container";
import Panel from "../../Panel/Panel";
import Button from "../../Button/Button";
import Table from "../../Table/Table";
import StatusIndicator from "../../CellRenderers/Vms/StatusIndicator";
import StudentActions from "../../CellRenderers/Vms/StudentActions";
import TemplateName from "../../CellRenderers/Vms/TemplateName";
import LastBoot from "../../CellRenderers/Vms/LastBoot";
import VmTypeDetails from "../../CellRenderers/Vms/VmTypeDetails";
import Username from "../../CellRenderers/Users/Username";

function VMClonesTable(props) {
    const [lastUpdated, setLastUpdated] = useState(moment());
    const [isCreateVmModalOpen, setIsCreateVmModelOpen] = useState(false);
    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Name",
                field: "description",
                cellRendererFramework: TemplateName,
                cellRendererParams: {
                    showModal: true,
                },
            },
            {
                headerName: "User ID",
                field: "uid",
                sort: "asc",
                width: 250,
                cellRendererFramework: Username,
            },
            {
                headerName: "Status",
                field: "status",
                cellRendererFramework: StatusIndicator,
            },
            {
                headerName: "Last Boot",
                field: "lastboot",
                cellRendererFramework: LastBoot,
            },
            {
                headerName: "IP Address",
                field: "ipaddress",
            },
            {
                headerName: "Actions",
                field: "status",
                cellRendererFramework: StudentActions,
            },
        ],
    };

    return (
        <>
            <Panel>
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <h1 className="text-xl font-bold">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faServer}
                                className="mr-2 text-gray-600"
                            />
                            VM Clones
                        </h1>
                    </div>
                </div>

                <Table gridOptions={gridOptions} rowData={props.clones} />
            </Panel>

  </>
  );
}

export default VMClonesTable;
