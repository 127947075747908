import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import Panel from "../../Panel/Panel";
import axios from "axios";
import Table from "../../Table/Table";
import PhysicalActions from "../../CellRenderers/Vms/PhysicalActions";

function LabAppliancesTable(props) {
    const [appliancesList, setAppliancesList] = useState(null);
    const getAppliancesList = () => {
        axios
            .get(`${process.env.REACT_APP_API}/appliances.json`)
            .then(({ data }) => setAppliancesList(data))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getAppliancesList();
    }, []);

    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Name",
                field: "shortname",
                width: 100
            },
            {
                headerName: "Description",
                field: "description",
            },
            {
                headerName: "Model",
                field: "model",
                width: 100
            },
            {
                headerName: "OS Version",
                field: "os_version",
            },
            {
                headerName: "Guest Image",
                field: "guest_image",
            },
            {
                headerName: "Actions",
                field: "id",
                cellRendererFramework: PhysicalActions,
            },
        ],
    };

    return (
        <Panel>
            <h1 className="text-xl font-bold mb-4">
                <FontAwesomeIcon
                    fixedWidth
                    icon={faServer}
                    className="mr-2 text-gray-600"
                />
                Physical Systems
            </h1>

            <Table gridOptions={gridOptions} rowData={appliancesList} />
        </Panel>
    );
}

export default LabAppliancesTable;
