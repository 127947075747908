import { faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button/Button";
import GoToCourse from "../../components/CellRenderers/Courses/GoToCourse";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";
import Selectbox from "../../components/Form/Selectbox";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import CurriculumLink from "../../components/CellRenderers/Curriculums/CurriculumLink";

registerLocale("en-gb", enGb);

function Courses() {
    const [courseUrl, setCourseUrl] = useState("courses.json");
    const [courses, setCourses] = useState(null);
    const [instructorFilter, setInstructorFilter] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const getCourses = () => {
        axios
            .get(`${process.env.REACT_APP_API}/${courseUrl}`)
            .then(({ data }) => {
                setCourses(data.courses);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getCourses();
    }, [courseUrl]);

    /**
     * Set up the gridOptions for the table
     */
    const gridOptions = {
        getRowNodeId(data) {
            return data.course.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Curriculum",
                field: "course.curriculum_title",
                minWidth: 150,
                cellRendererFramework: CurriculumLink,

            },
            {
                headerName: "Type",
                field: "course.course_type_name",
                minWidth: 100,
            },
            {
                headerName: "Customer",
                field: "course.customer_name",
                minWidth: 150,
            },
            {
                headerName: "Method",
                field: "course.delivery_method_name",
                minWidth: 150,
            },
            {
                headerName: "Instructor(s)",
                field: "course.delivery_method_name",
                valueGetter: function (params) {
                    let instructors = params.data.course_instructors;
                    return instructors
                        .filter((instructor) => instructor.primary)
                        .map((instructor) => instructor.name);
                },
                minWidth: 250,
            },
            {
                headerName: "Start Date",
                field: "course.startDate",
                minWidth: 150,
                suppressSizeToFit: true,
                sort: "desc",
            },
            {
                headerName: "Duration",
                field: "course.duration",
                minWidth: 100,
            },
            {
                headerName: "Students",
                field: "course.maxNumStudents",
                valueGetter: function (params) {
                    if (params.data.waiting_registration.length) {
                        return `${params.data.students.length} / ${params.data.course.maxNumStudents} (${params.data.waiting_registration.length})`;
                    }

                    return `${params.data.students.length} / ${params.data.course.maxNumStudents}`;
                },
                minWidth: 150,
                suppressSizeToFit: true,
            },
            {
                headerName: "Actions",
                field: "course.id",
                minWidth: 250,
                cellRendererFramework: GoToCourse,
            },
        ],
    };

    const formatOptions = (courses) => {
        let instructors = [];

        /**
         * Loop all the courses being run
         */
        courses.forEach((course) => {
            course.course_instructors.forEach((instructor) => {
                if (!instructors.includes(instructor.name)) {
                    instructors.push({
                        label: instructor.name,
                        value: instructor.name,
                    });
                }
            });
        });

        return instructors.sort((a, b) => a.label.localeCompare(b.label));
    };

    return (
        <div className="w-full">
            <Helmet>
                <title>Courses</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Course Management",
                        to: "/courses",
                    },
                ]}
            />
            <Container>
                <Panel>
                    <div className="flex flex-row mb-3">
                        <div className="flex-grow">
                            <h1 className="text-xl font-bold">Courses</h1>
                        </div>
                        <div></div>
                    </div>

                    <div className="flex justify-between items-center mb-4">
                        <div className="flex">
                            <div
                                className={`cursor-pointer border py-1 px-2 rounded-l-md select-none ${
                                    !courseUrl.includes("archive=true")
                                        ? "bg-teal-100 border-teal-300 text-teal-700"
                                        : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                                onClick={() => setCourseUrl("courses.json")}
                            >
                                Current / Future
                            </div>
                            <div
                                className={`cursor-pointer border py-1 px-2 rounded-r-md select-none ${
                                    courseUrl.includes("archive=true")
                                        ? "bg-teal-100 border-teal-300 text-teal-700"
                                        : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                                onClick={() =>
                                    setCourseUrl("courses.json?archive=true")
                                }
                            >
                                Include Archived
                            </div>
                        </div>
                        <div>
                            <Button
                                color="green"
                                isLink
                                to="/courses/new"
                            >
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faPlus}
                                    className="mr-2"
                                />
                                New Course
                            </Button>
                        </div>
                    </div>

                    <Table
                        gridOptions={gridOptions}
                        rowData={courses}
                        isExternalFilterPresent={instructorFilter !== null}
                        doesExternalFilterPass={(row) => console.log(row)}
                    />
                </Panel>
            </Container>
        </div>
    );
}

export default Courses;
