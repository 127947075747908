import MDEditor from "@uiw/react-md-editor";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import Loader from "../../components/Loader/Loader";
import Panel from "../../components/Panel/Panel";
import Input from "../../components/Form/Input";
import Selectbox from "../../components/Form/Selectbox";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";
import toast from "react-hot-toast";
import RenderResources from "../../components/Pages/Curriculum/RenderResources";
import Toggle from "../../components/Toggle/Toggle";
import StudentRoles from "../../components/Pages/Curriculum/StudentRoles";

const validateForm = (params) => {
    /**
     * Check the title isn't null or empty
     */
    if (params.curriculumTitle === null || params.curriculumTitle === "") {
        return false;
    }

    /**
     * Check the description isn't null or empty
     */
    if (
        params.curriculumDescription === null ||
        params.curriculumDescription === ""
    ) {
        return false;
    }

    if (params.selectedResource === null || params.selectedResource === "") {
        return false;
    }

    return true;
};

const submitForm = (id, params, setRedirect) => {
    let editPromise = axios
        .put(`${process.env.REACT_APP_API}/curriculums/${id}.json`, {
            title: params.curriculumTitle,
            duration: params.curriculumDuration,
            description: params.curriculumDescription,
            notes: params.curriculumNotes,
            curriculum_name: params.selectedResource.value,
        })
        .then(({ data }) => {
            setRedirect(true);
        })
        .catch((error) => console.error(error));

    toast.promise(editPromise, {
        loading: "Submitting...",
        success: "Successfully edited curriculum!",
        error: "Failed editing curriculum",
    });
};

function CurriculumEdit(props) {
    const [curriculum, setCurriculum] = useState(null);
    const [curriculumTitle, setCurriculumTitle] = useState(null);
    const [curriculumDuration, setCurriculumDuration] = useState(null);
    const [curriculumIsCustom, setCurriciulumIsCustom] = useState(false);
    const [curriculumDescription, setCurriculumDescription] = useState(null);
    const [curriculumNotes, setCurriculumNotes] = useState(null);

    const [resources, setResources] = useState(null);
    const [resource, setResource] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null);

    const [redirect, setRedirect] = useState(false);

    /**
     * Get the curriculums as soon as the component mounts
     */
    useEffect(() => {
        const getCurriculum = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API}/curriculums/${props.match.params.id}.json`
                )
                .then(({ data }) => {
                    setCurriculum(data);
                    setCurriculumTitle(data.title);
                    setCurriculumDuration(data.duration);
                    setCurriciulumIsCustom(data.custom);
                    setCurriculumDescription(data.description);
                    setCurriculumNotes(data.notes);

                    if (data.curriculum_name) {
                        setSelectedResource({
                            value: data.curriculum_name,
                            label: data.curriculum_name,
                        });
                    }
                })
                .catch((error) => console.error(error));
        };

        const getResources = () => {
            axios
                .get(`${process.env.REACT_APP_API}/resource/names.json`)
                .then(({ data }) => {
                    let parsedResources = data.resources.map((resource) => {
                        return {
                            value: resource.curriculum_name,
                            label: resource.curriculum_name,
                        };
                    });
                    setResources(parsedResources);
                })
                .catch((error) => console.error(error));
        };
        /**
         * Run the above functions when the page loads
         */
        getCurriculum();
        getResources();
    }, [props.match.params.id]);

    /**
     * Listen for changes in the resources object
     */
    useEffect(() => {
        const getResource = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API}/resource/resource_for.json?curriculum_name=${selectedResource.value}`
                )
                .then(({ data }) => {
                    setResource(data.resources);
                })
                .catch((error) => console.error(error));
        };

        if (selectedResource) {
            getResource();
        }
    }, [selectedResource]);

    if (redirect) {
        return <Redirect to={`/curriculums/${props.match.params.id}`} />;
    }

    if (!curriculum || !resources) {
        return <Loader text={"Loading curriculum"} />;
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>Edit | {curriculum.title} | Curriculums</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Curriculum Management",
                        to: "/curriculums",
                    },
                    {
                        name: curriculum.title,
                        to: `/curriculums/${props.match.params.id}`,
                    },
                    {
                        name: "Edit Curriculum",
                        to: `/curriculums/${props.match.params.id}/edit`,
                    },
                ]}
            />

            <Container>
                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Curriculum Information
                    </h1>
                    <div className="grid grid-cols-2 grid-rows-1 gap-4">
                        <div>
                            <p className="font-semibold text-gray-700 mb-1">
                                Title
                            </p>
                            {curriculumTitle && (
                                <Input
                                    ariaLabel="Title"
                                    name="title"
                                    id="title"
                                    type="text"
                                    required
                                    autoComplete="off"
                                    placeholder="Title..."
                                    onChange={setCurriculumTitle}
                                    value={curriculumTitle}
                                    hasError={false}
                                />
                            )}
                        </div>
                        <div>
                            <p className="font-semibold text-gray-700 mb-1">
                                Duration
                            </p>
                            {curriculumDuration && (
                                <input
                                    className="border-gray-300  focus:border-blue-300 focus:shadow-outline-blue placeholder-gray-500 appearance-none relative block w-full px-4 py-2 border text-gray-900 rounded-md focus:outline-none focus:z-10"
                                    type="number"
                                    min={0.5}
                                    max={14}
                                    step={0.5}
                                    value={curriculumDuration}
                                    onChange={(e) =>
                                        setCurriculumDuration(e.target.value)
                                    }
                                />
                            )}
                        </div>
                        <div>
                            <p className="font-semibold text-gray-700 mb-1">
                                Custom
                            </p>
                            <Toggle
                                isEnabled={curriculumIsCustom}
                                onClick={() => setCurriciulumIsCustom(!curriculumIsCustom)}
                            />
                        </div>
                    </div>
                </Panel>

                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Description
                    </h1>
                    <MDEditor
                        height={600}
                        value={curriculumDescription}
                        onChange={setCurriculumDescription}
                    />
                </Panel>
                <Panel>
                    <h1 className="text-xl font-bold mb-4">
                        Student Roles
                    </h1>
                    <StudentRoles
                        studentGroups={curriculum.studentgroups}
                        studentRoles={curriculum.curriculumRoles}
                        curriculum_id={curriculum.id}
                    />
                </Panel>

                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Assign Resources
                    </h1>
                    <Selectbox
                        onChange={setSelectedResource}
                        defaultValue={selectedResource}
                        options={resources}
                    />

                    {resource && (
                        <div className="grid grid-cols-4 grid-rows-1 gap-4 mt-4">
                            <RenderResources
                                title={"Guides"}
                                resources={resource}
                            />
                            <RenderResources
                                title={"Lab Materials"}
                                resources={resource}
                            />
                            <RenderResources
                                title={"Instructor"}
                                resources={resource}
                            />
                            <RenderResources
                                title={"Videos"}
                                resources={resource}
                            />
                        </div>
                    )}
                </Panel>

                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Instructor Notes
                    </h1>
                    <MDEditor
                        height={300}
                        value={curriculumNotes}
                        onChange={setCurriculumNotes}
                    />
                </Panel>

                <div className="flex flex-row justify-between">
                    <div>
                        <Button
                            size="md"
                            color="red"
                            onClick={() => setRedirect(true)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            size="md"
                            color="teal"
                            onClick={() =>
                                validateForm({
                                    curriculumTitle,
                                    curriculumDuration,
                                    curriculumDescription,
                                    selectedResource,
                                    curriculumNotes,
                                }) &&
                                submitForm(
                                    props.match.params.id,
                                    {
                                        curriculumTitle,
                                        curriculumDuration,
                                        curriculumDescription,
                                        selectedResource,
                                        curriculumNotes,
                                    },
                                    setRedirect
                                )
                            }
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faSave}
                                className="mr-2"
                            />
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default CurriculumEdit;
