import React from "react";
import Select from "react-select";
import {
    gray400,
    teal100,
    teal300,
    teal500,
    teal700,
} from "../../styles/vars.module.css";

const customStyles = {
    option: (provided, state) => {
        return {
            ...provided,
            color: state.isFocused ? "white" : state.isSelected ? "white" : "",
            backgroundColor: state.isFocused
                ? teal300
                : state.isSelected
                ? teal500
                : "",
            "&:hover": {
                backgroundColor: teal100,
                color: teal700,
            },
        };
    },
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: gray400,
        }
    }
};

function Selectbox(props) {
    return <Select styles={customStyles} {...props} />;
}

Selectbox.defaultProps = {};

export default Selectbox;
