import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../../Button/Button";
import Link from "../../Link/Link";

function CurriculumSchedule(props) {
    if (props.data.enabled) {
        return (
            <Link to={`/courses/new?curriculum=${props.data.id}`}>
                <Button color="teal">
                    <FontAwesomeIcon fixedWidth icon={faCalendarAlt} />
                </Button>
            </Link>
        );
    }

    return <div />;
}

export default CurriculumSchedule;
