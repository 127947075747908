import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleNotch,
    faSave,
    faTrash,
    faServer,
    faShare,
} from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Input from "../../components/Form/Input";
import NumberInput from "../../components/Form/NumberInput";
import Textarea from "../../components/Form/Textarea";
import Selectbox from "../../components/Form/Selectbox";
import Toggle from "../../components/Toggle/Toggle";
import Button from "../../components/Button/Button";
import EditTemplateValidator from "../../components/Form/Validators/EditTemplateValidator";
import Modal from "../../components/Modal/Modal";
import VMClonesTable from "../../components/Pages/Vms/VMClonesTable";

const formatOptions = (array, valueKey, labelKey) => {
    return array.map((item) => {
        return {
            value: item[valueKey],
            label: item[labelKey],
        };
    });
};


function VmAdminEdit(props) {
    const [redirect, setRedirect] = useState(false);
    const [template, setTemplate] = useState(null);
    const [name, setName] = useState("");
    const [vmid, setVmid] = useState("");
    const [node, setNode] = useState("");
    const [port, setPort] = useState(443);
    const [description, setDescription] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [consoleEnabled, setConsoleEnabled] = useState(true);
    const [webUi, setWebUi] = useState(true);
    const [enabled, setEnabled] = useState(true);
    const [retired, setRetired] = useState(false);
    const [icon, setIcon] = useState("trellix");
    const [selectedVmType, setSelectedVmType] = useState(null);
    const [selectedFeModel, setSelectedFeModel] = useState(null);
    const [dev, setDev] = useState(false);
    const [clones, setClones] = useState(null);

    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState(false);

    const [isMoving, setIsMoving] = useState(false);

    const deleteVm = () => {
        setIsDeleting(true);

        let deletionPromise = axios
            .delete(`${process.env.REACT_APP_API}/vm_templates/${props.match.params.id}.json`)
            .then(() => setIsDeleting(false))
            .then(() => setConfirmDeletionModalOpen(false))
            .then(() => window.location.replace("/vms/admin"));

        toast.promise(deletionPromise, {
            loading: "Deleting...",
            success: "Deleted VM",
            error: "Failed to delete VM",
        });
    };

    const moveVmToProduction = () => {
        setIsMoving(true);
        let moveVMPromise = axios
            .get(`${process.env.REACT_APP_API}/vm_templates/make_prod/${props.match.params.id}.json`)
            .then(() => window.location.replace("/vms/admin"));

        toast.promise(moveVMPromise, {
            loading: "Moving to Production..",
            success: "Completed",
            error: "Failed to promote to production",
        });
    };

    const getTemplate = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/vm_templates/${props.match.params.id}.json`
            )
            .then(({ data }) => {
                setTemplate(data);
                setName(data.name);
                setVmid(data.vmid);
                setNode(data.node);
                setPort(data.webport || 443);
                setDescription(data.description || "");
                setUsername(data.username || "");
                setPassword(data.password || "");
                setConsoleEnabled(data.console);
                setWebUi(data.web);
                setEnabled(data.enabled);
                setRetired(data.retired);
                setDev(data.dev);
                setIcon(data.icon || "trellix");
                if (data.vm_type) {
                    setSelectedVmType({
                        value: data.vm_types.find(
                            (item) => item.vmtype === data.vm_type
                        ).id,
                        label: data.vm_type,
                    });
                }

                if (data.fe_model_id) {
                    setSelectedFeModel({
                        label: data.fe_model.find(
                            (item) => item.id === data.fe_model_id
                        ).model,
                        value: data.fe_model_id,
                    });
                }
                if (data.clones) {
                    setClones(data.clones);
                }
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getTemplate();
    }, []);

    const formatFormParams = () => {
        return {
            name,
            vmid,
            webport: port,
            node,
            description,
            username,
            password,
            console: consoleEnabled,
            web: webUi,
            enabled,
            retired,
            icon,
            vm_type_id: selectedVmType?.value,
            fe_model_id: selectedFeModel?.value,
        };
    };

    const submitForm = (params) => {
        let updatePromise = axios
            .put(
                `${process.env.REACT_APP_API}/vm_templates/${props.match.params.id}.json`,
                params
            )
            .then(({ data }) => {
                setRedirect(true);
            });

        toast.promise(updatePromise, {
            loading: "Updating VM Template...",
            success: "Updated VM Template",
            error: "Failed updating VM Template",
        });
    };

    if (redirect) {
        return <Redirect to="/vms/admin" />;
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>Edit Template | Admin | VM Lab</title>
            </Helmet>
            {template ? (
                <div>
                    <Breadcrumbs
                        crumbs={[
                            {
                                name: "Home",
                                to: "/",
                            },
                            {
                                name: "VM Lab",
                                to: "/vms",
                            },
                            {
                                name: "Lab Admin",
                                to: "/vms/admin",
                            },
                            {
                                name: `Edit (${template.name})`,
                                to: "/vms/admin",
                            },
                        ]}
                    />
                    <Container>
                        <Panel>
                            <h1 className="text-xl font-bold mb-4">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faServer}
                                    className="mr-2 text-gray-600"
                                />
                                Edit {template.name} Information
                            </h1>
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div>
                                    {/* First name input */}
                                    <label
                                        htmlFor="name"
                                        className="text-gray-600 block mb-1"
                                    >
                                        Name
                                    </label>
                                    <Input
                                        ariaLabel="VM name"
                                        name="name"
                                        id="name"
                                        type="text"
                                        required
                                        autoComplete="off"
                                        placeholder="VM name..."
                                        onChange={setName}
                                        value={name}
                                    />
                                </div>
                                <div>
                                    <p className="text-gray-600 block mb-1">
                                        Web Port
                                    </p>
                                    <NumberInput
                                        min={1}
                                        max={100000}
                                        step={1}
                                        value={port}
                                        onChange={setPort}
                                    />
                                </div>
                                <div>
                                    <p className="text-gray-600 block mb-1">
                                        VMID
                                    </p>
                                    <NumberInput
                                        disabled
                                        min={1}
                                        max={20000}
                                        step={1}
                                        value={vmid}
                                        onChange={setVmid}
                                    />
                                </div>
                                <div>
                                    {/* First name input */}
                                    <label
                                        htmlFor="node"
                                        className="text-gray-600 block mb-1"
                                    >
                                        Node
                                    </label>
                                    <Input
                                        ariaLabel="Node"
                                        name="node"
                                        id="node"
                                        type="text"
                                        required
                                        autoComplete="off"
                                        placeholder="Node..."
                                        onChange={setNode}
                                        value={node}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-4">
                                    <label
                                        htmlFor="description"
                                        className="text-gray-600 block mb-1"
                                    >
                                        Description
                                    </label>
                                    <Textarea
                                        ariaLabel="Description"
                                        name="description"
                                        id="description"
                                        placeholder="Enter a description for this VM template..."
                                        onChange={setDescription}
                                        value={description}
                                        rows={10}
                                    />
                                </div>
                                <div className="col-span-2">
                                    {/* First name input */}
                                    <label
                                        htmlFor="node"
                                        className="text-gray-600 block mb-1"
                                    >
                                        Username
                                    </label>
                                    <Input
                                        ariaLabel="Username"
                                        name="username"
                                        id="username"
                                        type="text"
                                        required
                                        autoComplete="off"
                                        placeholder="Username..."
                                        onChange={setUsername}
                                        value={username}
                                    />
                                </div>
                                <div className="col-span-2">
                                    {/* First name input */}
                                    <label
                                        htmlFor="node"
                                        className="text-gray-600 block mb-1"
                                    >
                                        Password
                                    </label>
                                    <Input
                                        ariaLabel="Password"
                                        name="password"
                                        id="password"
                                        type="text"
                                        required
                                        autoComplete="off"
                                        placeholder="Password..."
                                        onChange={setPassword}
                                        value={password}
                                    />
                                </div>

                                <div>
                                    <label className="text-gray-600 block mb-1">
                                        Console Access
                                    </label>
                                    <Toggle
                                        isEnabled={consoleEnabled}
                                        onClick={() =>
                                            setConsoleEnabled(!consoleEnabled)
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="text-gray-600 block mb-1">
                                        Web UI Access
                                    </label>
                                    <Toggle
                                        isEnabled={webUi}
                                        onClick={() => setWebUi(!webUi)}
                                    />
                                </div>
                                <div>
                                    <label className="text-gray-600 block mb-1">
                                        Enabled
                                    </label>
                                    <Toggle
                                        isEnabled={enabled}
                                        onClick={() => setEnabled(!enabled)}
                                    />
                                </div>
                                <div>
                                    <label className="text-gray-600 block mb-1">
                                        Retired
                                    </label>
                                    <Toggle
                                        isEnabled={retired}
                                        onClick={() => setRetired(!retired)}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label className="text-gray-600 block mb-1">
                                        Icon
                                    </label>
                                    <div className="grid grid-cols-2 grid-rows-2 gap-4">
                                        <label
                                            className="flex items-center cursor-pointer"
                                            htmlFor="fireeyeIcon"
                                            onClick={() => setIcon("trellix")}
                                        >
                                            <input
                                                className="mr-4"
                                                type="radio"
                                                name="iconPicker"
                                                value="trellix"
                                                checked={icon === "trellix"}
                                                onChange={() => null}
                                            />
                                            <span className="flex items-center">
                                                <img
                                                    alt="Trellix Logo"
                                                    src="/images/trellix.png"
                                                    className="h-6 mr-2"
                                                />
                                                Trellix
                                            </span>
                                        </label>
                                        <label
                                            className="flex items-center cursor-pointer"
                                            htmlFor="windowsIcon"
                                            onClick={() => setIcon("windows")}
                                        >
                                            <input
                                                className="mr-4"
                                                type="radio"
                                                name="iconPicker"
                                                value="windows"
                                                checked={icon === "windows"}
                                                onChange={() => null}
                                            />
                                            <span className="flex items-center">
                                                <img
                                                    alt="Windows Logo"
                                                    src="/images/windows.png"
                                                    className="h-6 mr-2"
                                                />
                                                Windows
                                            </span>
                                        </label>
                                        <label
                                            className="flex items-center cursor-pointer"
                                            htmlFor="kaliIcon"
                                            onClick={() => setIcon("kali")}
                                        >
                                            <input
                                                className="mr-4"
                                                type="radio"
                                                name="iconPicker"
                                                value="kali"
                                                checked={icon === "kali"}
                                                onChange={() => null}
                                            />
                                            <span className="flex items-center">
                                                <img
                                                    alt="Kali Linux Logo"
                                                    src="/images/kali.svg"
                                                    className="h-6 w-6 mr-2"
                                                />
                                                Kali Linux
                                            </span>
                                        </label>
                                        <label
                                            className="flex items-center cursor-pointer"
                                            htmlFor="ubuntuIcon"
                                            onClick={() => setIcon("ubuntu")}
                                        >
                                            <input
                                                className="mr-4"
                                                type="radio"
                                                name="iconPicker"
                                                value="ubuntu"
                                                checked={icon === "ubuntu"}
                                                onChange={() => null}
                                            />
                                            <span className="flex items-center">
                                                <img
                                                    alt="Ubuntu"
                                                    src="/images/ubuntu.png"
                                                    className="h-6 mr-2"
                                                />
                                                Ubuntu
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <label className="text-gray-600 block mb-1">
                                        VM Type
                                    </label>
                                    <Selectbox
                                        onChange={setSelectedVmType}
                                        value={selectedVmType}
                                        options={formatOptions(
                                            template.vm_types,
                                            "id",
                                            "vmtype"
                                        )}
                                    />
                                </div>
                                {selectedVmType &&
                                    selectedVmType.label === "Trellix" ? (
                                    <div>
                                        <label className="text-gray-600 block mb-1">
                                            Trellix Model
                                        </label>
                                        <Selectbox
                                            onChange={setSelectedFeModel}
                                            value={selectedFeModel}
                                            options={formatOptions(
                                                template.fe_model,
                                                "id",
                                                "model"
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className="flex justify-between">
                                <Button
                                    isLink
                                    to="/vms/admin"
                                    color="dark-gray"
                                    size="md"
                                >
                                    Cancel
                                </Button>

                                {(clones && clones.length === 0) && ( (!dev && retired) || dev) ? <>
                                    <Button
                                        color="red"
                                        size="md"
                                        onClick={() => setConfirmDeletionModalOpen(true)}
                                        disabled={clones && clones.length > 0}
                                    >
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faTrash}
                                            className="mr-2"
                                        />{" "}
                                        Delete Template
                                    </Button>
                                </> : <></>
                                }
                                {dev ? <>
                                    <Button
                                        color="blue"
                                        size="md"
                                        onClick={() => moveVmToProduction()}
                                    >
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faShare}
                                            className="mr-2"
                                        />{" "}

                                        Move to Production
                                    </Button>


                                </> : <></>
                                }
                                <Button
                                    color="teal"
                                    size="md"
                                    onClick={() =>
                                        EditTemplateValidator(
                                            formatFormParams()
                                        ) && submitForm(formatFormParams())
                                    }
                                >
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={faSave}
                                        className="mr-2"
                                    />{" "}
                                    Save
                                </Button>
                            </div>
                        </Panel>
                    </Container>
                </div>
            ) : (
                <div className="w-full text-center pt-20 flex flex-col space-y-4 text-gray-600">
                    <div>
                        <FontAwesomeIcon size="3x" spin icon={faCircleNotch} />
                    </div>
                    <div className="text-xl">Loading...</div>
                </div>
            )}
            <Container>
                <VMClonesTable clones={clones} />
            </Container>
            <Modal
                isOpen={confirmDeletionModalOpen}
                setIsOpen={setConfirmDeletionModalOpen}

                headerContent={`Delete "${name}" VM?`}
                footerContent={
                    <Button
                        disabled={isDeleting}
                        color="green"
                        onClick={() => deleteVm()}
                    >
                        {isDeleting ? (
                            <FontAwesomeIcon spin icon={faCircleNotch} />
                        ) : (
                            "Confirm Deletion"
                        )}
                    </Button>
                }
            >
                Are you sure you want to delete this VM Template?
                <br />
                <br />
                <b>This will only succeed if there are no clones for this Template</b><br />
                <b>This action cannot be undone.</b>
            </Modal>

        </div>

    );
}

export default withRouter(VmAdminEdit);
