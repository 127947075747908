import React from "react";
import toast from "react-hot-toast";

function EditTemplateValidator(params) {
    let errors = [];
    let validated = true;

    /**
     * Check each and every element that is required
     */
    if (!params.name) {
        errors.push(<li key={1}>Template name is not set.</li>);
        validated = false;
    }

    if (!params.vmid) {
        errors.push(<li key={2}>A VMID is not set.</li>);
        validated = false;
    }

    if (!params.node) {
        errors.push(<li key={3}>Proxmox node is not set.</li>);
        validated = false;
    }

    if (!params.description) {
        errors.push(<li key={4}>Description is not set.</li>);
        validated = false;
    }

    if (!params.username) {
        errors.push(<li key={5}>Username is not set.</li>);
        validated = false;
    }

    if (!params.password) {
        errors.push(<li key={6}>Password is not set.</li>);
        validated = false;
    }

    if (!validated) {
        toast.error((t) => (
            <div>
                <div>Please address the following errors:</div>
                <div className="text-sm mt-1">
                    <ul className="list-inside list-disc">{errors}</ul>
                </div>
            </div>
        ));
    }

    return validated;
}

export default EditTemplateValidator;
