import React from "react";
import toast from "react-hot-toast";

function CreateCourseValidator(params) {
    let errors = [];
    let validated = true;

    /**
     * Check each and every element that is required
     */
    if (!params.curriculum) {
        errors.push(<li key={1}>Curriculum is not set.</li>);
        validated = false;
    }

    if (!params.courseType) {
        errors.push(<li key={2}>Course Type is not set.</li>);
        validated = false;
    }

    if (!params.timezone) {
        errors.push(<li key={3}>Region is not set.</li>);
        validated = false;
    }

    if (!params.timezone) {
        errors.push(<li key={4}>Timezone is not set.</li>);
        validated = false;
    }

    if (!params.deliveryMethod) {
        errors.push(<li key={5}>Delivery Method is not set.</li>);
        validated = false;
    }

    if (!params.instructor) {
        errors.push(<li key={6}>Primary Instructor is not set.</li>);
        validated = false;
    }

    if (!params.maximumStudents) {
        errors.push(<li key={7}>Maximum student is not set.</li>);
        validated = false;
    }

    if (!params.startDate) {
        errors.push(<li key={8}>Start date is not set.</li>);
        validated = false;
    }

    if (!validated) {
        toast.error((t) => (
            <div>
                <div>Please address the following errors:</div>
                <div className="text-sm mt-1">
                    <ul className="list-inside list-disc">{errors}</ul>
                </div>
            </div>
        ));
    }

    return validated;
}

export default CreateCourseValidator;
