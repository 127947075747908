import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
    faBook,
    faClipboardList,
    faFlask,
    faVideo,
    faQuestion,
    faQuestionCircle,
    faClipboard,
    faCalendarWeek,
    faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";

function CourseResourceView(props) {
    const [courseInformation, setCourseInformation] = useState(null);

    const getCourse = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/student/course/${props.match.params.id}.json`
            )
            .then(({ data }) => {
                setCourseInformation(data);
            })
            .catch((error) => console.error(error));
    };

    useEffect(getCourse, [props.match.params.id]);

    if (!courseInformation) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>View</title>
            </Helmet>
            <Breadcrumbs
                noMarginBottom
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Course Resources",
                        to: "/student/course_resources",
                    },
                    {
                        name: "View",
                        to: `/student/course_resources/:id`,
                    },
                ]}
            />
            <div>
                <div className="bg-teal-100 text-teal-700 w-full p-10 text-center">
                    <h1 className="font-extrabold text-2xl mb-4">
                        {courseInformation.course.curriculum_title}
                    </h1>
                    <h2 className="text-lg font-semibold leading-3 mb-4">
                        {courseInformation.course.customer_name}
                    </h2>
                    <div className="flex space-x-4 justify-center">
                        <span>
                            <FontAwesomeIcon
                                icon={faUserTie}
                                className="mr-2"
                            />
                            Instructor(s):
                        </span>
                        {courseInformation.course_instructors.map(
                            (instructor, index) => (
                                <span key={index}>{instructor.name}</span>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <Container>
                    <div className="grid grid-cols-10 gap-4">
                        <div className="col-span-7">
                            <Panel>
                                <h1 className="text-xl font-extrabold mb-4 pb-1 border-b border-gray-200">
                                    Description
                                </h1>
                                <ReactMarkdown className="markdown">
                                    {
                                        courseInformation.course
                                            .curriculum_description
                                    }
                                </ReactMarkdown>
                                { moment(
                                    courseInformation.course.startDate
                                ).isSameOrBefore(moment()) && (
                                    <div className="mt-4 pt-4 border-t border-gray-300 text-right">
                                        <a
                                            href={
                                                courseInformation.course.survey
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <Button size="md" color="teal">
                                                <FontAwesomeIcon
                                                    icon={faClipboardList}
                                                    className="mr-3"
                                                />
                                                Take Survey
                                            </Button>
                                        </a>
                                    </div>
                                )}
                            </Panel>
                        </div>
                        <div className="col-span-3 pb-4">
                            <Panel className="h-full">
                                <h1 className="text-xl font-extrabold mb-4 pb-1 border-b border-gray-200">
                                    Resources
                                </h1>
                                {courseInformation.guides.length > 0 && (
                                    <div className="mb-4">
                                        <h2 className="text-lg font-bold mb-1">
                                            <FontAwesomeIcon
                                                icon={faBook}
                                                className="text-gray-500 mr-2"
                                            />
                                            Guides
                                        </h2>
                                        <div className="flex-col space-y-2">
                                            {courseInformation.guides.map(
                                                (guide, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex"
                                                    >
                                                        <div className="flex-shrink-0 w-4">
                                                            {index + 1}.
                                                        </div>
                                                        <div className="flex-grow pl-4 truncate">
                                                            <Tippy
                                                                placement="left"
                                                                content={
                                                                    guide.filename
                                                                }
                                                            >
                                                                <a
                                                                    href={`${process.env.REACT_APP_API}/resource_link/${guide.id}`}
                                                                    className="text-teal-500 hover:text-teal-700"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {guide.name}
                                                                </a>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}

                                {courseInformation.labmaterials.length > 0 && (
                                    <div className="mb-4">
                                        <h2 className="text-lg font-bold mb-1">
                                            <FontAwesomeIcon
                                                icon={faFlask}
                                                className="text-gray-500 mr-2"
                                            />
                                            Lab Materials
                                        </h2>
                                        <div className="flex-col space-y-2">
                                            {courseInformation.labmaterials.map(
                                                (guide, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex"
                                                    >
                                                        <div className="flex-shrink-0 w-4">
                                                            {index + 1}.
                                                        </div>
                                                        <div className="flex-grow pl-4 truncate">
                                                            <Tippy
                                                                placement="left"
                                                                content={
                                                                    guide.filename
                                                                }
                                                            >
                                                                <a
                                                                    href={`${process.env.REACT_APP_API}/resource_link/${guide.id}`}
                                                                    className="text-teal-500 hover:text-teal-700"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {guide.name}
                                                                </a>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                                {courseInformation.videos.length > 0 && (
                                    <div className="mb-4">
                                        <h2 className="text-lg font-bold mb-1">
                                            <FontAwesomeIcon
                                                icon={faVideo}
                                                className="text-gray-500 mr-2"
                                            />
                                            Videos
                                        </h2>
                                        <div className="flex-col space-y-2">
                                            {courseInformation.videos.map(
                                                (video, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex"
                                                    >
                                                        <div className="flex-shrink-0 w-4">
                                                            {index + 1}.
                                                        </div>
                                                        <div className="flex-grow pl-4 truncate">
                                                            <Tippy
                                                                placement="left"
                                                                content={
                                                                    video.name
                                                                }
                                                            >
                                                                <a
                                                                    href={`${process.env.REACT_APP_API}/resource_link/${video.id}`}
                                                                    className="text-teal-500 hover:text-teal-700"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {video.name}
                                                                </a>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}


                            </Panel>
                        </div>
                    </div>

                    <Panel className="mb-4">
                        <h1 className="text-xl font-extrabold mb-4 pb-1 border-b border-gray-200">
                            Training Address
                        </h1>
                        {courseInformation.course.trainingAddress ? (
                            <CopyToClipboard
                                text={courseInformation.course.trainingAddress}
                                onCopy={() => toast.success("Copied")}
                            >
                                <div className="flex-grow whitespace-pre-wrap break-words font-mono p-4 border border-gray-300 bg-gray-100 rounded-md relative cursor-pointer">
                                    <div className="absolute top-0 right-0 p-3">
                                        <FontAwesomeIcon
                                            icon={faClipboard}
                                            className="text-teal-500 hover:text-teal-700"
                                        />
                                    </div>
                                    {courseInformation.course.trainingAddress}
                                </div>
                            </CopyToClipboard>
                        ) : (
                            <div>No address provided.</div>
                        )}
                    </Panel>

                    <Panel>
                        <div className="flex items-center mb-4 pb-3 border-b border-gray-200">
                            <div className="flex-grow">
                                <h1 className="text-xl font-extrabold">
                                    Schedule
                                </h1>
                            </div>
                            <div className="flex-shrink-0">
                                <a
                                    // className="flex"
                                    href={`${process.env.REACT_APP_API}/course/ics?course=${courseInformation.course.id}`}
                                >
                                    <Button color="indigo">
                                        <FontAwesomeIcon
                                            icon={faCalendarWeek}
                                            className="mr-3"
                                        />
                                        Download ICS
                                    </Button>
                                </a>
                            </div>
                        </div>

                        <p className="mb-4">
                            This course is due to start on{" "}
                            {courseInformation.course.startDate} (
                            {moment(courseInformation.course.startDate).format(
                                "dddd"
                            )}
                            ). Please see below for this courses daily schedule.
                        </p>
                        <div className="grid grid-cols-5 gap-4">
                            {courseInformation.timings.map((day, index) => (
                                <div
                                    key={index}
                                    className="bg-gray-100 border border-gray-200 p-4 rounded-md"
                                >
                                    <p className="font-bold mb-2">
                                        Day {day.dayNumber}
                                    </p>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            Start <br />
                                            {day.startTime}
                                        </div>
                                        <div>
                                            <FontAwesomeIcon
                                                className="text-gray-600"
                                                icon={faArrowRight}
                                            />
                                        </div>
                                        <div>
                                            End <br /> {day.endTime}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Panel>
                </Container>
            </div>
        </div>
    );
}

export default withRouter(CourseResourceView);
