import React from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import useToken from "./components/App/useToken";
import Header from "./components/Layout/Header";
import ToastComponent from "./components/Toast/ToastComponent";
import ProtectedRoute from "./components/Utils/ProtectedRoute";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import NotFound from "./pages/Errors/NotFound";
import Users from "./pages/User/Users";
import UserProfile from "./pages/User/UserProfile";
import UserProfileEdit from "./pages/User/UserProfileEdit";
import CourseResources from "./pages/Student/CourseResources";
import Curriculums from "./pages/Curriculums/Curriculums";
import CurriculumView from "./pages/Curriculums/CurriculumView";
import CurriculumNew from "./pages/Curriculums/CurriculumNew";
import CurriculumEdit from "./pages/Curriculums/CurriculumEdit";
import Resources from "./pages/Resources/Resources";
import Courses from "./pages/Courses/Courses";
import CoursesRoster from "./pages/Courses/CoursesRoster";
import CourseNew from "./pages/Courses/CourseNew";
import CourseView from "./pages/Courses/CourseView";
import CourseEdit from "./pages/Courses/CourseEdit";

import "./styles/tailwindcss.scss";
import CourseResourceView from "./pages/Student/CourseResourceView";
import ChangePassword from "./pages/User/ChangePassword";
import AdminUserProfileEdit from "./pages/User/AdminUserProfileEdit";
import PasswordReset from "./components/Pages/Login/PasswordReset";
import Vms from "./pages/Vms/Vms";
import VmAdmin from "./pages/Vms/VmAdmin";
import VmAdminEdit from "./pages/Vms/VmAdminEdit";
import ListAllVms from "./pages/Vms/ListAllVms";

function App() {
    /**
     * Set defaults for axios to pass the _portal_session
     * cookie through to the API with every request
     */
    axios.defaults.withCredentials = true;

    /**
     *
     */
    const { token, setToken, removeToken } = useToken();

    /**
     * Catch any error returned from axios calls and return
     * the user to the login screen.
     * TODO: Return an "Oops" screen if a 500 error, vs. returning
     * them to login where the request may fail again after logging in
     */
 /*   if (process.env.NODE_ENV !== "development") {
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                setToken(false);
                return Promise.reject(error);
            }
        );
    }

 */

    /**
     * Display the Register page if the URL contains /register/
     * TODO: Check if this is used anywhere else and make more robust
     */
    if (window.location.pathname.includes("/register/")) {
        return <Register />;
    }

    /**
     * Display the Password Reset Page if the route matches
     */
    if (window.location.pathname.includes("/users/update_password/")) {
        return <PasswordReset />;
    }

    /**
     * Display the login page if the user has no token
     */
    if (!token) {
        return <Login setToken={setToken} />;
    }

    return (
        <div className="font-body bg-gray-100 min-h-screen flex flex-col text-gray-900 pb-10">
            <Helmet
                titleTemplate={`%s | ${process.env.REACT_APP_NAME}`}
                defaultTitle={process.env.REACT_APP_NAME}
            />

            <ToastComponent />

            <Router>
                {/* Navigation header */}
                <Header token={token} removeToken={removeToken} />

                {/* Fill remaining height */}
                <div className="flex flex-grow mt-4">
                    {/* Routing */}
                    <Switch>
                        {/* Public pages */}
                        <Route
                            exact
                            path="/"
                            component={() => <Home token={token} />}
                        />

                        {/* Course Pages */}
                        <Route
                            exact
                            path="/student/course_resources"
                            component={() => <CourseResources token={token} />}
                        />

                        <Route
                            exact
                            path="/student/course_resources/:id"
                            component={() => (
                                <CourseResourceView token={token} />
                            )}
                        />

                        {/* User page */}
                        <ProtectedRoute
                            exact
                            path="/users"
                            component={() => <Users token={token} />}
                            requires={["user-admin"]}
                            claims={token.claims}
                        />

                        {/* User Profile routes */}
                        <Route
                            exact
                            path="/user/profile"
                            component={() => <UserProfile token={token} />}
                        />

                        <Route
                            exact
                            path="/user/profile/edit"
                            component={() => (
                                <AdminUserProfileEdit
                                    token={token}
                                    setToken={setToken}
                                />
                            )}
                        />

                        <ProtectedRoute
                            exact
                            path="/user/admin/:username"
                            component={() => (
                                <AdminUserProfileEdit
                                    token={token}
                                    isEdit={true}
                                />
                            )}
                            requires={["user-admin", "guest-instructor"]}
                            claims={token.claims}
                        />

                        <Route
                            exact
                            path="/user/change_password"
                            component={() => <ChangePassword token={token} />}
                        />

                        {/* Curriculum Pages */}
                        <ProtectedRoute
                            exact
                            path="/curriculums"
                            component={() => <Curriculums token={token} />}
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/curriculums/new"
                            component={CurriculumNew}
                            requires={["course-coordinator"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/curriculums/:id"
                            component={() => <CurriculumView token={token} />}
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/curriculums/:id/edit"
                            component={CurriculumEdit}
                            requires={["course-coordinator"]}
                            claims={token.claims}
                        />

                        {/* Resource Management */}
                        <ProtectedRoute
                            exact
                            path="/resources"
                            component={Resources}
                            requires={["course-coordinator"]}
                            claims={token.claims}
                        />

                        {/* Course Management */}
                        <ProtectedRoute
                            exact
                            path="/courses"
                            component={Courses}
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />
                        <ProtectedRoute
                            exact
                            path="/courses/roster/:id"
                            component={CoursesRoster}
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/courses/new"
                            component={CourseNew}
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/courses/:id/edit"
                            component={CourseEdit}
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/courses/:id"
                            component={() => <CourseView token={token} /> }
                            requires={["course-coordinator", "guest-instructor"]}
                            claims={token.claims}
                        />

                        {/* VM Lab */}
                        <ProtectedRoute
                            exact
                            path="/vms"
                            requires={["vm_lab_user"]}
                            claims={token.claims}
                            component={() => <Vms token={token} />}
                        />

                        <ProtectedRoute
                            exact
                            path="/vms/admin"
                            component={VmAdmin}
                            requires={["vm-admin"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/vms/listallvms"
                            component={ListAllVms}
                            requires={["user-admin"]}
                            claims={token.claims}
                        />

                        <ProtectedRoute
                            exact
                            path="/vms/admin/:id"
                            component={VmAdminEdit}
                            requires={["vm-admin"]}
                            claims={token.claims}
                        />

                        <Route component={() => <NotFound />} />
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;
