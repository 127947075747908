import Link from "../../Link/Link";
import Tippy from "@tippyjs/react";

function CurriculumLink(props) {
    return (
        <Tippy placement="top" content={`${props.data.title || props.data.course.curriculum_title}`}>
            <div>
            <Link to={`/curriculums/${props.data.id || props.data.course.curriculum_id}`}>{props.value}</Link>
            </div>
        </Tippy>
    );
}

export default CurriculumLink;
