import React from "react";
import Button from "../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import {
    faClipboardList,
    faDownload,
    faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import StudentCertificateDownload from "../Users/StudentCertificateDownload";

function GoToCourseResource(props) {
    return (
        <div className="flex space-x-2">
            <a
                className="flex"
                href={`${process.env.REACT_APP_API}/course/ics?course=${props.data.course_id}`}
            >
                <Button color="indigo">
                    <FontAwesomeIcon icon={faCalendarWeek} className="mr-3" />
                    ICS
                </Button>
            </a>
            <StudentCertificateDownload data={props.data} token={props.token} />

            { props.data.survey !== null && (
                <Tippy placement="top" content="Take Survey">
                    <a
                        href={props.data.survey}
                        target="_blank"
                        rel="noreferrer"
                        className="flex"
                    >
                        <Button color="teal">
                            <FontAwesomeIcon icon={faClipboardList} />
                        </Button>
                    </a>
                </Tippy>
            )}
        </div>
    );
}

export default GoToCourseResource;
