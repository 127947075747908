import React from "react";
import PropType from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function StackedLabel({ icon, label, value }) {
    return (
        <div className="flex flex-col">
            <div className="text-gray-600">
                {icon && (
                    <FontAwesomeIcon fixedWidth icon={icon} className="mr-2" />
                )}
                {label}
            </div>
            <div>{value}</div>
        </div>
    );
}

StackedLabel.propTypes = {
    label: PropType.string.isRequired,
};

export default StackedLabel;
