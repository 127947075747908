import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "../../Table/Table";
import AwaitingActions from "../../CellRenderers/Courses/AwaitingActions";
import RegisterLink from "../../CellRenderers/Courses/RegisterLink";

function TokensUsers(props) {
    const [users, setUsers] = useState(null);
    

    const getUsers = () => {
        axios
            .get(`${process.env.REACT_APP_API}/tokens.json`)
            .then(({ data }) => {
                setUsers(data);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getUsers();
    }, []);

    const gridOptions = {
        getRowNodeId(data) {
            return data.token;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Email",
                field: "email",
            },
            {
                headerName: "Token",
                field: "token",
                cellRendererFramework: RegisterLink,
            },
            {
                headerName: "Created",
                field: "created_at",
            },
            {
                headerName: "Last Sent",
                field: "updated_at",
            },
            {
                headerName: "Actions",
                field: "id",
                cellRendererFramework: AwaitingActions,
                cellRendererParams: {
                    getData: getUsers,
                },
            },
        ],
    };

    return <Table gridOptions={gridOptions} rowData={users} />;
}

export default TokensUsers;
