import {
    faCircleNotch,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import Alert from "../../components/Alert/Alert";
import Container from "../../components/Layout/Container";
import Tile from "../../components/Pages/Home/Tile";
import qs from "qs";

function renderTiles(tiles, searchString) {
    let renderedTiles = tiles.map((tile, index) => {
        if (tile.name.toLowerCase().includes(searchString.toLowerCase())) {
            return <Tile key={index} tile={tile} />;
        }

        return null;
    });

    if (renderedTiles.every((element) => element === null)) {
        return (
            <div className="flex justify-center items-center h-32 col-span-4">
                <h1 className="text-2xl text-gray-600">
                    No tiles found for "{searchString}", please try again.
                </h1>
            </div>
        );
    }

    return renderedTiles;
}

function Home({ token, ...props }) {
    const [loading, setLoading] = useState(true);
    const [tiles, setTiles] = useState(null);
    const [searchString, setSearchString] = useState("");
    const [permissionsIssue, setPermissionsIssue] = useState(false);

    // Get the query string params (if the user has been redirected)
    const queryString = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });

    // Check if there's any message in the queryString
    if (queryString.hasOwnProperty("message") && queryString.message !== "registered" && !permissionsIssue) {
        setPermissionsIssue(true);
    }

    const getTiles = () => {
        axios
            .get(`${process.env.REACT_APP_API}/landing.json`)
            .then(({ data }) => {
                setLoading(false);
                setTiles(data);
            })
            .catch((error) => console.error(error));
    };

    /**
     * Get the tiles as soon as the component mounts
     */
    useEffect(() => {
        getTiles();
    }, []);

    return (
        <Container>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <div className="flex flex-row items-center mb-4">
                <div>
                    <h1 className="text-xl font-bold">
                        Welcome, {token.profile.firstname}
                    </h1>
                </div>
                <div className="ml-auto">
                    <input
                        type="text"
                        className="bg-white border border-gray-300 rounded-md px-3 py-1"
                        placeholder="Search tiles..."
                        onChange={(e) => setSearchString(e.target.value)}
                        value={searchString}
                    />
                </div>
            </div>

            {permissionsIssue && (
                <Alert
                    title="Insufficient Permissions"
                    color="red"
                    icon={faTimesCircle}
                    className="mb-4"
                >
                    You don't have the required permissions to access "
                    {queryString.page}". If you think this was an error, please
                    report it to the admins.
                </Alert>
            )}

            {loading ? (
                <div className="flex justify-center items-center h-32">
                    <h1 className="text-2xl text-gray-600">
                        <FontAwesomeIcon
                            spin
                            icon={faCircleNotch}
                            className="mr-4"
                        />{" "}
                        Loading tiles...
                    </h1>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {tiles && renderTiles(tiles, searchString)}
                </div>
            )}
        </Container>
    );
}

export default withRouter(Home);
