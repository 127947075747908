import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs, faSync } from "@fortawesome/free-solid-svg-icons";
import ConsoleToggle from "../../components/CellRenderers/Vms/ConsoleToggle";
import WebToggle from "../../components/CellRenderers/Vms/WebToggle";
import EnabledToggle from "../../components/CellRenderers/Vms/EnabledToggle";
import Actions from "../../components/CellRenderers/Vms/Actions";
import TemplateName from "../../components/CellRenderers/Vms/TemplateName";
import VmTypeDetails from "../../components/CellRenderers/Vms/VmTypeDetails";
import Button from "../../components/Button/Button";

function VmAdmin(props) {
    const [templates, setTemplates] = useState(null);
    const [dev_templates, setDev_templates] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const getTemplates = () => {
        axios
            .get(`${process.env.REACT_APP_API}/vm_templates.json`)
            .then(({ data }) => {
                setTemplates(data.vm_templates);
                setDev_templates(data.vm_devs)
            })
            .catch((error) => console.error(error));
    };

    const refreshTemplates = () => {
        setIsRefreshing(true);
        axios
            .get(`${process.env.REACT_APP_API}/vm_templates/refresh.json`)
            .then(({ data }) => {
                setTemplates(data.vm_templates);
                setDev_templates(data.vm_devs);
            })
            .then(() => setIsRefreshing(false))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getTemplates();
    }, []);

    /**
     * Set up the gridOptions for the table
     */
    const LabVMgridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Name",
                field: "name",
                width: 500,
                cellRendererFramework: TemplateName,
            },
            {
                headerName: "VMID",
                field: "vmid",
            },
            {
                headerName: "Node",
                field: "node",
            },
            {
                headerName: "Console",
                field: "console",
                cellRendererFramework: ConsoleToggle,
            },
            {
                headerName: "Web",
                field: "web",
                cellRendererFramework: WebToggle,
            },
            {
                headerName: "Enabled",
                field: "enabled",
                cellRendererFramework: EnabledToggle,
            },
            {
                headerName: "VM Type",
                field: "vm_type",
                width: 250,
                cellRendererFramework: VmTypeDetails,
            },
            {
                headerName: "Count",
                field: "count",
            },
            {
                headerName: "Actions",
                field: "actions",
                cellRendererFramework: Actions,
            },
        ],
    };

    return (
        <div className="w-full">
            <Helmet>
                <title>Admin | VM Lab</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "VM Lab",
                        to: "/vms",
                    },
                    {
                        name: "Lab Admin",
                        to: "/vms/admin",
                    },
                ]}
            />
            <Container>
                <div className="flex justify-between items-end mb-4">
                    <Button
                        disabled={isRefreshing}
                        onClick={() => refreshTemplates()}
                    >
                        <FontAwesomeIcon
                            spin={isRefreshing}
                            fixedWidth
                            icon={faSync}
                            className="mr-2"
                        />
                        Refresh
                    </Button>
                </div>
            </Container>

            <Container>
                <Panel>
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-xl font-bold">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faCogs}
                                className="mr-2 text-gray-600"
                            />
                            Lab Template Administration
                        </h1>
                    </div>

                    <Table gridOptions={LabVMgridOptions} rowData={templates} />
                </Panel>
            </Container>

            <Container>
                <Panel>
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-xl font-bold">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faCogs}
                                className="mr-2 text-gray-600"
                            />
                            Lab Template Development
                        </h1>
                    </div>

                    <Table gridOptions={LabVMgridOptions} rowData={dev_templates} />
                </Panel>
            </Container>


        </div>
    );
}

export default VmAdmin;
