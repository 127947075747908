import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Toggle from "../../Toggle/Toggle";

function ConsoleToggle(props) {
    const [enabled, setEnabled] = useState(props.value);

    const toggleAction = (value) => {
        let updatePromise = axios
            .put(
                `${process.env.REACT_APP_API}/vm_templates/${props.data.id}.json`,
                {
                    console: value,
                }
            )
            .then(() => setEnabled(value));

        toast.promise(updatePromise, {
            loading: "Updating console value...",
            success: "Updated console value",
            error: "Failed updating console value",
        });
    };

    return (
        <Toggle isEnabled={enabled} onClick={() => toggleAction(!enabled)} />
    );
}

export default ConsoleToggle;
