import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

function RegisterLink(props) {
    return (
        <CopyToClipboard
            text={`${window.location.origin}/register/${props.value}`}
            onCopy={() => toast.success("Copied")}
        >
            <span className="text-teal-500 hover:text-teal-700 cursor-pointer">
                <FontAwesomeIcon icon={faClipboard} className="mr-2" />
                {props.value}
            </span>
        </CopyToClipboard>
    );
}

export default RegisterLink;
