import React from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { ConditionalWrapper } from "../Utils/ConditionalWrapper";
import { Link } from "react-router-dom";

class Button extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        if (this.props.disabled) {
            e.preventDefault();
            return;
        }

        if (this.props.onClick) {
            return this.props.onClick(e);
        }
    }

    getColorShades(color) {
        /**
         * Create a list of colors that we want to override the color
         * shading defaults for
         */
        if (color.includes("gray")) {
            return {
                default: 900,
                hover: 700,
                focus: 700,
            };
        }

        return {
            default: 500,
            hover: 700,
            focus: 700,
        };
    }

    render() {
        const colorShadeOverrides = this.getColorShades(this.props.color);
        const classnames = classNames(
            "relative",
            "text-white",
            "rounded-md",
            "border",
            "border-transparent",
            "transition-all",
            "duration-200",
            `bg-${this.props.color}-${colorShadeOverrides.default}`,
            `hover:bg-${this.props.color}-${colorShadeOverrides.hover}`,
            `focus:bg-${this.props.color}-${colorShadeOverrides.focus}`,
            "focus:outline-none",
            "focus:shadow-outline",
            "disabled:opacity-25",
            "disabled:cursor-not-allowed",
            {
                "px-2 py-1 text-sm": this.props.size.includes("sm"),
                "px-3 py-2": this.props.size.includes("md"),
                "px-4 py-3 text-lg": this.props.size.includes("lg"),
                "block w-full": this.props.block,
                "inline-block": !this.props.block,
                "rounded-l-none": this.props.noRoundingLeft,
                "rounded-r-none": this.props.noRoundingRight,
            }
        );

        const Tag = this.props.isLink ? Link : "button";

        return (
            <Tag
                onClick={this.onClick}
                className={classnames}
                type={this.props.type}
                to={this.props.to}
                disabled={this.props.disabled}
            >
                <ConditionalWrapper
                    condition={this.props.tooltip}
                    wrapper={(children) => (
                        <Tippy
                            content={this.props.tooltip}
                            placement={this.props.tooltipPlacement}
                        >
                            <span>{this.props.children}</span>
                        </Tippy>
                    )}
                >
                    {this.props.children}
                </ConditionalWrapper>
            </Tag>
        );
    }
}

Button.propTypes = {
    onClick: PropTypes.func,
    block: PropTypes.bool,
    size: PropTypes.string,
};

Button.defaultProps = {
    isLink: false,
    to: "/",
    type: null,
    size: "sm",
    color: "blue",
    block: false,
    noRoundingLeft: false,
    noRoundingRight: false,
    tooltip: false,
    tooltipPlacement: "top",
    disabled: false,
};

export default Button;
