import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import qs from "qs";
import logo from "../../images/Trellix_LOGO_Color_Graphite.png";
import LoginForm from "../../components/Pages/Login/LoginForm";
import Alert from "../../components/Alert/Alert";
import TwoFactorSetup from "../../components/Pages/Login/TwoFactorSetup";
import VerifyOtp from "../../components/Pages/Login/VerifyOtp";
import ToastComponent from "../../components/Toast/ToastComponent";
import {
    faCheckCircle,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import ForgotPassword from "../../components/Pages/Login/ForgotPassword";

function Login({ setToken }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [hasTwoFactor, setHasTwoFactor] = useState(false);
    const [isComingFromRegistration, setIsComingFromRegistration] =
        useState(false);
    const [isComingFromInvalidToken, setIsComingFromInvalidToken] =
        useState(false);
    const [needsToRedirect, setNeedsToRedirect] = useState(null);
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [hasForgotPassword, setHasForgotPassword] = useState(false);

    // Get the query string params (if the user has been redirected)
    const queryString = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });

    // Check if the query string contains the 'registered' string and show an alert
    // message to tell the user their registration was successful.
    if (
        "message" in queryString &&
        queryString.message === "registered" &&
        !isComingFromRegistration
    ) {
        setIsComingFromRegistration(true);
    }

    // Check if the query string contains the 'already_registered' string and show an alert
    // message to tell the user they have already registered.
    if (
        "message" in queryString &&
        queryString.message === "already_registered" &&
        !isComingFromInvalidToken
    ) {
        setIsComingFromInvalidToken(true);
    }

    // Check if the query string contains the 'registered' string and show an alert
    // message to tell the user their registration was successful.
    if ("uri" in queryString && needsToRedirect === null) {
        setNeedsToRedirect(queryString.uri);
    }

    return (
        <div className="font-body bg-gray-100 min-h-screen flex justify-center items-center">
            <Helmet title="Log in | Training Labs 2.0 | Trellix" />

            <ToastComponent />

            <div className="max-w-lg w-full py-12 px-6">
                <img
                    src={logo}
                    className="mx-auto h-12 w-auto"
                    alt="Trellix Logo"
                />
                <p className="mt-2 text-lg text-center text-gray-700">
                    Training Labs 2.0
                </p>
                {hasForgotPassword ? (
                    <ForgotPassword
                        setHasForgotPassword={setHasForgotPassword}
                    />
                ) : !loggedIn ? (
                    <div>
                        {isComingFromRegistration && (
                            <Alert
                                color="green"
                                className="mt-4"
                                icon={faCheckCircle}
                            >
                                <span className="text-sm">
                                    Thank you for registering. To complete the
                                    registration process, please sign in below
                                    and enable Multi-Factor Authentication (MFA)
                                    for your account.
                                </span>
                            </Alert>
                        )}

                        {isComingFromInvalidToken && (
                            <Alert
                                color="orange"
                                className="mt-4"
                                icon={faExclamationCircle}
                            >
                                <span className="text-sm">
                                    You may have already registered. Please log in
                                    using the credentials you provided during
                                    the registration process.
                                </span>
                            </Alert>
                        )}
                        <LoginForm
                            setLoggedIn={setLoggedIn}
                            setHasTwoFactor={setHasTwoFactor}
                            somethingWentWrong={somethingWentWrong}
                            setHasForgotPassword={setHasForgotPassword}
                        />
                    </div>
                ) : hasTwoFactor ? (
                    <VerifyOtp
                        setLoggedIn={setLoggedIn}
                        setToken={setToken}
                        needsToRedirect={needsToRedirect}
                        setSomethingWentWrong={setSomethingWentWrong}
                    />
                ) : (
                    <TwoFactorSetup
                        setLoggedIn={setLoggedIn}
                        setToken={setToken}
                    />
                )}
            </div>
        </div>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
};

export default Login;
