import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Selectbox from "../../components/Form/Selectbox";
import Breadcrumbs from "../../components/Breadcrumbs";
import Input from "../../components/Form/Input";
import Textarea from "../../components/Form/Textarea";
import Badge from "../../components/Badge/Badge";
import NumberInput from "../../components/Form/NumberInput";
import Button from "../../components/Button/Button";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router";
import moment from "moment";
import CreateCourseValidator from "../../components/Form/Validators/CreateCourseValidator";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import toast from "react-hot-toast";
import { withRouter } from "react-router-dom";
import qs from "qs";
registerLocale("en-gb", enGb);

const formatOptions = (array, valueKey, labelKey, customSort = false) => {
    if (customSort) {
        return array
            .sort(function (x, y) {
                console.log(x);
                return x.custom - y.custom || x.title.localeCompare(y.title);
            })
            .map((item) => {
                return {
                    value: item[valueKey],
                    label: (
                        <div className="flex">
                            <div className="flex-grow">{item[labelKey]}</div>
                            <div className="flex-shrink-0">
                                {item.custom && (
                                    <Badge color="teal" text={"Custom"} />
                                )}
                            </div>
                        </div>
                    ),
                };
            });
    }

    return array.map((item) => {
        return {
            value: item[valueKey],
            label: item[labelKey],
        };
    });
};

const submitForm = (params, setRedirect, props, originalCourseInfo) => {
    let originalInstructors = originalCourseInfo.course_instructors;
    /**
     * Format the instructors into a single object
     */
    let instructors = [];
    // Loop the primary instructors array
    params.instructor.forEach((item) => {
        let tempInstructor = originalInstructors.find(
            (i) => i.user_id === item.value
        );

        if (tempInstructor) {
            instructors.push({
                id: tempInstructor.id,
                user_id: item.value,
                primary: true,
            });
        } else {
            instructors.push({
                user_id: item.value,
                primary: true,
            });
        }
    });

    // Loop the secondary instructors array
    if (params.secondaryInstructor) {
        params.secondaryInstructor.forEach((item) => {
            let tempInstructor = originalInstructors.find(
                (i) => i.user_id === item.value
            );
            if (tempInstructor) {
                instructors.push({
                    id: tempInstructor.id,
                    user_id: item.value,
                    primary: false,
                });
            } else {
                instructors.push({
                    user_id: item.value,
                    primary: false,
                });
            }
        });
    }

    /**
     * Check if any instructors were removed
     */
    let newIds = instructors.map((item) => {
        return item.user_id;
    });
    let oldIds = originalCourseInfo.course_instructors.map((item) => {
        return {
            user_id: item.user_id,
            id: item.id,
        };
    });
    let removedIds = oldIds.filter((x) => !newIds.includes(x.user_id));

    if (removedIds.length) {
        removedIds.forEach((item) => {
            instructors.push({
                id: item.id,
                _destroy: true,
            });
        });
    }

    /**
     * Create a formatted parameter object from the data
     * submitted from the form
     */
    let paramObject = {
        course: {
            curriculum_id: params.curriculum,
            startDate: moment(params.startDate).format("YYYY-MM-DD"),
            shippingAddress: params.shippingAddress,
            trainingAddress: params.trainingAddress,
            maxNumStudents: params.maximumStudents,
            customer_name: params.customerName,
            course_type_id: params.courseType,
            delivery_method_id: params.deliveryMethod,
            timeZone: params.timezone,
            region: params.region,
            course_instructors_attributes: instructors,
        },
    };

    let createCourse = axios
        .put(
            `${process.env.REACT_APP_API}/courses/${props.match.params.id}.json`,
            paramObject
        )
        .then(({ data }) => {
            setRedirect(true);
        })
        .catch((error) => console.error(error));

    toast.promise(createCourse, {
        loading: "Submitting...",
        success: "Successfully edited course!",
        error: "Failed editing course",
    });
};

function CourseEdit(props) {
    const [redirect, setRedirect] = useState(false);
    const [courseInfo, setCourseInfo] = useState(null);
    const [individualCourseInfo, setIndividualCourseInfo] = useState(null);
    const [selectedCurriculum, setSelectedCurriculum] = useState(null);
    const [selectedCourseType, setSelectedCourseType] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(null);
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    const [selectedSecondaryInstructor, setSelectedSecondaryInstructor] =
        useState(null);
    const [maximumStudents, setMaximumStudents] = useState(1);
    const [startDate, setStartDate] = useState(new Date());
    const [customerName, setCustomerName] = useState("");
    const [shippingAddress, setShippingAddress] = useState("");
    const [trainingAddress, setTrainingAddress] = useState("");

    const formatFormParams = () => {
        console.log(maximumStudents);
        return {
            curriculum: selectedCurriculum?.value,
            courseType: selectedCourseType?.value,
            region: selectedRegion?.value,
            timezone: selectedTimezone?.value,
            deliveryMethod: selectedDeliveryMethod?.value,
            instructor: selectedInstructor,
            secondaryInstructor: selectedSecondaryInstructor,
            maximumStudents,
            startDate,
            customerName,
            shippingAddress,
            trainingAddress,
        };
    };

    const getCourseInfo = () => {
        axios
            .get(`${process.env.REACT_APP_API}/courses/new.json`)
            .then(({ data }) => {
                setCourseInfo(data);
            })
            .catch((error) => console.error(error));
    };

    const getIndividualCourseInfo = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/courses/${props.match.params.id}.json`
            )
            .then(({ data }) => {
                /**
                 * Start setting the default values for everything (apart from instructor)
                 * at this point, instructor needs some extra work.
                 * */
                setIndividualCourseInfo(data);
                setSelectedCurriculum({
                    value: data.curriculum.id,
                    label: data.curriculum.title,
                });
                setSelectedCourseType({
                    value: data.course.course_type_id,
                    label: data.course.course_type_name,
                });
                setSelectedRegion({
                    value: data.course.region,
                    label: data.course.region,
                });
                setSelectedTimezone({
                    value: data.course.timeZone,
                    label: data.course.timeZone,
                });
                setSelectedDeliveryMethod({
                    value: data.course.delivery_method_id,
                    label: data.course.delivery_method_name,
                });
                setMaximumStudents(data.course.maxNumStudents);
                setStartDate(moment(data.course.startDate).toDate());
                setCustomerName(data.course.customer_name);
                setShippingAddress(data.course.shippingAddress);
                setTrainingAddress(data.course.trainingAddress);

                /**
                 * Parse out the primary and secondary instructors and
                 * place their values in a formatted array
                 */
                let allInstructors = data.course_instructors;
                /**
                 * Get the primarys
                 */
                let parsedPrimaries = allInstructors
                    .filter((instructor) => instructor.primary === true)
                    .map((primary) => {
                        return {
                            label: primary.name,
                            value: primary.user_id,
                        };
                    });

                setSelectedInstructor(parsedPrimaries);

                let parsedSecondaries = allInstructors
                    .filter((instructor) => instructor.primary === false)
                    .map((secondary) => {
                        return {
                            label: secondary.name,
                            value: secondary.user_id,
                        };
                    });

                setSelectedSecondaryInstructor(parsedSecondaries);
            })
            .catch((error) => console.error(error));
    };

    /**
     * Get the curriculums as soon as the component mounts
     */
    useEffect(() => {
        getCourseInfo();
        getIndividualCourseInfo();
    }, []);

    useEffect(() => {
        console.log(maximumStudents);
    }, [maximumStudents]);

    // Get the query string params (if the user has been redirected)
    const queryString = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
    });

    if (redirect) {
        return (
            <Redirect
                to={
                    queryString.hasOwnProperty("redirectTo")
                        ? queryString.redirectTo
                        : `/courses`
                }
            />
        );
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>Edit | Courses</title>
            </Helmet>

            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Course Management",
                        to: "/courses",
                    },
                    {
                        name: "Edit",
                        to: `/courses`,
                    },
                ]}
            />

            <Container>
                <h1 className="font-extrabold text-2xl mb-4">Edit Course</h1>
                <Panel>
                    {courseInfo && individualCourseInfo ? (
                        <div>
                            <div className="grid grid-cols-4 gap-4">
                                <div>
                                    <h2 className="text-lg font-semibold">
                                        Course Information
                                    </h2>
                                    <p className="text-xs text-gray-600">
                                        Select the required information for this
                                        course.
                                    </p>
                                </div>
                                <div className="col-span-3">
                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Curriculum
                                        </p>
                                        <Selectbox
                                            value={selectedCurriculum}
                                            onChange={setSelectedCurriculum}
                                            options={formatOptions(
                                                courseInfo.curriculums,
                                                "id",
                                                "title",
                                                true
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Course Type
                                        </p>
                                        <Selectbox
                                            onChange={setSelectedCourseType}
                                            value={selectedCourseType}
                                            options={formatOptions(
                                                courseInfo.coursetypes,
                                                "id",
                                                "name"
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Region
                                        </p>
                                        <Selectbox
                                            onChange={setSelectedRegion}
                                            value={selectedRegion}
                                            options={formatOptions(
                                                courseInfo.regions,
                                                "region",
                                                "region"
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Timezone
                                        </p>
                                        <Selectbox
                                            onChange={setSelectedTimezone}
                                            value={selectedTimezone}
                                            options={moment.tz.names().map((tz, index) => {return {value: tz, label: tz}})}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Delivery Method
                                        </p>
                                        <Selectbox
                                            onChange={setSelectedDeliveryMethod}
                                            value={selectedDeliveryMethod}
                                            options={formatOptions(
                                                courseInfo.deliverymethods,
                                                "id",
                                                "name"
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Primary Instructor(s)
                                        </p>
                                        <Selectbox
                                            isMulti
                                            onChange={setSelectedInstructor}
                                            value={selectedInstructor}
                                            options={formatOptions(
                                                courseInfo.instructors,
                                                "user_id",
                                                "name"
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Secondary Instructor(s)
                                        </p>
                                        <Selectbox
                                            isMulti
                                            onChange={
                                                setSelectedSecondaryInstructor
                                            }
                                            value={selectedSecondaryInstructor}
                                            options={formatOptions(
                                                courseInfo.instructors,
                                                "user_id",
                                                "name"
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Maximum number of students
                                        </p>
                                        <NumberInput
                                            min={1}
                                            max={20}
                                            step={1}
                                            value={maximumStudents}
                                            onChange={setMaximumStudents}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Start Date
                                        </p>
                                        <ReactDatePicker
                                            selected={startDate}
                                            onChange={(date) =>
                                                setStartDate(date)
                                            }
                                            minDate={new Date() }
                                            dateFormat={"yyyy-MM-dd"}
                                            locale={"en-gb"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Loading...</div>
                    )}
                </Panel>

                <Panel>
                    {courseInfo && individualCourseInfo ? (
                        <div>
                            <div className="grid grid-cols-4 gap-4">
                                <div>
                                    <h2 className="text-lg font-semibold">
                                        Customer Information
                                    </h2>
                                    <p className="text-xs text-gray-600">
                                        Enter the required information for the
                                        customer undertaking this course.
                                    </p>
                                </div>
                                <div className="col-span-3">
                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Customer Name
                                        </p>
                                        <Input
                                            ariaLabel="Customer Name"
                                            name="customerName"
                                            id="customerName"
                                            placeholder="Enter a customer name..."
                                            required
                                            autoComplete="off"
                                            onChange={setCustomerName}
                                            value={customerName}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Shipping Address
                                        </p>
                                        <Textarea
                                            ariaLabel="Shipping Address"
                                            name="shippingAddress"
                                            id="shippingAddress"
                                            placeholder="Enter the customers shipping address..."
                                            onChange={setShippingAddress}
                                            value={shippingAddress}
                                            description="The address where any physical training materials will be shipped."
                                            rows={5}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <p className="font-semibold text-sm text-gray-600 mb-1">
                                            Training Address
                                        </p>
                                        <Textarea
                                            ariaLabel="Training Address"
                                            name="trainingAddress"
                                            id="trainingAddress"
                                            placeholder="Enter the training address or virtual connection details..."
                                            onChange={setTrainingAddress}
                                            value={trainingAddress}
                                            description="The physical address (or virtual) where the training will take place. Please enter virtual connection details here if training is not being provided in person."
                                            rows={5}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Loading...</div>
                    )}
                </Panel>

                <div className="flex flex-row justify-between">
                    <div>
                        <Button
                            size="md"
                            color="gray"
                            onClick={() => setRedirect(true)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            size="md"
                            color="teal"
                            onClick={() =>
                                CreateCourseValidator(formatFormParams()) &&
                                submitForm(
                                    formatFormParams(),
                                    setRedirect,
                                    props,
                                    individualCourseInfo
                                )
                            }
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faEdit}
                                className="mr-2"
                            />
                            Save
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default withRouter(CourseEdit);
