import React from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterDomLink } from "react-router-dom";

export default function Link(props) {
    return (
        <ReactRouterDomLink
            to={props.to}
            className="text-teal-500 hover:text-teal-700"
        >
            {props.children}
        </ReactRouterDomLink>
    );
}

Link.defaultProps = {
    to: "/",
};

Link.propTypes = {
    to: PropTypes.string.isRequired,
};
