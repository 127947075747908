import {
    faCalendarCheck,
    faCertificate,
    faSync,
    faTimes,
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import RosterModal from "../../Pages/Course/RosterModal";
import StudentCertificateDownload from "../Users/StudentCertificateDownload";

function EnroledActions(props) {
    const [resetConfirmModalOpen, setResetConfirmModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [rosterModalOpen, setRosterModalOpen] = useState(false);

    /**
     * Render the confirm button inside the modal footer
     */
    const getConfirmButton = () => (
        <Button color="green" onClick={() => removeUser()}>
            Confirm
        </Button>
    );

    /**
     * Remove the user via a DELETE request from axios, and then reload
     * the data to refresh the table content
     */
    const removeUser = () => {
        let removePromise = axios
            .delete(
                `${process.env.REACT_APP_API}/students/${props.data.id}.json`
            )
            .then(({ data }) => {
                props.getCourse();
            });

        toast.promise(removePromise, {
            loading: "Removing user...",
            success: "Removed user",
            error: "Failed removing user",
        });
    };

    /**
     * Render the confirm button inside the modal footer
     */
    const getResetConfirmButton = () => (
        <Button color="green" onClick={() => resetOtp()}>
            Confirm
        </Button>
    );

    var certinfo = [];
    certinfo = ({
           student_id : props.data.id,
           startDate  : props.course.course.startDate,
           duration   : props.course.curriculum.duration
        })

    /**
     * Reset the users OTP
     */

    const resetOtp = () => {
        let resetPromise = axios
            .get(
                `${process.env.REACT_APP_API}/user/resetOTP.json?uid=${props.data.uid}`
            )
            .then(() => props.getCourse())
            .then(() => setResetConfirmModalOpen(false));

        toast.promise(resetPromise, {
            loading: "Resetting...",
            success: "Reset OTP",
            error: "Failed resetting OTP",
        });
    };


    return (
        <div>
            <div className="flex flex-row space-x-2">
                <Tippy content="Remove">
                    <div>
                        <Button
                            color="red"
                            onClick={() =>
                                setConfirmModalOpen(!confirmModalOpen)
                            }
                        >
                            <FontAwesomeIcon fixedWidth icon={faTimes} />
                        </Button>
                    </div>
                </Tippy>
                <Tippy content="Reset OTP">
                    <div>
                        <Button
                            color="yellow"
                            disabled={!props.data.otpenabled}
                            onClick={() => setResetConfirmModalOpen(true)}
                        >
                            <FontAwesomeIcon fixedWidth icon={faSync} />
                        </Button>
                    </div>
                </Tippy>
                <StudentCertificateDownload data={certinfo} token={props.token}/>

                <Tippy content={"Roster"}>
                    <div>
                        <Button
                            color="cyan"
                            onClick={() => setRosterModalOpen(true)}
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faCalendarCheck}
                            />
                        </Button>
                    </div>
                </Tippy>
            </div>
            <Modal
                isOpen={confirmModalOpen}
                setIsOpen={setConfirmModalOpen}
                closeWording={"Cancel"}
                headerContent={"Confirm"}
                footerContent={getConfirmButton()}
            >
                Are you sure you want to remove "{props.data.email}" from the
                course?
            </Modal>

            <Modal
                isOpen={resetConfirmModalOpen}
                setIsOpen={setResetConfirmModalOpen}
                closeWording={"Cancel"}
                headerContent={"Are you sure?"}
                footerContent={getResetConfirmButton()}
            >
                This action will remove the OTP tokens stored for this user and
                force them to enrol again. Are you sure you want to continue?
            </Modal>

            <RosterModal
                rosterModalOpen={rosterModalOpen}
                setRosterModalOpen={setRosterModalOpen}
                user={props.data}
                course={props.course}
                getCourse={props.getCourse}
            />
        </div>
    );
}

export default EnroledActions;
