import React from "react";
import Panel from "../../Panel/Panel";
import findLogoImage from "../../../util/findLogoImage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const renderTile = (tile, isInternal) => (
    <Panel
        noMargin
        className="hover:bg-gray-100 border border-white hover:border-gray-300 group"
    >
        <div className="relative">
            {!isInternal && (
                <div className="absolute top-0 right-0">
                    <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" className="text-gray-500 group-hover:text-gray-800" />
                </div>
            )}
            <div className="flex">
                <div className="flex-shrink-0 my-auto">
                    <img
                        src={`/images/tiles/${findLogoImage(tile.logo)}`}
                        alt={tile.name}
                        className="h-10 w-10"
                    />
                </div>
                <div className="flex-grow pl-4">
                    <p className="font-semibold text-gray-700 mb-1">
                        {tile.name}
                    </p>
                    <p className="text-gray-600 mb-2">{tile.subtitle}</p>
                </div>
            </div>
        </div>
    </Panel>
);

function Tile({ tile }) {
    /**
     * Determine whether the tile is internal to the site
     * or needs to be externally linked
     */
    const isInternal = tile.link.charAt(0) === "/";

    if (isInternal) {
        return <Link to={tile.link}>{renderTile(tile, isInternal)}</Link>;
    }

    return (
        <a href={`${process.env.REACT_APP_API}/tile_url?id=${tile.id}`} target="_blank">
            {renderTile(tile, isInternal)}
        </a>
    );
}

export default Tile;
