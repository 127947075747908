import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button/Button";
import Tippy from "@tippyjs/react";
import axios from "axios";
import toast from "react-hot-toast";

function LdapGroupMembership({
    staffGroups,
    studentGroups,
    user,
    refreshUser,
}) {
    const [availableGroups, setAvailableGroups] = useState({
        member: [],
        nonMember: [],
        all: [],
    });

    useEffect(() => {
        formatAvailableGroups();
    }, [staffGroups, studentGroups]);

    const formatAvailableGroups = () => {
        let groups = {
            member: [],
            nonMember: [],
            all: [],
        };
        /**
         * Check if the staff groups variable is
         * not falsey
         */
        if (staffGroups) {
            staffGroups.forEach((element) => {
                if (element.member) {
                    groups.member.push(element.group);
                } else {
                    groups.nonMember.push(element.group);
                }

                groups.all.push(element);
            });
        }

        /**
         * Format the student groups
         */
        studentGroups.forEach((element) => {
            if (element.member) {
                groups.member.push(element.group);
            } else {
                groups.nonMember.push(element.group);
            }

            groups.all.push(element);
        });

        setAvailableGroups(groups);
    };

    const addToGroup = (group) => {
        let dn = availableGroups.all.find((item) => item.group === group)?.dn;
        let addToGroupPromise = axios
            .get(
                `${process.env.REACT_APP_API}/users/joingroup.json?userid=${user.username}&groupdn=${dn}`
            )
            .then(({ data }) => refreshUser());

        toast.promise(addToGroupPromise, {
            success: `Added user to ${group}`,
            error: `Failed to add user to ${group}`,
            loading: "Adding...",
        });
    };

    const removeFromGroup = (group) => {
        let dn = availableGroups.all.find((item) => item.group === group)?.dn;
        let removeFromGroupPromise = axios
            .get(
                `${process.env.REACT_APP_API}/users/leavegroup.json?userid=${user.username}&groupdn=${dn}`
            )
            .then(({ data }) => refreshUser());

        toast.promise(removeFromGroupPromise, {
            success: `Removed user from ${group}`,
            error: `Failed to remove user from ${group}`,
            loading: "Removing...",
        });
    };

    return (
        <div className="grid grid-cols-2 grid-rows-1 gap-4">
            <div className="h-56 overflow-y-scroll border border-gray-200 rounded-md">
                {availableGroups?.nonMember.length > 0 ? (
                    <ul className="flex flex-col">
                        {availableGroups?.nonMember?.map((group, index) => (
                            <li key={index}>
                                <div className="flex flex-row items-center hover:bg-gray-100 px-4 py-2">
                                    <div className="flex-grow">{group}</div>
                                    <div className="flex-shrink-0">
                                        <Tippy content="Add to group">
                                            <div>
                                                <Button
                                                    color="green"
                                                    onClick={() =>
                                                        addToGroup(group)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        icon={faPlusCircle}
                                                    />
                                                </Button>
                                            </div>
                                        </Tippy>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="h-full flex items-center justify-center">
                        <h3 className="text-gray-600">
                            No available groups for user to be added too.
                        </h3>
                    </div>
                )}
            </div>
            <div className="h-56 overflow-y-scroll border border-gray-200 rounded-md">
                {availableGroups?.member.length > 0 ? (
                    <ul className="flex flex-col">
                        {availableGroups?.member?.map((group, index) => (
                            <li key={index}>
                                <div className="flex flex-row items-center hover:bg-gray-100 px-4 py-2">
                                    <div className="flex-grow">{group}</div>
                                    <div className="flex-shrink-0">
                                        <Tippy content="Remove from group">
                                            <div>
                                                <Button
                                                    color="red"
                                                    onClick={() =>
                                                        removeFromGroup(group)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        icon={faMinusCircle}
                                                    />
                                                </Button>
                                            </div>
                                        </Tippy>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="h-full flex items-center justify-center">
                        <h3 className="text-gray-600">
                            User is not a member of any groups.
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
}

export default LdapGroupMembership;
