import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import Panel from "../../Panel/Panel";
import axios from "axios";
import Table from "../../Table/Table";
import CloudActions from "../../CellRenderers/Vms/CloudActions";

function CloudAppliancesTable(props) {
    const [cloudList, setCloudList] = useState(null);
    const getCloudList = () => {
        axios
            .get(`${process.env.REACT_APP_API}/clouds.json`)
            .then(({ data }) => setCloudList(data.clouds))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
      getCloudList();
    }, []);

    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Name",
                field: "shortname",
                width: 100
            },
            {
                headerName: "Description",
                field: "description",
            },
            {
                headerName: "Actions",
                field: "url",
                cellRendererFramework: CloudActions,
            },
        ],
    };

    return (
        <Panel>
            <h1 className="text-xl font-bold mb-4">
                <FontAwesomeIcon
                    fixedWidth
                    icon={faServer}
                    className="mr-2 text-gray-600"
                />
                Cloud Services
            </h1>

            <Table gridOptions={gridOptions} rowData={cloudList} />
        </Panel>
    );
}

export default CloudAppliancesTable;
