import React, { useEffect, useState } from "react";
import axios from "axios";
import moment, { duration } from "moment";
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faCogs,
    faPlus,
    faServer,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../../Layout/Container";
import Panel from "../../Panel/Panel";
import Button from "../../Button/Button";
import Table from "../../Table/Table";
import CreateVmModal from "../../Pages/Vms/CreateVmModal";
import StatusIndicator from "../../CellRenderers/Vms/StatusIndicator";
import StudentActions from "../../CellRenderers/Vms/StudentActions";
import TemplateName from "../../CellRenderers/Vms/TemplateName";
import LastBoot from "../../CellRenderers/Vms/LastBoot";
import VmTypeDetails from "../../CellRenderers/Vms/VmTypeDetails";

function LabVMTable(props) {
    const [lastUpdated, setLastUpdated] = useState(moment());
    const [isCreateVmModalOpen, setIsCreateVmModelOpen] = useState(false);
    const [vmsList, setVmsList] = useState(null);
    const getVmsList = () => {
        var this_user = "";
        if (props.token.claims.includes("user-admin") || props.token.claims.includes("guest-instructor")) {
            if (props.userid) {
                this_user = `?userid=${props.userid}`;
            }
        }
        axios
            .get(`${process.env.REACT_APP_API}/vms.json${this_user}`)
            .then(({ data }) => setVmsList(data))
            .then(() => setLastUpdated(moment()))
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getVmsList();
    }, []);

    useEffect(() => {
        const checkTimeSinceLastUpdate = () => {
            let now = moment();
            let duration = moment.duration(now.diff(lastUpdated));

            return parseInt(duration.asSeconds());
        };

        let refreshInterval = setInterval(() => {
            /**
             * Check the time since the vms were last updated
             */
            if (checkTimeSinceLastUpdate() >= 30) {
                getVmsList();
            }
        }, 1000);

        return () => {
            clearInterval(refreshInterval);
        };
    }, [lastUpdated]);

    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Name",
                field: "description",
                cellRendererFramework: TemplateName,
                cellRendererParams: {
                    showModal: true,
                    refreshVms: getVmsList,
                },
            },
            {
                headerName: "Type",
                field: "vm_type",
                sort: "asc",
                width: 250,
                cellRendererFramework: VmTypeDetails,
            },
            {
                headerName: "Status",
                field: "status",
                cellRendererFramework: StatusIndicator,
            },
            {
                headerName: "Last Boot",
                field: "lastboot",
                cellRendererFramework: LastBoot,
            },
            {
                headerName: "IP Address",
                field: "ipaddress",
            },
            {
                headerName: "Actions",
                field: "full_status",
                cellRendererFramework: StudentActions,
                cellRendererParams: {
                    refreshVms: getVmsList,
                },
            },
        ],
    };

    return (
        <>
            <Panel>
                <div className="flex justify-between items-center mb-4">
                    <div>
                        <h1 className="text-xl font-bold">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faServer}
                                className="mr-2 text-gray-600"
                            />
                            Virtual Systems
                        </h1>
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                        <div className="text-gray-600 text-sm">
                            <Tippy
                                placement="bottom"
                                content="Updates automatically every 30 seconds"
                            >
                                <div>
                                    <FontAwesomeIcon
                                        icon={faClock}
                                        fixedWidth
                                        className="mr-2"
                                    />
                                    Last updated:{" "}
                                    {lastUpdated.format("HH:mm:ss")}
                                </div>
                            </Tippy>
                        </div>
                        <Button
                            color="green"
                            onClick={() => setIsCreateVmModelOpen(true)}
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faPlus}
                                className="mr-2"
                            />
                            Create VM
                        </Button>
                        {props.token.claims.includes("vm-admin") && (
                            <Button color="orange" isLink to="/vms/admin">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faCogs}
                                    className="mr-2"
                                />
                                Lab Admin
                            </Button>
                        )}
                        {props.token.claims.includes("user-admin") && (
                            <Button color="orange" isLink to="/vms/listallvms">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faCogs}
                                    className="mr-2"
                                />
                                All user VMs
                            </Button>
                        )}
                    </div>
                </div>

                <Table gridOptions={gridOptions} rowData={vmsList} />
            </Panel>

    <CreateVmModal
      isCreateVmModalOpen={isCreateVmModalOpen}
      setIsCreateVmModalOpen={setIsCreateVmModelOpen}
      refreshVms={getVmsList}
      user_id={props.userid}
      token={props.token}
    />
  </>
  );
}

export default LabVMTable;
