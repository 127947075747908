import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Button/Button";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";
import Modal from "../../Modal/Modal";

function NormalUsersActions(props) {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    /**
     * Render the confirm button inside the modal footer
     */
    const getConfirmButton = () => (
        <Button color="green" onClick={() => resetOtp()}>
            Confirm
        </Button>
    );

    const resetOtp = () => {
        let resetPromise = axios
            .get(
                `${process.env.REACT_APP_API}/user/resetOTP.json?uid=${props.data.uid}`
            )
            .then(() => props.getUsers())
            .then(() => setConfirmModalOpen(false));

        toast.promise(resetPromise, {
            loading: "Resetting...",
            success: "Reset OTP",
            error: "Failed resetting OTP",
        });
    };

    return (
        <div>
            <Tippy placement="top" content="Reset OTP">
                <div>
                    <Button
                        color="yellow"
                        onClick={() => setConfirmModalOpen(true)}
                        disabled={!props.data.otpenabled}
                    >
                        <FontAwesomeIcon icon={faSync} />
                    </Button>
                </div>
            </Tippy>
            <Modal
                isOpen={confirmModalOpen}
                setIsOpen={setConfirmModalOpen}
                closeWording={"Cancel"}
                headerContent={"Are you sure?"}
                footerContent={getConfirmButton()}
            >
                This action will remove the OTP tokens stored for this user and
                force them to enrol again. Are you sure you want to continue?
            </Modal>
        </div>
    );
}

export default NormalUsersActions;
