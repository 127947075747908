import React from "react";
import axios from "axios";
import { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useEffect } from "react/cjs/react.development";
import Toggle from "../../Toggle/Toggle";

class RosterToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEnabled: this.props.value,
        };
    }

    toggleAction() {
        let togglePromise = axios.put(
            `${process.env.REACT_APP_API}/rosters/${this.props.data.roster_id}.json`,
            {
                [this.props.field]: this.state.isEnabled,
            }
        );

        toast.promise(togglePromise, {
            loading: "Toggling...",
            success: `Toggled ${this.props.field.toUpperCase()} value`,
            error: `Failed to toggle ${this.props.field.toUpperCase()} value`,
        });
    }

    render() {
        return (
            <Toggle
                isEnabled={this.state.isEnabled}
                onClick={() =>
                    this.setState({ isEnabled: !this.state.isEnabled }, () =>
                        this.toggleAction()
                    )
                }
            />
        );
    }
}

export default RosterToggle;
