function findLogoImage(logo) {
    const overrideMapping = {
        "Graylog.svg": "graylog.png",
        "FELogo.png": "fireeye-logomark.png",
        "PTNG.jpg": "staging.png",
        "BooksLaptop.jpg": "course-resources.png",
        "curriculums.jpg": "curriculum.png",
        "courseManagement.jpg": "course_management.png",
        "replay.png": 'replay.png',
        "xpand.png": 'X.png',
    };

    return overrideMapping[logo] || logo;
}

export default findLogoImage;
