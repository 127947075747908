import React from "react";

function Panel({ noMargin, header, children, footer, className, ...props }) {
    return (
        <div
            className={`bg-white rounded-md p-4 shadow ${
                !noMargin ? "mb-4" : ""
            } ${className}`}
            {...props}
        >
            {header && (
                <div className="pb-4 mb-4 border-b border-gray-200">
                    {header}
                </div>
            )}
            {children}
            {footer && (
                <div className="pt-4 mt-4 border-t border-gray-200">
                    {footer}
                </div>
            )}
        </div>
    );
}

Panel.defaultProps = {
    noMargin: false,
    className: "",
};

export default Panel;
