import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import Toggle from "../../Toggle/Toggle";

function CurriculumEnabled(props) {
    const [isLoading, setIsLoading] = useState(false);

    const toggle = (id) => {
        setIsLoading(true);
        const togglePromise = axios
            .get(
                `${process.env.REACT_APP_API}/curriculumsEnableToggle.json?curriculum_id=${id}`
            )
            .then(({ data }) => {
                props.reload();
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });

        toast.promise(togglePromise, {
            loading: "Toggling...",
            success: "Successfully toggled curriculum!",
            error: "Failed toggling curriculum",
        });
    };

    return (
        <Toggle
            isEnabled={props.value}
            onClick={() => toggle(props.data.id)}
            isLoading={isLoading}
            disabled={props.token.claims.includes("guest-instructor")}
        />
    );
}

export default CurriculumEnabled;
