import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import LabVMTable from "../../components/Pages/Vms/LabVMTable";
import LabAppliancesTable from "../../components/Pages/Vms/LabAppliancesTable";
import CloudAppliancesTable from "../../components/Pages/Vms/CloudAppliancesTable";

function Vms(props) {
    return (
        <div className="w-full">
            <Helmet>
                <title>VM Lab</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "VM Lab",
                        to: "/vms",
                    },
                ]}
            />
            <Container>
                <LabVMTable token={props.token} />
                <LabAppliancesTable />
                <CloudAppliancesTable />
            </Container>
        </div>
    );
}

export default Vms;
