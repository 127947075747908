import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronCircleDown,
    faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

function TimingRow({ id, dayNumber, startTime, endTime }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [internalStartTime, setInternalStartTime] = useState(
        moment(startTime, "HH:mm").toDate()
    );
    const [internalEndTime, setInternalEndTime] = useState(
        moment(endTime, "HH:mm").toDate()
    );

    const updateStartTime = (id, time, dayNumber) => {
        let momentStart = moment(time);
        let momentEnd = moment(internalEndTime);

        if (momentStart.isBefore(momentEnd)) {
            let startTimeRequest = axios
                .put(`${process.env.REACT_APP_API}/timings/${id}.json`, {
                    startTime: moment(time).format("HH:mm"),
                })
                .then(({ data }) => setInternalStartTime(time));

            toast.promise(startTimeRequest, {
                success: `Updated start time for day ${dayNumber}`,
                error: "Failed to update start time",
                loading: "Updating...",
            });
        } else {
            toast.error("Start time cannot be after end time");
        }
    };

    const updateEndTime = (id, time, dayNumber) => {
        let momentStart = moment(internalStartTime);
        let momentEnd = moment(time);

        if (momentEnd.isAfter(momentStart)) {
            let startTimeRequest = axios
                .put(`${process.env.REACT_APP_API}/timings/${id}.json`, {
                    endTime: moment(time).format("HH:mm"),
                })
                .then(({ data }) => setInternalEndTime(time));

            toast.promise(startTimeRequest, {
                success: `Updated end time for day ${dayNumber}`,
                error: "Failed to update end time",
                loading: "Updating...",
            });
        } else {
            toast.error("End time cannot be before start time");
        }
    };

    return (
        <div className="cursor-pointer rounded-md p-4 bg-gray-100 hover:bg-gray-200 border border-gray-300 mb-4">
            <div
                className="-m-4 p-4 select-none"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="flex flex-row space-x-4">
                    <div>
                        <span className="font-semibold">Day:</span> {dayNumber}
                    </div>
                    <div>
                        <span className="font-semibold">Start:</span>{" "}
                        {moment(internalStartTime).format("HH:mm")}
                    </div>
                    <div>
                        <span className="font-semibold">End:</span>{" "}
                        {moment(internalEndTime).format("HH:mm")}
                    </div>
                    <div className="flex-grow text-right">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={
                                isExpanded
                                    ? faChevronCircleDown
                                    : faChevronCircleRight
                            }
                            className="text-teal-500"
                        />
                    </div>
                </div>
            </div>
            {isExpanded && (
                <div className="grid grid-cols-2 gap-4 mt-4">
                    <div>
                        Start{" "}
                        <ReactDatePicker
                            selected={internalStartTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onChange={(date) => {
                                updateStartTime(id, date, dayNumber);
                            }}
                        />
                    </div>
                    <div>
                        End{" "}
                        <ReactDatePicker
                            selected={internalEndTime}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={30}
                            timeCaption="Time"
                            timeFormat="HH:mm"
                            dateFormat="HH:mm"
                            onChange={(date) => {
                                updateEndTime(id, date, dayNumber);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default TimingRow;
