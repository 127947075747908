import {
    faPlay,
    faTrash,
    faCircleNotch,
    faStop,
    faCode,
    faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

function StudentActions(props) {
    const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState(false);
    const [confirmStopModalOpen, setConfirmStopModalOpen] = useState(false);
    const [isStarting, setIsStarting] = useState(false);
    const [isStopping, setIsStopping] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteVm = () => {
        setIsDeleting(true);

        let deletionPromise = axios
            .delete(`${process.env.REACT_APP_API}/vms/${props.data.id}.json`)
            .then(() => props.refreshVms())
            .then(() => setIsDeleting(false))
            .then(() => setConfirmDeletionModalOpen(false));

        toast.promise(deletionPromise, {
            loading: "Deleting...",
            success: "Deleted VM",
            error: "Failed to delete VM",
        });
    };

    const startVm = () => {
        setIsStarting(true);

        let startPromise = axios
            .get(
                `${process.env.REACT_APP_API}/vm/start.json?id=${props.data.id}`
            )
            .then(() => setIsStarting(false))
            .then(() => props.refreshVms())
            .catch((error) => console.error(error));

        toast.promise(startPromise, {
            loading: "Starting VM...",
            success: "Started VM",
            error: "Failed to start VM",
        });
    };

    const stopVm = () => {
        setIsStopping(true);

        let stopPromise = axios
            .get(
                `${process.env.REACT_APP_API}/vm/stop.json?id=${props.data.id}`
            )
            .then(() => setIsStopping(false))
            .then(() => setConfirmStopModalOpen(false))
            .then(() => props.refreshVms())
            .catch((error) => console.error(error));

        toast.promise(stopPromise, {
            loading: "Stopping VM...",
            success: "Stopped VM",
            error: "Failed to stop VM",
        });
    };

    return (
        <div>
            <div className="flex space-x-2">
                {props.data.status == "running" ? (
                    <div className="flex space-x-2">
                        <div className="flex">
                            {props.data.console && (
                                <Tippy content="Console">
                                    <a
                                        target="_blank"
                                        href={`${process.env.REACT_APP_API}/vm/console?id=${props.data.id}`}
                                    >
                                        <Button
                                            color="teal"
                                            noRoundingRight={
                                                props.data.web &&
                                                props.data.ipaddress
                                            }
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faCode}
                                            />
                                        </Button>
                                    </a>
                                </Tippy>
                            )}
                            {props.data.web && props.data.ipaddress && (
                                <Tippy content="Web UI">
                                    <a
                                        target="_blank"
                                        href={`${process.env.REACT_APP_API}/vm/https?id=${props.data.id}`}
                                    >
                                        <Button
                                            color="teal"
                                            noRoundingLeft={props.data.console}
                                        >
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faGlobe}
                                            />
                                        </Button>
                                    </a>
                                </Tippy>
                            )}
                        </div>
                        <Tippy content="Stop VM">
                            <div>
                                <Button
                                    disabled={isStopping}
                                    color="orange"
                                    onClick={() =>
                                        setConfirmStopModalOpen(true)
                                    }
                                >
                                    <FontAwesomeIcon
                                        spin={isStopping}
                                        fixedWidth
                                        icon={
                                            isStopping ? faCircleNotch : faStop
                                        }
                                    />
                                </Button>
                            </div>
                        </Tippy>
                    </div>
                ) : (
                    <Tippy content="Start VM">
                        <div>
                            <Button
                                disabled={isStarting}
                                color="green"
                                onClick={() => startVm()}
                            >
                                <FontAwesomeIcon
                                    spin={isStarting}
                                    fixedWidth
                                    icon={isStarting ? faCircleNotch : faPlay}
                                />
                            </Button>
                        </div>
                    </Tippy>
                )}
                <Tippy
                    content={
                        props.data.status === "running"
                            ? "Unable to delete running VM"
                            : "Delete VM"
                    }
                >
                    <div>
                        <Button
                            disabled={props.data.status === "running"}
                            color="red"
                            onClick={() => setConfirmDeletionModalOpen(true)}
                        >
                            <FontAwesomeIcon fixedWidth icon={faTrash} />
                        </Button>
                    </div>
                </Tippy>
            </div>
            <Modal
                isOpen={confirmDeletionModalOpen}
                setIsOpen={setConfirmDeletionModalOpen}
                headerContent={`Delete "${props.data.description}" VM?`}
                footerContent={
                    <Button
                        disabled={isDeleting}
                        color="green"
                        onClick={() => deleteVm()}
                    >
                        {isDeleting ? (
                            <FontAwesomeIcon spin icon={faCircleNotch} />
                        ) : (
                            "Confirm Deletion"
                        )}
                    </Button>
                }
            >
                Are you sure you want to delete this VM?
                <br />
                <br />
                <b>This action cannot be undone.</b>
            </Modal>
            <Modal
                isOpen={confirmStopModalOpen}
                setIsOpen={setConfirmStopModalOpen}
                headerContent={`Stop "${props.data.description}" VM?`}
                footerContent={
                    <Button
                        disabled={isStopping}
                        color="orange"
                        onClick={() => stopVm()}
                    >
                        {isStopping ? (
                            <FontAwesomeIcon spin icon={faCircleNotch} />
                        ) : (
                            "Confirm Action"
                        )}
                    </Button>
                }
            >
                <p className="mb-1">Are you sure you want to stop this VM?</p>
                <p className="mb-1">
                    This is a hard stop, therefore, any running applications may
                    lose data.
                </p>
                <p className="font-bold">This action cannot be undone.</p>
            </Modal>
        </div>
    );
}

export default StudentActions;
