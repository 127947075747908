import { faMinus, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

function VmTypeDetails(props) {
    return (
        <div>
          {props.data.vm_type} {props.data.vm_type == "Trellix" ? <> ({props.data.fe_model})</> : <></>}
           
        </div>
    );
}

export default VmTypeDetails;
