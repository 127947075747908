import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "../../Table/Table";
import Username from "../../CellRenderers/Users/Username";
import StaffUsersActions from "../../CellRenderers/Users/StaffUsersActions";

function StaffUsers(props) {
    const [users, setUsers] = useState(null);

    const getUsers = () => {
        axios
            .get(`${process.env.REACT_APP_API}/users.json?type=staff`)
            .then(({ data }) => {
                setUsers(data.users);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getUsers();
    }, []);

    const gridOptions = {
        getRowNodeId(data) {
            return data.uid;
        },
        paginationPageSize: 30,
        columnDefs: [
            {
                headerName: "Name",
                field: "name",
                cellRendererFramework: Username,
            },
            {
                headerName: "UID",
                field: "uid",
            },
            {
                headerName: "Company",
                field: "company",
            },
            {
                headerName: "Email",
                field: "email",
            },
            {
                headerName: "Actions",
                field: "otpenabled",
                cellRendererFramework: StaffUsersActions,
                cellRendererParams: {
                    token: props.token,
                    getUsers: getUsers,
                },
            },
        ],
    };

    return <Table gridOptions={gridOptions} rowData={users} />;
}

export default StaffUsers;
