import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Button from "../../components/Button/Button";
import CurriculumCustom from "../../components/CellRenderers/Curriculums/CurriculumCustom";
import CurriculumEnabled from "../../components/CellRenderers/Curriculums/CurriculumEnabled";
import CurriculumLink from "../../components/CellRenderers/Curriculums/CurriculumLink";
import CurriculumSchedule from "../../components/CellRenderers/Curriculums/CurriculumSchedule";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";

function Curriculums({ token }) {
    const [curriculums, setCurriculums] = useState(null);
    const [showCustom, setShowCustom] = useState("curriculums.json");
    const [hideDisabled, setHideDisabled] = useState(false);

    const getCurriculums = () => {
        axios
            .get(`${process.env.REACT_APP_API}/${showCustom}`)
            .then(({ data }) => {
                setCurriculums(data);
            })
            .catch((error) => console.error(error));
    };

    /**
     * Get the curriculums as soon as the component mounts
     */
    useEffect(() => {
        getCurriculums();
    }, [showCustom]);

    /**
     * Set up the gridOptions for the table
     */
    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Curriculum Name",
                field: "title",
                width: 450,
                cellRendererFramework: CurriculumLink,
                suppressSizeToFit: false,
                sort: "asc",
                sortIndex: 1,
            },
            {
                headerName: "Duration",
                field: "duration",
            },
            {
                headerName: "Custom",
                field: "custom",
                cellRendererFramework: CurriculumCustom,
                cellRendererParams: {
                    reload: getCurriculums,
                    token: token,
                },
                filter: false,
            },
            {
                headerName: "Enabled",
                field: "enabled",
                sort: "desc",
                sortIndex: 0,
                cellRendererFramework: CurriculumEnabled,
                cellRendererParams: {
                    reload: getCurriculums,
                    token: token,
                },
                filter: false,
            },
        {
            headerName: "Guest Instructors",
            field: 'guest_instructor_count'
        },
            {
                headerName: "Schedule",
                cellRendererFramework: CurriculumSchedule,
                width: 100,
            },
        ],
    };

    return (
        <div className="w-full">
            <Helmet>
                <title>Curriculums</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Curriculum Management",
                        to: "/curriculums",
                    },
                ]}
            />
            <Container>
                <Panel>
                    <div className="flex flex-row">
                        <div className="flex-grow">
                            <h1 className="text-xl font-bold mb-4">
                                Curriculums
                            </h1>
                        </div>
                        { console.log("token:", token)}
                        {!token.claims.includes("guest-instructor") && (
                        <div>
                            <Button color="green" isLink to="/curriculums/new">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faPlus}
                                    className="mr-2"
                                />
                                New Curriculum
                            </Button>
                        </div>
                        )}
                    </div>

                    <div className="flex justify-between mb-4">
                        <div className="flex justify-between items-center">
                            <div className="flex">
                                <div
                                    className={`cursor-pointer border py-1 px-2 rounded-l-md select-none ${
                                        showCustom === "curriculums.json"
                                            ? "bg-teal-100 border-teal-300 text-teal-700"
                                            : "bg-gray-100 border-gray-300 text-gray-700"
                                    }`}
                                    onClick={() =>
                                        setShowCustom("curriculums.json")
                                    }
                                >
                                    All
                                </div>
                                <div
                                    className={`cursor-pointer border py-1 px-2 select-none ${
                                        showCustom ===
                                        "curriculums.json?type=public"
                                            ? "bg-teal-100 border-teal-300 text-teal-700"
                                            : "bg-gray-100 border-gray-300 text-gray-700"
                                    }`}
                                    onClick={() =>
                                        setShowCustom(
                                            "curriculums.json?type=public"
                                        )
                                    }
                                >
                                    Public
                                </div>
                                <div
                                    className={`cursor-pointer border py-1 px-2 rounded-r-md select-none ${
                                        showCustom ===
                                        "curriculums.json?type=custom"
                                            ? "bg-teal-100 border-teal-300 text-teal-700"
                                            : "bg-gray-100 border-gray-300 text-gray-700"
                                    }`}
                                    onClick={() =>
                                        setShowCustom(
                                            "curriculums.json?type=custom"
                                        )
                                    }
                                >
                                    Custom
                                </div>
                            </div>
                        </div>
                        <div>
                        {!token.claims.includes("guest-instructor") && (
                            <div
                                className={`cursor-pointer border py-1 px-2 rounded select-none ${
                                    hideDisabled
                                        ? "bg-teal-100 border-teal-300 text-teal-700"
                                        : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                                onClick={() => setHideDisabled(!hideDisabled)}
                            >
                                Hide Disabled
                            </div>
                        )}
                        </div>
                    </div>

                    <Table
                        gridOptions={gridOptions}
                        rowData={
                            hideDisabled
                                ? curriculums.filter((item) => item.enabled)
                                : curriculums
                        }
                    />
                </Panel>
            </Container>
        </div>
    );
}

export default Curriculums;
