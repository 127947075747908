import React, { useState } from "react";
import axios from "axios";
import Alert from "../../Alert/Alert";
import {
    faExclamationTriangle,
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const validateForm = (username, password) => {
    if (
        username === "" ||
        username === undefined ||
        password === "" ||
        password === undefined
    ) {
        return false;
    }

    return true;
};

const submitForm = (
    username,
    password,
    setHasTwoFactor,
    setLoggedIn,
    setInvalidCredentials,
    setShowErrorPanel
) => {
    axios
        .post(`${process.env.REACT_APP_API}/sessions.json`, {
            username: username,
            password: password,
        })
        .then(({ data }) => {
            if (data.status.includes("invalid")) setInvalidCredentials(true);
            if (data.status.includes("Logged in")) {
                setHasTwoFactor(data.info.otp);
                setLoggedIn(true);
            }
        })
        .catch((error) => {
            setShowErrorPanel(true);
            setInvalidCredentials(true);
        });
};

function LoginForm({
    setHasTwoFactor,
    setLoggedIn,
    somethingWentWrong,
    setHasForgotPassword,
}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showErrorPanel, setShowErrorPanel] = useState(false);
    const [invalidCredentials, setInvalidredentials] = useState(false);

    return (
        <form
            className="mt-5"
            onSubmit={(e) => {
                e.preventDefault();
                validateForm(username, password) &&
                    submitForm(
                        username,
                        password,
                        setHasTwoFactor,
                        setLoggedIn,
                        setInvalidredentials,
                        setShowErrorPanel
                    );
            }}
        >
            <div className="rounded-md shadow-sm">
                <div>
                    <input
                        aria-label="Username"
                        name="username"
                        type="text"
                        required=""
                        className="border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-4 py-3 border text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                    />
                </div>
                <div className="-mt-px relative">
                    <input
                        aria-label="Password"
                        name="password"
                        type="password"
                        required=""
                        className="border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-4 py-3 border text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                </div>
            </div>
            {showErrorPanel ||
                (somethingWentWrong && (
                    <div className="mt-5">
                        <Alert icon={faExclamationTriangle}>
                            <div className="sm:text-sm">
                                Something went wrong, please re-authenticate and
                                try again.
                            </div>
                        </Alert>
                    </div>
                ))}
            {invalidCredentials && (
                <div className="mt-5">
                    <Alert>
                        <div className="text-center sm:text-sm">
                            Invalid credentials, please try again.
                        </div>
                    </Alert>
                </div>
            )}
            <div className="mt-5">
                <Button block size="md" type="submit" color="gray">
                    <div className="sm:text-sm">
                        <span className="absolute left-0 inset-y pl-3">
                            <FontAwesomeIcon
                                icon={faLock}
                                className="text-gray-600"
                            />
                        </span>
                        Log in
                    </div>
                </Button>
            </div>

            <div className="mt-7">
                <div
                    className="font-light underline cursor-pointer text-center text-sm"
                    onClick={() => setHasForgotPassword(true)}
                >
                    Forgot password?
                </div>
            </div>
        </form>
    );
}

export default LoginForm;
