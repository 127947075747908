import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button/Button";
import Tippy from "@tippyjs/react";
import axios from "axios";
import toast from "react-hot-toast";

function StudentRoles({ studentGroups, studentRoles, curriculum_id }) {
  const [availableGroups, setAvailableGroups] = useState({
    member: [],
    nonMember: [],
    all: [],
  });

  useEffect(() => {
    formatAvailableGroups();
  }, [studentGroups]);

  const formatAvailableGroups = () => {
    let groups = {
      member: [],
      nonMember: [],
      all: [],
    };
    /**
     * Format the student groups
     */
    studentGroups.forEach((element) => {
      if (studentRoles.filter(role => role.role == element.group).length > 0) {
        groups.member.push(element.group);
      } else {
        groups.nonMember.push(element.group);
      }

      groups.all.push(element);
    });

    setAvailableGroups(groups);
  };

  const addToGroup = (group) => {
    let addToGroupPromise = axios
      .get(
        `${process.env.REACT_APP_API}/curriculum/${curriculum_id}/role/add.json?role=${group}`
      )
      .then(({ data }) => {
        let tmpgrp = {
          member: [],
          nonMember: [],
          all: [],
        }
        tmpgrp.nonMember = availableGroups.nonMember.filter(m => m !== group)
        tmpgrp.all = availableGroups.all
        tmpgrp.member = availableGroups.member
        tmpgrp.member.push(group)
        setAvailableGroups(tmpgrp)
      });

    toast.promise(addToGroupPromise, {
      success: `Added to ${group} to Curriculum`,
      error: `Failed to ${group} to Curriculum`,
      loading: "Adding...",
    });
  };

  const removeFromGroup = (group) => {
    let removeFromGroupPromise = axios
      .get(
        `${process.env.REACT_APP_API}/curriculum/${curriculum_id}/role/del.json?role=${group}`
      )
      .then(({ data }) => {
        let tmpgrp = {
          member: [],
          nonMember: [],
          all: [],
        }
        tmpgrp.member = availableGroups.member.filter(m => m !== group)
        tmpgrp.all = availableGroups.all
        tmpgrp.nonMember = availableGroups.nonMember
        tmpgrp.nonMember.push(group)
        setAvailableGroups(tmpgrp)
      }
      );

    toast.promise(removeFromGroupPromise, {
      success: `Removed user from ${group}`,
      error: `Failed to remove user from ${group}`,
      loading: "Removing...",
    });
  };

  return (
    <div className="grid grid-cols-2 grid-rows-1 gap-4">
      <div className="h-56 overflow-y-scroll border border-gray-200 rounded-md">
        {availableGroups?.nonMember.length > 0 ? (
          <ul className="flex flex-col">
            {availableGroups?.nonMember?.map((group, index) => (
              <li key={index}>
                <div className="flex flex-row items-center hover:bg-gray-100 px-4 py-2">
                  <div className="flex-grow">{group}</div>
                  <div className="flex-shrink-0">
                    <Tippy content="Add to group">
                      <div>
                        <Button
                          color="green"
                          onClick={() =>
                            addToGroup(group)
                          }
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon={faPlusCircle}
                          />
                        </Button>
                      </div>
                    </Tippy>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="h-full flex items-center justify-center">
            <h3 className="text-gray-600">
              No available roles.
            </h3>
          </div>
        )}
      </div>
      <div className="h-56 overflow-y-scroll border border-gray-200 rounded-md">
        {availableGroups?.member.length > 0 ? (
          <ul className="flex flex-col">
            {availableGroups?.member?.map((group, index) => (
              <li key={index}>
                <div className="flex flex-row items-center hover:bg-gray-100 px-4 py-2">
                  <div className="flex-grow">{group}</div>
                  <div className="flex-shrink-0">
                    <Tippy content="Remove from group">
                      <div>
                        <Button
                          color="red"
                          onClick={() =>
                            removeFromGroup(group)
                          }
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon={faMinusCircle}
                          />
                        </Button>
                      </div>
                    </Tippy>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="h-full flex items-center justify-center">
            <h3 className="text-gray-600">
              Curriculum has no Roles assigned
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentRoles;
