import React from "react";
import ComponentModal from "react-modal";
import Button from "../Button/Button";

function Modal({
    isOpen,
    setIsOpen,
    size,
    headerContent,
    children,
    footerContent,
    closeWording,
    closeButtonColor,
    capMaxSize,
}) {
    /**
     * Set the app element
     */
    ComponentModal.setAppElement("#root");

    const getSizing = () => {
        const sizes = {
            sm: "w-1/3",
            md: "w-1/2",
            lg: "w-2/3",
            xl: "w-10/12",
        };

        return sizes[size] || "w-1/2";
    };

    return (
        <ComponentModal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            className={`w-11/12 sm:${getSizing()} mx-auto my-8 bg-white rounded-lg shadow focus:outline-none py-3 px-4`}
        >
            <div className="flex flex-col">
                {headerContent && (
                    <div className="text-lg font-bold border-b border-gray-300 pb-2 mb-4">
                        {headerContent}
                    </div>
                )}
                <div
                    className={`flex-grow ${
                        capMaxSize ? " overflow-y-scroll" : ""
                    }`}
                >
                    {children}
                </div>
                <div className="flex flex-row space-x-2 border-t border-gray-300 pt-4 mt-4">
                    
                    <div className="flex-grow">
                        <Button
                            color={closeButtonColor}
                            onClick={() => setIsOpen(false)}
                        >
                            {closeWording}
                        </Button>
                    </div>
                    <div className="flex-shrink-0">{footerContent}</div>
                </div>
            </div>
        </ComponentModal>
    );
}

Modal.defaultProps = {
    isOpen: false,
    size: "md",
    headerContent: null,
    footerContent: null,
    closeWording: "Close",
    closeButtonColor: "red",
    capMaxSize: true,
};

export default Modal;
