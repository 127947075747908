import React, { useState } from "react";
import Button from "../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";


function GoToCourse(props) {

    return (
        <>
            <Button
                noRoundingRight
                isLink
                color="cyan"
                to={`/courses/roster/${props.data.course.id}`}
            >
                <FontAwesomeIcon
                    icon={faCalendarCheck}
                    className="mr-2"
                />
                Roster
            </Button>

            <Button
                noRoundingRight
                noRoundingLeft
                isLink
                to={`/courses/${props.data.course.id}`}
                color="blue"
            >
                View
            </Button>
            <Button
                noRoundingLeft
                isLink
                to={`/courses/${props.data.course.id}/edit`}
                color="teal"
            >
                <FontAwesomeIcon fixedWidth icon={faEdit} className="mr-2" />
                Edit
            </Button>
        </>
    );
}

export default GoToCourse;
