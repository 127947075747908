import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Panel from "../../components/Panel/Panel";
import Container from "../../components/Layout/Container";
import MDEditor from "@uiw/react-md-editor";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Breadcrumbs from "../../components/Breadcrumbs";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import RenderResources from "../../components/Pages/Curriculum/RenderResources";
import Table from "../../components/Table/Table";
import toast from "react-hot-toast";
import { Redirect } from "react-router-dom";
import TokensUsers from "../../components/Pages/Users/TokensUsers";
import Curriculums from "./Curriculums";
import GuestInstructors from "../../components/Pages/Curriculum/GuestInstructors";
import ListCurriculumRoles from "../../components/Pages/Curriculum/ListCurriculumRoles";

function CurriculumView(props) {
    const [curriculum, setCurriculum] = useState(null);
    const [cdeleted, setCDeleted] = useState(false);

    const getCurriculum = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/curriculums/${props.match.params.id}.json`
            )
            .then(({ data }) => {
                setCurriculum(data);
            })
            .catch((error) => console.error(error));
    };

    /**
     * Get the curriculums as soon as the component mounts
     */
    useEffect(getCurriculum, [props.match.params.id]);

    if (!curriculum) {
        return <Loader text={"Loading curriculum"} />;
    }

    function GoCourse(props) {
        return (
            <Button
                noRoundingRight
                isLink
                to={`/courses/${props.data.id}`}
                color="blue"
            >
                View
            </Button>
        )
    }

    const metable = `
    |    Feature | Support            |
  | ---------: | :----------------- |
  | CommonMark | 100%               |
  |        GFM | 100% w/ remark-gfm |
  `;

    function deleteCurriculum(e, id) {
        let removePromise = axios
            .delete(
                `${process.env.REACT_APP_API}/curriculums/${id}.json`
            )
            .then(() => {
                setCDeleted(true);
            });

        toast.promise(removePromise, {
            loading: "Removing curriculum...",
            success: "Removed curriculum",
            error: "Failed removing curriculum",
        });
    }

    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Type",
                field: "course_type_name",
            },
            {
                headerName: "Customer",
                field: "customer_name",
            },
            {
                headerName: "Method",
                field: "delivery_method_name",
            },
            {
                headerName: "Instructor(s)",
                field: "delivery_method_name",
                valueGetter: function (params) {
                    let instructors = params.data.course_instructors;
                    return instructors
                        .filter((instructor) => instructor.primary)
                        .map((instructor) => instructor.name);
                },
            },
            {
                headerName: "Start Date",
                field: "startDate",
                width: 130,
                suppressSizeToFit: true,
                sort: "asc",
            },
            {
                headerName: "",
                field: "id",
                cellRendererFramework: GoCourse,
                width: 160,
                suppressSizeToFit: true,
            },
        ],
    };

    if (cdeleted) {
        return <Redirect to={`/curriculums`} />;
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>{curriculum.title
                } | Curriculums</title >
            </Helmet >
            <Breadcrumbs
                noMarginBottom
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Curriculum Management",
                        to: "/curriculums",
                    },
                    {
                        name: curriculum.title,
                        to: `/curriculums/${props.match.params.id}`,
                    },
                ]}
            />

            <div
                className={`${curriculum.enabled ? "bg-green-100" : "bg-red-100"
                    } text-teal-700 w-full p-10 text-center relative`}
            >
                <div className="absolute top-0 right-0 p-2">
                    <div
                        className={`${curriculum.enabled ? "bg-green-400" : "bg-red-400"
                            } py-1 px-2 rounded-md text-white text-sm font-bold`}
                    >
                        {curriculum.enabled ? "Enabled" : "Disabled"}
                    </div>
                </div>

                {!curriculum.enabled ? <>
                    <div className="absolute top-0 right-0 p-2">
                        <div
                            className={`${curriculum.enabled ? "bg-green-400" : "bg-red-400"
                                } py-1 px-2 rounded-md text-white text-sm font-bold`}
                        >
                            Delete
                        </div>
                    </div>
                </> : ""}

                <h1 className="font-extrabold text-2xl mb-4">
                    {curriculum.title}
                </h1>

                <h2 className="text-lg font-semibold leading-3">
                    Duration: {curriculum.duration} days
                </h2>
            </div>

            <div className="mt-4">
                <Container>
                    <Panel>
                        <p className="text-lg font-bold mb-4">Description</p>
                        <MDEditor.Markdown source={curriculum.description} />
                    </Panel>
                    <Panel>
                        <p className="text-lg font-bold mb-4"> Student Roles</p>
                        <div>
                            <ListCurriculumRoles
                                studentRoles={curriculum.curriculumRoles}
                            />
                        </div>
                    </Panel>

                    <Panel>
                        <p className="text-lg font-bold mb-4">
                            Assigned Materials
                        </p>
                        {curriculum.curriculum_name ? (
                            <div>
                                <p className="mb-4">
                                    Assigned resource name:{" "}
                                    <span className="font-mono py-1 px-2 rounded bg-gray-200">
                                        {curriculum.curriculum_name}
                                    </span>
                                </p>
                                <div className="grid grid-cols-4 grid-rows-1 gap-4 mt-4">
                                    <RenderResources
                                        title={"Guides"}
                                        resources={curriculum.resources}
                                        curriculumName={curriculum.curriculum_name}
                                    />
                                    <RenderResources
                                        title={"Lab Materials"}
                                        resources={curriculum.resources}
                                        curriculumName={curriculum.curriculum_name}
                                    />
                                    <RenderResources
                                        title={"Instructor"}
                                        resources={curriculum.resources}
                                        curriculumName={curriculum.curriculum_name}
                                    />
                                    <RenderResources
                                        title={"Videos"}
                                        resources={curriculum.resources}
                                        curriculumName={curriculum.curriculum_name}
                                    />

                                </div>
                            </div>
                        ) : (
                            "No resources assigned to this curriculum."
                        )}
                    </Panel>

                    <Panel>
                        <p className="text-lg font-bold mb-4">
                            Instructor Notes
                        </p>
                        {curriculum.notes ? (
                            <div>
                                <MDEditor.Markdown source={curriculum.notes} />
                            </div>
                        ) : (
                            <div>No instructor notes to show.</div>
                        )}
                    </Panel>

                    <Panel>
                        <p className="text-lg font-bold mb-4">
                            Guest Instructors
                        </p>
                        {curriculum.guest_instructors ? (
                            <div>
                                <GuestInstructors
                                    guest_instructors={curriculum.guest_instructors}
                                />
                            </div>
                        )
                            : (
                                <div>No Guest Instructors assigned to this curriculum</div>
                            )}
                    </Panel>

                    <Panel>
                        <p className="text-lg font-bold mb-4">Associated Courses</p>
                        {curriculum.courses.length > 0 ? (
                            <Table
                                gridOptions={gridOptions}
                                rowData={curriculum.courses}
                            />

                        ) : "No courses have been assigned to this curriculum."
                        }
                    </Panel>
                    {!props.token.claims.includes("guest-instructor") && (
                        <div className="flex flex-row">
                            <div className="flex ml-auto">
                                <Button
                                    size="md"
                                    color="teal"
                                    isLink
                                    to={`/curriculums/${props.match.params.id}/edit`}
                                >
                                    <FontAwesomeIcon
                                        fixedWidth
                                        icon={faEdit}
                                        className="mr-2"
                                    />
                                    Edit
                                </Button>
                                {curriculum.courses.length == 0 ? (
                                    <Button
                                        size="md"
                                        color="red"
                                        onClick={(e) => deleteCurriculum(e, props.match.params.id)}
                                    >
                                        <FontAwesomeIcon
                                            fixedWidth
                                            icon={faTrash}
                                            className="mr-2"
                                        />
                                        Delete
                                    </Button>
                                ) : ""}
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </div >
    );
}

export default withRouter(CurriculumView);
