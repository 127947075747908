import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Badge from "../../Badge/Badge";
import ResourceLink from "../../CellRenderers/Resources/ResourceLink";
import ResourceEnabled from "../../CellRenderers/Resources/ResourceEnabled";
import ResourceInternalOnly from "../../CellRenderers/Resources/ResourceInternalOnly";
import Table from "../../Table/Table";

function ExpandablePanel({ title, resources, getResources }) {
    const [isExpanded, setIsExpanded] = useState(false);

    /**
     * Set up the gridOptions for the table
     */
    const gridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Resource Name",
                field: "name",
                width: 450,
                cellRendererFramework: ResourceLink,
                suppressSizeToFit: false,
                sort: "asc",
            },
            {
                headerName: "Type",
                field: "material_type",
            },
            {
                headerName: "Enabled",
                field: "enabled",
                filter: false,
                cellRendererFramework: ResourceEnabled,
                cellRendererParams: {
                    reload: getResources,
                },
            },
            {
                headerName: "Internal",
                field: "internalOnly",
                filter: false,
                cellRendererFramework: ResourceInternalOnly,
                cellRendererParams: {
                    reload: getResources,
                },
            },
        ],
    };

    return (
        <div className="mb-2">
            <div
                className={`flex p-2 ${
                    isExpanded ? "bg-gray-200" : "bg-gray-100"
                } hover:bg-gray-200 cursor-pointer ${
                    isExpanded ? "rounded-t" : "rounded"
                }`}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div>
                    <Badge
                        color="blue"
                        text={resources.length}
                        round
                        className="mr-2"
                    />
                    {title}
                </div>
                <div className="ml-auto">
                    <FontAwesomeIcon
                        fixedWidth
                        icon={isExpanded ? faChevronUp : faChevronDown}
                    />
                </div>
            </div>
            {isExpanded && (
                <div className="rounded-b border border-gray-100 p-4">
                    <Table gridOptions={gridOptions} rowData={resources} />
                </div>
            )}
        </div>
    );
}

export default ExpandablePanel;
