import React from "react";

function NumberInput({ min, max, step, value, onChange, disabled }) {
    return (
        <input
            className="border-gray-300 focus:border-blue-300 focus:shadow-outline-blue placeholder-gray-500 appearance-none relative block w-full px-4 py-2 border text-gray-900 rounded-md focus:outline-none focus:z-10 disabled:cursor-not-allowed"
            type="number"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
        />
    );
}

NumberInput.defaultProps = {
    disabled: false,
};

export default NumberInput;
