import React from "react";
import { Link } from "react-router-dom";

function NavLink({ to, pathname, children, external }) {
    if (external) {
        return (
            <a
                href={to}
                target="_blank"
                rel="noreferrer"
                className="text-gray-900 border-b-2 hover:border-red-100 hover:bg-gray-100 px-4 text-sm font-medium flex items-center"
            >
                {children}
            </a>
        );
    }

    const isActive = pathname === to;
    return (
        <Link
            to={to}
            className={`${
                isActive
                    ? "border-red-500 hover:border-red-500"
                    : "border-transparent"
            } text-gray-900 border-b-2 hover:border-red-100 hover:bg-gray-100 px-4 text-sm font-medium flex items-center`}
        >
            {children}
        </Link>
    );
}

NavLink.defaultProps = {
    to: "/",
    pathname: "/",
    external: false,
};

export default NavLink;
