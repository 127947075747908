import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import AlternateLoader from "../../components/Loader/AlternateLoader";
import RegistrationForm from "../../components/Pages/Register/RegistrationForm";
import ToastComponent from "../../components/Toast/ToastComponent";
import logo from "../../images/Trellix_LOGO_Color_Graphite.png";

const getTokenFromUrl = () => {
    const url = window.location.pathname;
    const lastIndexOf = url.lastIndexOf("/");
    return url.substring(lastIndexOf + 1);
};

function Register(props) {
    const token = getTokenFromUrl();
    const [isLoading, setIsLoading] = useState(true);
    const [isInvalidToken, setIsInvalidToken] = useState(false);
    const [email, setEmail] = useState(null);

    const getEmailFromToken = () => {
        axios
            .get(`${process.env.REACT_APP_API}/register/${token}.json`)
            .then(({ data }) => {
                /**
                 * If a status is returned, the token is invalid, else
                 * set the email field to display
                 */
                if ("status" in data) {
                    setIsInvalidToken(true);
                    window.location.replace("/?message=already_registered");
                } else {
                    setEmail(data.email);
                }

                setIsLoading(false);
            })
            .catch((error) => {
                toast.error("Unable to validate token");
            });
    };

    useEffect(getEmailFromToken, [token]);

    return (
        <div className="font-body bg-gray-100 min-h-screen flex justify-center items-center">
            <Helmet title="Register | Training Labs 2.0 | Trellix" />

            <ToastComponent />

            <div className="max-w-lg w-full py-12 px-6">
                <img
                    src={logo}
                    className="mx-auto h-12 w-auto"
                    alt="Trellix Logo"
                />
                <p className="mt-2 text-lg text-center text-gray-700">
                    Registration | Training Labs 2.0
                </p>
                {isLoading ? (
                    <div className="mt-5">
                        <AlternateLoader />
                        <div className="text-center text-gray-700 mt-5">
                            Verifying token, please wait...
                        </div>
                    </div>
                ) : isInvalidToken ? (
                    <div className="mt-5 text-center">
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="3x"
                            className="text-red-500 w-24 h-24"
                        />
                        <div className="text-red-500 font-semibold mt-5">
                            Invalid token! Redirecting to login...
                        </div>
                    </div>
                ) : (
                    <RegistrationForm token={token} email={email} />
                )}
            </div>
        </div>
    );
}

export default Register;
