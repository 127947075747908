import { PropertyKeys } from "ag-grid-community";
import React, { useState, useEffect } from "react";
import Table from "../../../components/Table/Table";
import Link from "../../Link/Link";

function GuestInstructors(props) {

  console.log("props",props)
  const gridOptions = {
    getRowNodeId(data) {
        return data.id;
    },
    paginationPageSize: 15,
    columnDefs: [
        {
            headerName: "User ID",
            field: "uid",
            cellRendererFramework: function(params) {
              return (
                <Link to={`/user/admin/${params.value}`}>{params.value}</Link>
              )
            }
        },
        {
            headerName: "Name",
            field: "name",
        },
    ],
};

  return (
    <Table
      gridOptions={gridOptions}
      rowData={props.guest_instructors}
      />
  );

}

export default GuestInstructors;