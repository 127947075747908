import React from "react";
import Button from "../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import moment from "moment";

function StudentCertificateDownload(props) {
    if ( props.tokens && props.token.claims.includes("user-admin") && moment(props.data.startDate).add(props.data.duration, 'days').isBefore(moment())) {
        return (
            <Tippy content="Download course attendance certificate">
                <div>
                    <a
                        href={`${process.env.REACT_APP_API}/student/getcert/${props.data.student_id}.pdf`}
                        target="_blank"
                        className="flex"
                    >
                        <Button color="green">
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </a>
                </div>
            </Tippy>
        );
    } else {
        return (<></>);
    }
}

export default StudentCertificateDownload;
