import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import AllVMTable from "../../components/Pages/Vms/AllVMTable";

function ListAllVms(props) {
    return (
        <div className="w-full">
            <Helmet>
                <title>All VMs</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "VM user admin",
                        to: "/vms/listallvms",
                    },
                ]}
            />
            <Container>
                <AllVMTable token={props.token} />
            </Container>
        </div>
    );
}

export default ListAllVms;
