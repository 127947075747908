import { Redirect, Route } from "react-router-dom";
import Middleware from "./Middleware";

function ProtectedRoute(props) {
    const claims = props.claims;
    const requires = props.requires;

    // If the user has the required claims
    if (Middleware(requires, claims)) {
        return <Route {...props} />;
    }

    /**
     * If the user doesn't have permissions, redirect them to the home
     * page with query params to display a message
     * */
    return <Redirect to={`/?message=permissions&page=${props.path}`} />;
}

export default ProtectedRoute;
