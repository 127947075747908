import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import NormalUsers from "../../components/Pages/Users/NormalUsers";
import StaffUsers from "../../components/Pages/Users/StaffUsers";
import TokensUsers from "../../components/Pages/Users/TokensUsers";
import Breadcrumbs from "../../components/Breadcrumbs";
import Input from "../../components/Form/Input";
import Button from "../../components/Button/Button";
import toast from "react-hot-toast";


function Users({ token }) {
    const [selectedUsers, setSelectedUsers] = useState("normal");
    const [email, setEmail] = useState();

    const newToken = () => {
        axios
            .post(`${process.env.REACT_APP_API}/tokens.json`, {
                token: {
                    email: email
                }
            })
            .then(({ data }) => {
                console.log("data", data)
                toast.success("User Registered");
            })
            .catch((error) => console.error(error));
    };


    return (
        <div className="w-full">
            <Helmet>
                <title>Users</title>
            </Helmet>

            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "User Management",
                        to: "/users",
                    },
                ]}
            />

            <Container>
                <Panel>
                    <div className="flex mb-4">
                        <div
                            className={`cursor-pointer border py-2 px-4 rounded-l-md select-none ${selectedUsers.includes("normal")
                                ? "bg-teal-100 border-teal-300 text-teal-700"
                                : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                            onClick={() => setSelectedUsers("normal")}
                        >
                            Users
                        </div>
                        <div
                            className={`cursor-pointer border py-2 px-4 select-none ${selectedUsers.includes("staff")
                                ? "bg-teal-100 border-teal-300 text-teal-700"
                                : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                            onClick={() => setSelectedUsers("staff")}
                        >
                            Staff
                        </div>
                        <div
                            className={`cursor-pointer border py-2 px-4 rounded-r-md select-none ${selectedUsers.includes("tokens")
                                ? "bg-teal-100 border-teal-300 text-teal-700"
                                : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                            onClick={() => setSelectedUsers("tokens")}
                        >
                            Tokens
                        </div>
                        {token.claims.includes("admins") &&
                            <div className="flex flex-row">
                                <Input type="text"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                    placeholder="E-Mail Address"
                                    value={email}
                                    onChange={setEmail}
                                />
                                <Button onClick={(e) => newToken()} color="red">Register</Button>
                            </div>
                        }
                    </div>

                    <div className="">
                        {selectedUsers === "normal" ? (
                            <NormalUsers token={token} />
                        ) : selectedUsers === "staff" ? (
                            <StaffUsers token={token} />
                        ) : (
                            <TokensUsers token={token} />
                        )}
                    </div>
                </Panel>
            </Container>
        </div>
    );
}

export default Users;
