import { faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import axios from "axios";
import toast from "react-hot-toast";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";

function AwaitingActions(props) {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    /**
     * Render the confirm button inside the modal footer
     */
    const getConfirmButton = () => (
        <Button color="green" onClick={() => removeUser()}>
            Confirm
        </Button>
    );

    /**
     * Remove the user via a DELETE request from axios, and then reload
     * the data to refresh the table content
     */
    const removeUser = () => {
        let removePromise = axios
            .delete(`${process.env.REACT_APP_API}/tokens/${props.data.id}.json`)
            .then(({ data }) => {
                props.getData();
                setConfirmModalOpen(false);
            });

        toast.promise(removePromise, {
            loading: "Removing user...",
            success: "Removed user",
            error: "Failed removing user",
        });
    };

    /**
     * Resend the enrolment email to the user and refresh the data after
     * the request
     */
    const resendEnrolMail = () => {
        let resendPromise = axios
            .get(
                `${process.env.REACT_APP_API}/token/send_enrol_mail.json?id=${props.data.id}`
            )
            .then(({ data }) => {
                props.getData();
            });

        toast.promise(resendPromise, {
            loading: "Re-sending enrolment email to user...",
            success: "Sent enrolment email to user",
            error: "Failed to re-send enrolment email user",
        });
    };

    return (
        <div>
            {props.data.id ?
                <div>
                    <div className="flex flex-row space-x-2">
                        <Tippy content="Remove">
                            <div>
                                <Button
                                    color="red"
                                    onClick={() =>
                                        setConfirmModalOpen(!confirmModalOpen)
                                    }
                                >
                                    <FontAwesomeIcon fixedWidth icon={faTimes} />
                                </Button>
                            </div>
                        </Tippy>
                        <Tippy content="Re-send Registration Email">
                            <div>
                                <Button color="blue" onClick={() => resendEnrolMail()}>
                                    <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                                </Button>
                            </div>
                        </Tippy>
                    </div>
                    <Modal
                        isOpen={confirmModalOpen}
                        setIsOpen={setConfirmModalOpen}
                        closeWording={"Cancel"}
                        headerContent={"Confirm"}
                        footerContent={getConfirmButton()}
                    >
                        Are you sure you want to remove {props.data.email}?
                    </Modal>
                </div>
                : <></>
            }
        </div>
    );
}

export default AwaitingActions;
