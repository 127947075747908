import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
    faCircleNotch,
    faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import extractCnFromDn from "../../../util/extractCnFromDn";
import Alert from "../../Alert/Alert";
import Button from "../../Button/Button";
import Panel from "../../Panel/Panel";

function verifyGeneratedCode(
    e,
    code,
    setToken,
    setSomethingWentWrong,
    setLoggedIn
) {
    e.preventDefault();

    axios
        .post(`${process.env.REACT_APP_API}/user/otpverify.json`, {
            code: code,
        })
        .then(({ data }) => {
            if (data.status.includes("OTP Verified")) {
                let parsedGroups = data.info.groups.map((group) =>
                    extractCnFromDn(group)
                );
                setToken({
                    profile: data.info.user,
                    claims: parsedGroups,
                });
            } else {
                setSomethingWentWrong(true);
                setLoggedIn(false);
                toast.error((t) => (
                    <span>
                        Oops, something went wrong.
                        <br />
                        Please log in again.
                    </span>
                ));
            }
        })
        .catch((error) => {
            console.error(error);
            setSomethingWentWrong(true);
            setLoggedIn(false);
        });
}

export default function TwoFactorSetup({ setLoggedIn, setToken }) {
    const [showQrCode, setShowQrCode] = useState(false);
    const [qrCodeObject, setQrCodeObject] = useState(null);
    const [verifyCode, setVerifyCode] = useState("");
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);

    const codeInputRef = useRef();

    useEffect(() => {
        if (codeInputRef.current) {
            codeInputRef.current.focus();
        }
    });

    const getQrCodeObject = () => {
        axios
            .get(`${process.env.REACT_APP_API}/user/showOTPQRcode.json`)
            .then(({ data }) => setQrCodeObject(data))
            .catch((error) => console.error(error));
    };

    return (
        <div className="mt-5 text-center sm:text-sm">
            <Panel>
                <p className="text-gray-700 mb-5">
                    For extra security you must setup MFA
                    <br />
                    (Multi-factor Authentication)
                </p>
                {showQrCode && (
                    <div>
                        <p>
                            Please scan the QR Code below into your
                            authenticator application (Google Authenticator
                            recommended).
                        </p>
                        <div className="mt-5 w-full h-auto mx-auto">
                            {qrCodeObject ? (
                                <div>
                                    <div>
                                        <div
                                            className="flex justify-center"
                                            dangerouslySetInnerHTML={{
                                                __html: qrCodeObject.qrcode,
                                            }}
                                        />
                                    </div>
                                    <div className="mt-5 max-w-full">
                                        <pre className="whitespace-pre-line overflow-x-auto bg-gray-200 p-4 rounded-md">
                                            {qrCodeObject.code}
                                        </pre>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <FontAwesomeIcon
                                        size="3x"
                                        spin
                                        className="text-gray-600"
                                        icon={faCircleNotch}
                                    />
                                </div>
                            )}
                        </div>
                        {somethingWentWrong && (
                            <div className="mt-5">
                                <Alert
                                    color="red"
                                    title="Whoops! Something went wrong."
                                    icon={faExclamationTriangle}
                                >
                                    Please try scanning the QR Code above again
                                    and re-entering your verification code.
                                </Alert>
                            </div>
                        )}
                        <p className="mt-5">
                            Once scanned, please verify by entering the six
                            digit code generated and click "Verify" to complete
                            this process.
                        </p>
                        <form className="mt-5">
                            <div className="rounded-md shadow-sm">
                                <div>
                                    <input
                                        aria-label="Verify code"
                                        name="verify"
                                        type="text"
                                        required=""
                                        className="border-gray-300 placeholder-gray-500 appearance-none relative block w-full px-4 py-3 border text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                                        placeholder="Six digit code..."
                                        onChange={(e) =>
                                            setVerifyCode(e.target.value)
                                        }
                                        value={verifyCode}
                                        autoComplete="off"
                                        autoFocus
                                        ref={codeInputRef}
                                    />
                                </div>
                            </div>
                            <div className="mt-5">
                                <Button
                                    onClick={(e) =>
                                        verifyGeneratedCode(
                                            e,
                                            verifyCode,
                                            setToken,
                                            setSomethingWentWrong,
                                            setLoggedIn
                                        )
                                    }
                                    block
                                    color="gray"
                                >
                                    Verify
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
                {!showQrCode && (
                    <div>
                        <Button
                            onClick={() => {
                                setShowQrCode(true);
                                getQrCodeObject();
                            }}
                            block
                            size="md"
                            color="gray"
                        >
                            Generate QR Code
                        </Button>
                    </div>
                )}
            </Panel>
        </div>
    );
}
