import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "../../Button/Button";

function Actions(props) {
    return (
        <Button color="teal" isLink to={`/vms/admin/${props.data.id}`}>
            <FontAwesomeIcon fixedWidth icon={faEdit} className="mr-2" />
            Edit
        </Button>
    );
}

export default Actions;
