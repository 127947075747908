import Link from "../../components/Link/Link";

export default function NotFound() {
    return (
        <div className="flex flex-col flex-grow items-center justify-center">
            <div className="text-3xl font-bold mb-1">
                Oops, something went wrong!
            </div>
            <div>
                Click <Link to="/">here</Link> to return home.
            </div>
        </div>
    );
}
