import { faChevronRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "./Layout/Container";
import Link from "./Link/Link";

function Breadcrumbs(props) {
    return (
        <div
            className={`w-full bg-white border-b-2 border-gray-200 p-2 -mt-4 ${
                props.noMarginBottom ? "" : "mb-4"
            }`}
        >
            <Container>
                <div className="flex flex-row space-x-4 text-sm font-semibold">
                    {props.crumbs.map((crumb, index, array) => {
                        if (array.length - 1 === index) {
                            return (
                                <div className="text-teal-700" key={index}>
                                    {crumb.name}
                                </div>
                            );
                        }

                        return (
                            <div className="flex space-x-4" key={index}>
                                <Link to={crumb.to}>
                                    {crumb.name === "Home" ? (
                                        <FontAwesomeIcon icon={faHome} />
                                    ) : (
                                        crumb.name
                                    )}
                                </Link>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="text-gray-500"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Container>
        </div>
    );
}

Breadcrumbs.defaultProps = {
    noMarginBottom: false,
};

export default Breadcrumbs;
