import { faMinus, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

function LastBoot(props) {
    return (
        <div>
            <FontAwesomeIcon
                fixedWidth
                icon={props.data.status === "running" ? faPlay : faMinus}
                className={`mr-2 ${
                    props.data.status === "running"
                        ? "text-green-500"
                        : "text-gray-500"
                }`}
            />
            {props.value ? moment(props.value).fromNow() : "Never"}
        </div>
    );
}

export default LastBoot;
