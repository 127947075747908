import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button/Button";
import Tippy from "@tippyjs/react";
import axios from "axios";
import toast from "react-hot-toast";

function GuestInstructorCurriculums({
    allCurriculums,
    user,
    refreshUser,
}) {
    const [availableCurriculums, setAvailableCurriculums] = useState({
        member: [],
        nonMember: [],
    });

    useEffect(() => {
        formatAvailableCurriculums();
    }, [allCurriculums]);

    const formatAvailableCurriculums = () => {
        let curriculums = {
            member: [],
            nonMember: [],
        };
        allCurriculums && allCurriculums.forEach((element) => {
          if (element.member) {
            curriculums.member.push(element)
          } else {
            curriculums.nonMember.push(element)
          }
        })
        setAvailableCurriculums(curriculums);
    };

    const addCurriculumToUser = (cid) => {
        let cur = allCurriculums.find((item) => item.id === cid);
        let addCurriculumPromise = axios
            .get(
                `${process.env.REACT_APP_API}/curriculum/addGuestInstructor.json?userid=${user.username}&cid=${cur.id}`
            )
            .then(({ data }) => refreshUser());

        toast.promise(addCurriculumPromise, {
            success: `Added user to ${cur.title}`,
            error: `Failed to add user to ${cur.title}`,
            loading: "Adding...",
        });
    };

    const removeCurriculumFromUser = (cid) => {
        let cur = allCurriculums.find((item) => item.id === cid);
        let removeCurriculumPromise = axios
            .get(
                `${process.env.REACT_APP_API}/curriculum/removeGuestInstructor.json?userid=${user.username}&cid=${cur.id}`
            )
            .then(({ data }) => refreshUser());

        toast.promise(removeCurriculumPromise, {
            success: `Removed user from ${cur.name}`,
            error: `Failed to remove user from ${cur.name}`,
            loading: "Removing...",
        });
    };

    return (
        <div className="grid grid-cols-2 grid-rows-1 gap-4">
            <div className="h-56 overflow-y-scroll border border-gray-200 rounded-md">
                {availableCurriculums?.nonMember.length > 0 ? (
                    <ul className="flex flex-col">
                        {availableCurriculums?.nonMember?.map((cur) => (
                            <li key={cur.id}>
                                <div className="flex flex-row items-center hover:bg-gray-100 px-4 py-2">
                                    <div className="flex-grow">{cur.title}</div>
                                    <div className="flex-shrink-0">
                                        <Tippy content="Add curriculum">
                                            <div>
                                                <Button
                                                    color="green"
                                                    onClick={() =>
                                                      addCurriculumToUser(cur.id)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        icon={faPlusCircle}
                                                    />
                                                </Button>
                                            </div>
                                        </Tippy>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="h-full flex items-center justify-center">
                        <h3 className="text-gray-600">
                            No available curriculums for user to be assigned.
                        </h3>
                    </div>
                )}
            </div>
            <div className="h-56 overflow-y-scroll border border-gray-200 rounded-md">
                {availableCurriculums?.member.length > 0 ? (
                    <ul className="flex flex-col">
                        {availableCurriculums?.member?.map((cur) => (
                            <li key={cur.id}>
                                <div className="flex flex-row items-center hover:bg-gray-100 px-4 py-2">
                                    <div className="flex-grow">{cur.title}</div>
                                    <div className="flex-shrink-0">
                                        <Tippy content="Remove from group">
                                            <div>
                                                <Button
                                                    color="red"
                                                    onClick={() =>
                                                      removeCurriculumFromUser(cur.id)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        fixedWidth
                                                        icon={faMinusCircle}
                                                    />
                                                </Button>
                                            </div>
                                        </Tippy>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="h-full flex items-center justify-center">
                        <h3 className="text-gray-600">
                            User has not been assigned any Curriculums.
                        </h3>
                    </div>
                )}
            </div>
        </div>
    );
}

export default GuestInstructorCurriculums;
