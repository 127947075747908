import { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Input from "../../components/Form/Input";
import MDEditor from "@uiw/react-md-editor";
import Selectbox from "../../components/Form/Selectbox";
import RenderResources from "../../components/Pages/Curriculum/RenderResources";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router";
import toast from "react-hot-toast";
import Toggle from "../../components/Toggle/Toggle";

const validateForm = (params) => {
    /**
     * Check the title isn't null or empty
     */
    if (params.curriculumTitle === null || params.curriculumTitle === "") {
        return false;
    }

    /**
     * Check the description isn't null or empty
     */
    if (
        params.curriculumDescription === null ||
        params.curriculumDescription === ""
    ) {
        return false;
    }

    if (params.selectedResource === null || params.selectedResource === "") {
        return false;
    }

    if (params.duration === 0 || params.selectedResource === "") {
        return false;
    }

    return true;
};

const submitForm = (params, setRedirect) => {
    let createPromise = axios
        .post(`${process.env.REACT_APP_API}/curriculums.json`, {
            title: params.title,
            duration: params.duration,
            description: params.description,
            notes: params.notes,
            curriculum_name: params.selectedResource.value,
            custom: params.isCustom
        })
        .then(({ data }) => {
            setRedirect(true);
        })
        .catch((error) => console.error(error));

    toast.promise(createPromise, {
        loading: "Submitting...",
        success: "Successfully edited curriculum!",
        error: "Failed editing curriculum",
    });
};

function CurriculumNew(props) {
    const [title, setTitle] = useState("");
    const [duration, setDuration] = useState(1);
    const [isCustom, setIsCustom] = useState(false);
    const [description, setDescription] = useState("");
    const [notes, setNotes] = useState("");

    const [resources, setResources] = useState(null);
    const [resource, setResource] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null);

    const [redirect, setRedirect] = useState(false);

    /**
     * Get the curriculums as soon as the component mounts
     */
    useEffect(() => {
        const getResources = () => {
            axios
                .get(`${process.env.REACT_APP_API}/resource/names.json`)
                .then(({ data }) => {
                    let parsedResources = data.resources.map((resource) => {
                        return {
                            value: resource.curriculum_name,
                            label: resource.curriculum_name,
                        };
                    });
                    setResources(parsedResources);
                })
                .catch((error) => console.error(error));
        };
        /**
         * Run the above functions when the page loads
         */
        getResources();
    }, [props.match.params.id]);

    /**
     * Listen for changes in the resources object
     */
    useEffect(() => {
        const getResource = () => {
            axios
                .get(
                    `${process.env.REACT_APP_API}/resource/resource_for.json?curriculum_name=${selectedResource.value}`
                )
                .then(({ data }) => {
                    setResource(data.resources);
                })
                .catch((error) => console.error(error));
        };

        if (selectedResource) {
            getResource();
        }
    }, [selectedResource]);

    if (redirect) {
        return <Redirect to={`/curriculums`} />;
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>New | Curriculums</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Curriculum Management",
                        to: "/curriculums",
                    },
                    {
                        name: "Create",
                        to: `/curriculums/new`,
                    },
                ]}
            />

            <Container>
                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Create Curriculum
                    </h1>

                    <div className="grid grid-cols-2 grid-rows-1 gap-4">
                        <div>
                            <p className="font-semibold text-gray-700 mb-1">
                                Title
                            </p>
                            <Input
                                ariaLabel="Title"
                                name="title"
                                id="title"
                                type="text"
                                required
                                autoComplete="off"
                                placeholder="Title..."
                                onChange={setTitle}
                                value={title}
                                hasError={false}
                            />
                        </div>
                        <div>
                            <p className="font-semibold text-gray-700 mb-1">
                                Duration
                            </p>
                            <input
                                className="border-gray-300  focus:border-blue-300 focus:shadow-outline-blue placeholder-gray-500 appearance-none relative block w-full px-4 py-2 border text-gray-900 rounded-md focus:outline-none focus:z-10"
                                type="number"
                                min={0.5}
                                max={14}
                                step={0.5}
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                            />
                        </div>

                        <div>
                            <p className="font-semibold text-gray-700 mb-1">
                                Custom
                            </p>
                            <Toggle
                                isEnabled={isCustom}
                                onClick={() => setIsCustom(!isCustom)}
                            />
                        </div>
                    </div>
                </Panel>

                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Description
                    </h1>

                    <MDEditor
                        height={600}
                        value={description}
                        onChange={setDescription}
                    />
                </Panel>

                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Assign Resources
                    </h1>

                    <Selectbox
                        onChange={setSelectedResource}
                        defaultValue={selectedResource}
                        options={resources}
                    />

                    {resource && (
                        <div className="grid grid-cols-4 grid-rows-1 gap-4 mt-4">
                            <RenderResources
                                title={"Guides"}
                                resources={resource}
                            />
                            <RenderResources
                                title={"Lab Materials"}
                                resources={resource}
                            />
                            <RenderResources
                                title={"Instructor"}
                                resources={resource}
                            />
                             <RenderResources
                                title={"Videos"}
                                resources={resource}
                            />

                        </div>
                    )}
                </Panel>

                <Panel>
                    <h1 className="font-extrabold text-2xl mb-4">
                        Instructor Notes
                    </h1>
                    <MDEditor height={300} value={notes} onChange={setNotes} />
                </Panel>

                <div className="flex flex-row justify-between">
                    <div>
                        <Button
                            size="md"
                            color="gray"
                            onClick={() => setRedirect(true)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            size="md"
                            color="green"
                            onClick={() =>
                                validateForm({
                                    title,
                                    duration,
                                    description,
                                    selectedResource,
                                    notes,
                                }) &&
                                submitForm(
                                    {
                                        title,
                                        duration,
                                        description,
                                        selectedResource,
                                        notes,
                                        isCustom
                                    },
                                    setRedirect
                                )
                            }
                        >
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faPlus}
                                className="mr-2"
                            />
                            Create
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default CurriculumNew;
