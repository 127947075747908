import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChalkboard,
    faChalkboardTeacher,
    faCircle,
    faSave,
    faSync,
    faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import StackedLabel from "../../components/Label/StackedLabel";
import Input from "../../components/Form/Input";
import Button from "../../components/Button/Button";
import axios from "axios";
import toast from "react-hot-toast";
import Breadcrumbs from "../../components/Breadcrumbs";
import AlternateLoader from "../../components/Loader/AlternateLoader";
import CoursesTaken from "../../components/Pages/Users/CoursesTaken";
import CoursesTaught from "../../components/Pages/Users/CoursesTaught";
import Tippy from "@tippyjs/react";
import Modal from "../../components/Modal/Modal";
import LdapGroupMembership from "../../components/Pages/Users/LdapGroupMembership";
import LabVMTable from "../../components/Pages/Vms/LabVMTable";
import GuestInstructorCurriculums from "../../components/Pages/Users/GuestInstructorCurriculums";

function validateForm(formData) {
    /**
     * Validate each form field seperately
     */
    if (!formData.firstName) {
        formData.setFirstNameHasError(true);
        return false;
    }

    if (!formData.surname) {
        formData.setSurnameHasError(true);
        return false;
    }

    if (!formData.company) {
        formData.setCompanyHasError(true);
        return false;
    }

    /**
     * If everything passes, set all errors to false
     * (In case they have been true and fixed)
     */
    formData.setFirstNameHasError(false);
    formData.setSurnameHasError(false);
    formData.setCompanyHasError(false);

    return true;
}

function submitForm(formData, props) {
    let postData = {
        user_id: formData.username,
        firstName: formData.firstName,
        surname: formData.surname,
        company: formData.company,
    };

    let updatePromise = axios
        .post(`${process.env.REACT_APP_API}/user/update.json`, postData)
        .then(({ data }) => {
            if (props.token !== undefined) {
                // Parse out the data returned from the updated
                // user and update the token stored in the cache
                let newToken = {
                    profile: {
                        company: data.info.company,
                        firstname: data.info.firstname,
                        surname: data.info.surname,
                        email: props.token.profile.email,
                        username: props.token.profile.username,
                    },
                    claims: props.token.claims,
                };

                // Set the token with the new data
                props.setToken(newToken);
            }
        });

    toast.promise(updatePromise, {
        loading: "Updating user...",
        success: "Updated user",
        error: "Failed updating user",
    });
}

function AdminUserProfileEdit(props) {
    /**
     * Extract the parameters from the URL
     * /user/edit/:username
     */
    let params = useParams();
    const [isTokenSet, setIsTokenSet] = useState(
        props.token !== undefined && !props.isEdit
    );
    let { username } = isTokenSet ? props.token.profile : params;

    const [user, setUser] = useState(null);
    const [coursesTaken, setCoursesTaken] = useState(null);
    const [isInstructor, setIsInstructor] = useState(false);
    const [coursesTaught, setCoursesTaught] = useState(null);

    const [firstName, setFirstName] = useState("");
    const [firstNameHasError, setFirstNameHasError] = useState(false);

    const [surname, setSurname] = useState("");
    const [surnameHasError, setSurnameHasError] = useState(false);

    const [company, setCompany] = useState("");
    const [companyHasError, setCompanyHasError] = useState(false);

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const [curriculums, setCurriculums] = useState([]);

    const getUserInfo = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/user/profile.json?userid=${username}`
            )
            .then(({ data }) => {
                setUser(data.user);
                setFirstName(data.user.firstname);
                setSurname(data.user.surname);
                setCompany(data.user.company === null ? "" : data.user.company);
                setCoursesTaken(data.courses);
                setIsInstructor(data.user.instructor);
                setCoursesTaught(data.coursesTaught);
                setCurriculums(data.curriculums);
            });
    };

    /**
     * Render the confirm button inside the modal footer
     */
    const getConfirmButton = () => (
        <Button color="green" onClick={() => resetOtp()}>
            Confirm
        </Button>
    );

    const resetOtp = () => {
        let resetPromise = axios
            .get(
                `${process.env.REACT_APP_API}/user/resetOTP.json?uid=${user.username}`
            )
            .then(() => getUserInfo())
            .then(() => setConfirmModalOpen(false));

        toast.promise(resetPromise, {
            loading: "Resetting...",
            success: "Reset OTP",
            error: "Failed resetting OTP",
        });
    };

    /**
     * Make staff Button
     */
    function MakeStaffButton () {
        return (
        <Button color="warning" onClick={() => makestaff()} disabled={!props.token.claims.includes("admins")}>
            Make Staff
        </Button>
        )
    }

    const makestaff = () => {
        let makestaffPromise = axios
            .get(
                `${process.env.REACT_APP_API}/user/makestaff/${user.username}.json`
            )
            .then(() => getUserInfo())

        toast.promise(makestaffPromise, {
            loading: "Making user staff...",
            success: "User is now Staff",
            error: "Failed to Make Staff",
        });
    };



    useEffect(getUserInfo, []);

    let formData = {
        username,
        firstName,
        setFirstNameHasError,
        surname,
        setSurnameHasError,
        company,
        setCompanyHasError,
    };

    if (!user) {
        return <AlternateLoader />;
    }

    return (
        <div className="w-full">
            {!isTokenSet && (
                <Breadcrumbs
                    crumbs={[
                        {
                            name: "Home",
                            to: "/",
                        },
                        {
                            name: "User Management",
                            to: "/users",
                        },
                        {
                            name: user.username,
                            to: `/user/admin/:id`,
                        },
                    ]}
                />
            )}
            <Container>
                <Helmet>
                    <title>Edit Profile: {`${firstName} ${surname}`}</title>
                </Helmet>
                <Panel>
                    <h1 className="text-xl font-bold mb-4">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faUserAlt}
                            className="text-gray-600 mr-4"
                        />
                        {`${firstName} ${surname}`}
                    </h1>
                    <div className="grid grid-cols-3 gap-4 mb-4">
                        <div>
                            <StackedLabel label="Email" value={user.email} />
                        </div>
                        <div>
                            <StackedLabel
                                label="Username"
                                value={user.username}
                            />
                        </div>
                        <div>
                            <StackedLabel
                                label="MFA Enabled"
                                value={
                                    <div className="flex space-x-4 items-center">
                                        <div>
                                            <FontAwesomeIcon
                                                fixedWidth
                                                icon={faCircle}
                                                className={
                                                    user.otpenabled
                                                        ? "text-green-500 mr-1"
                                                        : "text-red-500 mr-1"
                                                }
                                            />
                                            {user.otpenabled ? "Yes" : "No"}
                                        </div>
                                        <div>
                                            <Tippy
                                                placement="top"
                                                content="Reset OTP"
                                            >
                                                <div>
                                                    <Button
                                                        color="yellow"
                                                        disabled={
                                                            !user.otpenabled
                                                        }
                                                        onClick={() =>
                                                            setConfirmModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSync}
                                                        />
                                                    </Button>
                                                </div>
                                            </Tippy>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div>
                            {/* First name input */}
                            <label
                                htmlFor="firstName"
                                className="text-gray-600 block mb-1"
                            >
                                First name
                            </label>
                            <Input
                                ariaLabel="First name"
                                name="firstName"
                                id="firstName"
                                type="text"
                                required
                                autoComplete="off"
                                placeholder="First name..."
                                onChange={setFirstName}
                                value={firstName}
                                hasError={firstNameHasError}
                            />
                            {firstNameHasError && (
                                <div className="px-1 mt-1 text-sm font-semibold text-red-500">
                                    First name is required!
                                </div>
                            )}
                        </div>
                        <div>
                            {/* Surname input */}
                            <label
                                htmlFor="surname"
                                className="text-gray-600 block mb-1"
                            >
                                Surname
                            </label>
                            <Input
                                aria-label="Surname"
                                name="surname"
                                id="surname"
                                type="text"
                                required
                                autoComplete="off"
                                placeholder="Surname..."
                                onChange={setSurname}
                                value={surname}
                                hasError={surnameHasError}
                            />
                            {surnameHasError && (
                                <div className="px-1 mt-1 text-sm font-semibold text-red-500">
                                    Surname is required!
                                </div>
                            )}
                        </div>

                        <div>
                            {/* Company input */}
                            <label
                                htmlFor="company"
                                className="text-gray-600 block mb-1"
                            >
                                Company
                            </label>
                            <Input
                                aria-label="Surname"
                                name="company"
                                id="company"
                                type="text"
                                required
                                autoComplete="off"
                                placeholder="Company..."
                                onChange={setCompany}
                                value={company}
                                hasError={companyHasError}
                            />
                            {companyHasError && (
                                <div className="px-1 mt-1 text-sm font-semibold text-red-500">
                                    Company is required!
                                </div>
                            )}
                        </div>

                        <div>
                            <StackedLabel
                                label="Time Format"
                                value={
                                    <pre className="font-mono">
                                        {user.timeformat}
                                    </pre>
                                }
                            />
                        </div>
                        <div>
                            <StackedLabel
                                label="Timezone"
                                value={user.timezone}
                            />
                        </div>
                        <div>
                            <StackedLabel
                                label="Last Login"
                                value={user.lastLogin}
                            />
                        </div>

                    </div>
                    <div className="flex space-x-2">
                        <Button
                            onClick={() =>
                                validateForm(formData) &&
                                submitForm(formData, props)
                            }
                        >
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                            Save changes
                        </Button>
                        <Button color="gray" isLink to={(props.token.claims.includes("user-admin") && username != props.token.profile.username) ? "/users" : "/user/profile"} >
                            Cancel
                        </Button>
                    </div>
                </Panel>

                {(props.token.claims.includes("user-admin") ||
                    props.token.claims.includes("admins")) && (
                        <>
                        <Panel>
                            <h1 className="text-xl font-bold mb-4">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faChalkboard}
                                    className="text-gray-600 mr-4"
                                />
                                LDAP Group Membership
                                {!user?.staffgroups && props.token.claims.includes("admins")?
                                  <div style={{float: "right"}}> <Button color="red" onClick={() => makestaff()} disabled={!props.token.claims.includes("admins")}>Make Staff</Button></div>
                                  : <></>
                                }
                                </h1>
                            <LdapGroupMembership
                                staffGroups={user?.staffgroups}
                                studentGroups={user?.studentgroups}
                                user={user}
                                refreshUser={getUserInfo}
                            />
                        </Panel>
                        { user?.staffgroups && user?.staffgroups?.find((membership) => membership.group === "guest-instructor").member ?
                        <Panel>
                            <h1 className="text-xl font-bold mb-4">
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={faChalkboard}
                                    className="text-gray-600 mr-4"
                                />
                                Guest Instructor Curriculums
                            </h1>
                            <GuestInstructorCurriculums
                              user={user}
                              refreshUser={getUserInfo}
                              allCurriculums={curriculums}
                            />
                        </Panel>
                        : <></>
                    }
                        </>
                    )}

                <Panel>
                    <h1 className="text-xl font-bold mb-4">
                        <FontAwesomeIcon
                            fixedWidth
                            icon={faChalkboard}
                            className="text-gray-600 mr-4"
                        />
                        Courses Taken
                    </h1>
                    <CoursesTaken coursesTaken={coursesTaken} />
                </Panel>

                {isInstructor && (
                    <Panel>
                        <h1 className="text-xl font-bold mb-4">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faChalkboardTeacher}
                                className="text-gray-600 mr-4"
                            />
                            Courses Taught
                        </h1>
                        <CoursesTaught coursesTaught={coursesTaught} />
                    </Panel>
                )}
                <LabVMTable token={props.token} userid={user.username}/>
            </Container>

            <Modal
                isOpen={confirmModalOpen}
                setIsOpen={setConfirmModalOpen}
                closeWording={"Cancel"}
                headerContent={"Are you sure?"}
                footerContent={getConfirmButton()}
            >
                This action will remove the OTP tokens stored for this user and
                force them to enrol again. Are you sure you want to continue?
            </Modal>
        </div>
    );
}

export default AdminUserProfileEdit;
