import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../Modal/Modal";
import Input from "../../Form/Input";
import Tippy from "@tippyjs/react";
import Button from "../../Button/Button";

function CreateVmModal({
    isCreateVmModalOpen,
    setIsCreateVmModalOpen,
    refreshVms,
    user_id,
    token,
}) {
    const [templates, setTemplates] = useState(null);
    const [selectedType, setSelectedType] = useState("Victim");
    const [selectedImage, setSelectedImage] = useState(null);
    const [name, setName] = useState("");
    const [nameHasError, setNameHasError] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [showDevVMS, setShowDevVMS] = useState(false);
    const getTemplates = () => {
        axios
            .get(`${process.env.REACT_APP_API}/vm_templates.json`)
            .then(({ data }) => {
                setTemplates(data);
            })
            .catch((error) => console.error(error));
    };
    useEffect(() => {
        if (isCreateVmModalOpen && !templates) {
            getTemplates();
        }
        setSelectedType("Victim");
        setSelectedImage(null);
        setName("");
        setShowDevVMS(false);
    }, [isCreateVmModalOpen]);

    const validateForm = () => {
        let errors = [];
        let validated = true;

        if (!selectedType) {
            errors.push(<li key={1}>VM Type is required</li>);
            validated = false;
        }

        if (!selectedImage) {
            errors.push(<li key={2}>Image is required</li>);
            validated = false;
        }

        if (!selectedImage) {
            errors.push(<li key={3}>Name is required</li>);
            validated = false;
            setNameHasError(true);
        } else {
            setNameHasError(false);
        }

        if (!validated) {
            toast.error((t) => (
                <div>
                    <div>Please address the following errors:</div>
                    <div className="text-sm mt-1">
                        <ul className="list-inside list-disc">{errors}</ul>
                    </div>
                </div>
            ));
        }

        return validated;
    };

    const submitForm = () => {
        setIsCreating(true);
        var this_user_id = ""
        if (user_id && token.claims.includes("user-admin")) { this_user_id = user_id }

        let createPromise = axios
            .post(`${process.env.REACT_APP_API}/vms.json`, {
                description: name,
                vm_template_id: selectedImage,
                user_id: this_user_id,
            })
            .then(() => refreshVms())
            .then(() => setIsCreating(false))
            .then(() => setIsCreateVmModalOpen(false));

        toast.promise(createPromise, {
            loading: "Creating VM...",
            success: "Created VM",
            error: "Failed to create VM",
        });
    };

    return (
        <Modal
            isOpen={isCreateVmModalOpen}
            setIsOpen={setIsCreateVmModalOpen}
            capMaxSize={false}
            headerContent={"Create VM"}
            footerContent={
                <Button
                    disabled={isCreating || !selectedImage || !name}
                    color="green"
                    onClick={() => validateForm() && submitForm()}
                >
                    {isCreating ? (
                        <FontAwesomeIcon spin icon={faCircleNotch} />
                    ) : (
                        "Create"
                    )}
                </Button>
            }
        >
            {templates ? (
                <div className="relative">
                    {isCreating && (
                        <div className="absolute inset-0 flex flex-col space-y-4 justify-center items-center z-10 bg-gray-100 opacity-60">
                            <FontAwesomeIcon
                                className="text-gray-600"
                                size="3x"
                                spin
                                icon={faCircleNotch}
                            />
                            <div>This may take a minute or two...</div>
                        </div>
                    )}
                    <p className="text-gray-600 block mb-1">VM Type*</p>
                    <div className="grid grid-cols-4 grid-rows-1 mb-4">
                        {templates.vm_types.map((type, index) => (
                            <div
                                key={index}
                                onClick={() => setSelectedType(type.vmtype)}
                                className={`text-center px-3 py-2 cursor-pointer border ${selectedType === type.vmtype
                                    ? "bg-teal-100 border-teal-300"
                                    : "border-gray-300"
                                    }`}
                            >
                                {type.vmtype}
                            </div>
                        ))}
                    </div>
                    {token.claims.includes("vm-admin") ?
                        <div className="relative">
                            <p className="text-gray-600 block mb-1">Show Dev VMS</p>
                            <input
                                aria-Label="DevVMS"
                                type="checkbox"
                                id="devvms"
                                value={showDevVMS}
                                onChange={(e) => setShowDevVMS(e.target.checked)}
                            />
                        </div>
                        : <></>
                    }
                    <p className="text-gray-600 block mb-1">Image*</p>
                    <div className="grid grid-cols-4 gap-4 grid-rows-1 mb-4">
                        { [...templates.vm_templates, ...templates.vm_devs].filter(
                            (item) => (item.vm_type === selectedType &&
                                item.devvm === showDevVMS &&
                                item.enabled === true )
                        ).length ? (
                            [...templates.vm_templates, ...templates.vm_devs]
                                .filter((item) => (item.vm_type === selectedType &&
                                    item.devvm === showDevVMS &&
                                    item.enabled === true)
                                ).map((image, index) => (
                                    <Tippy
                                        key={index}
                                        placement="bottom"
                                        content={image.description}
                                    >
                                        <div
                                            key={index}
                                            onClick={() =>
                                                setSelectedImage(image.id)
                                            }
                                            className={`flex flex-col space-y-4 items-center justify-center h-28 text-center px-3 py-2 cursor-pointer border ${selectedImage === image.id
                                                ? "bg-teal-100 border-teal-300"
                                                : "border-gray-300"
                                                }`}
                                        >
                                            <div>
                                                <img
                                                    src={
                                                        image.icon
                                                            ? `/images/${image.icon
                                                            }.${image.icon ===
                                                                "kali"
                                                                ? "svg"
                                                                : "png"
                                                            }`
                                                            : "/images/unknown.png"
                                                    }
                                                    alt="Image Logo"
                                                    className="h-10 w-10"
                                                />
                                            </div>
                                            <div className="text-sm">
                                                {image.name}
                                            </div>
                                        </div>
                                    </Tippy>
                                ))
                        ) : (
                            <div className="col-span-4 p-4 text-center text-gray-600">
                                No {selectedType} templates found.
                            </div>
                        )}
                    </div>
                    <p className="text-gray-600 block mb-1">Name*</p>
                    <Input
                        ariaLabel="Name"
                        name="name"
                        id="name"
                        type="text"
                        required
                        autoComplete="off"
                        placeholder="Name..."
                        onChange={setName}
                        value={name}
                        hasError={nameHasError}
                    />
                    {nameHasError && (
                        <p className="text-red-500 mt-1 text-xs font-semibold">
                            Name is required.
                        </p>
                    )}
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center space-y-4">
                    <FontAwesomeIcon
                        size="3x"
                        spin
                        icon={faCircleNotch}
                        className="text-gray-600"
                    />
                    <div className="text-gray-600">Loading VM Templates...</div>
                </div>
            )}
        </Modal>
    );
}

export default CreateVmModal;
