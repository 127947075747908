import React from "react";
import Link from "../../Link/Link";
import Tippy from "@tippyjs/react";

function CourseLink({ value, data }) {
    return (
        <Tippy placement="top" content={`${data.title || data.curriculumName || data.curriculum_title}`}>
            <div>
                <Link to={`/student/course_resources/${data.id || data.course_id || data.course.id}`}>{value}</Link>
            </div>
        </Tippy>
    );
}

export default CourseLink;
