import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import AlternateLoader from "../../components/Loader/AlternateLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarAlt,
    faChalkboardTeacher,
    faDownload,
    faFlask,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import GoToCourseResource from "../../components/CellRenderers/CourseResources/GoToCourseResource";
import CourseStatus from "../../components/CellRenderers/CourseResources/CourseStatus";
import CourseLink from "../../components/Pages/Users/CourseLink";

function CourseResources(props) {
    const [courseResources, setCourseResources] = useState(null);

    const getCourseResources = () => {
        let getPromise = axios
            .get(`${process.env.REACT_APP_API}/student/course_resources.json`)
            .then(({ data }) => setCourseResources(data));

        toast.promise(getPromise, {
            success: "Retrieved course resources",
            error: "Failed retrieving course resources",
            loading: "Loading...",
        });
    };

    useEffect(() => {
        getCourseResources();
    }, []);

    const gridOptions = {
        getRowNodeId(data) {
            return data.course_id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Course Name",
                field: "curriculum_title",
                cellRendererFramework: CourseLink
            },
            {
                headerName: "Start Date",
                field: "startDate",
                width: 150,
                suppressSizeToFit: true,
                sort: "desc",
            },
            {
                headerName: "Duration",
                field: "duration",
                width: 70,
            },
            {
                headerName: "Status",
                field: "",
                width: 150,
                suppressSizeToFit: true,
                cellRendererFramework: CourseStatus,
            },
            {
                headerName: "Actions",
                field: "course_id",
                cellRendererFramework: GoToCourseResource,
                cellRendererParams: {
                    token: props.token,
                },
            },
        ],
    };

    return (
        <div className="w-full">
            <Helmet>
                <title>Students</title>
            </Helmet>
            <Container>
                <Panel>
                    <h1 className="text-xl font-bold">
                        Student Course Resources
                    </h1>
                    <p className="text-sm text-gray-600">
                        This material is provided for the individual, internal
                        use of the student attending authorized Trellix
                        training, and may not be used for any other purpose or
                        disseminated to third parties. This material constitutes
                        confidential information of Trellix, and is subject to
                        non-disclosure obligations.
                    </p>
                    <p className="text-right text-gray-600 text-sm mt-2">
                        &copy; Trellix, Inc. 2019 - {moment().format("YYYY")}
                    </p>
                </Panel>

                {courseResources ? (
                    courseResources.students.length > 0 ? (
                        <Panel>
                            <h2 className="text-lg font-bold mb-4">
                                Assigned Courses
                            </h2>

                            <Table
                                gridOptions={gridOptions}
                                rowData={courseResources.students}
                            />
                        </Panel>
                    ) : (
                        <div className="text-center p-20 text-xl text-gray-600">
                            You have not been enrolled in any courses.
                        </div>
                    )
                ) : (
                    <AlternateLoader />
                )}
            </Container>
        </div>
    );
}

export default CourseResources;
