import loader from "../../images/loader.gif";

function Loader(props) {
    return (
        <div className="w-full text-center py-10">
            <img src={loader} alt="Loading..." className="mx-auto h-40" />
            {props.text && (
                <div className="text-gray-600 text-lg flex justify-center space-x-1">
                    <div>{props.text}</div>
                    <div className="w-6">
                        <div className="text-left loading-text"></div>
                    </div>
                </div>
            )}
        </div>
    );
}

Loader.defaultProps = {
    text: "Loading",
};

export default Loader;
