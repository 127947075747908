import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Input from "../../components/Form/Input";
import Button from "../../components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle,
    faLock,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast from "react-hot-toast";

function ChangePassword(props) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordDoesntMeetRequirements, setPasswordDoesntMeetRequirements] =
        useState(false);
    const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
    const [notAllFieldsPopulated, setNotAllFieldsPopulated] = useState(false);

    const validateInput = () => {
        if (newPassword !== confirmNewPassword) {
            setPasswordsDontMatch(true);
            return false;
        } else {
            setPasswordsDontMatch(false);
        }

        if (!currentPassword || !newPassword || !confirmNewPassword) {
            setNotAllFieldsPopulated(true);
            return false;
        } else {
            setNotAllFieldsPopulated(false);
        }

        if (newPassword.length < 8) {
            setPasswordDoesntMeetRequirements(true);
            return false;
        } else {
            setPasswordDoesntMeetRequirements(false);
        }

        if (
            !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i.test(
                newPassword
            )
        ) {
            setPasswordDoesntMeetRequirements(true);
            return false;
        } else {
            setPasswordDoesntMeetRequirements(false);
        }

        return true;
    };

    const changePassword = () => {
        let updatePromise = axios
            .post(`${process.env.REACT_APP_API}/users/update_password.json`, {
                currentpassword: currentPassword,
                password: newPassword,
                password2: confirmNewPassword,
            })
            .then(({ data }) => console.log(data));

        toast.promise(updatePromise, {
            loading: "Updating password",
            success: "Updated password",
            error: "Failed to update password",
        });
    };

    return (
        <div className="w-full">
            <Helmet>
                <title>Change Password | Users</title>
            </Helmet>
            <Container>
                <Panel>
                    <h1 className="font-extrabold text-xl">Change Password</h1>
                    <p className="mb-4">
                        Use the form below to change your password. Please note
                        that passwords are required to meet the following
                        criteria; a minimum of eight characters and at least one
                        of the following: Uppercase letter, lowercase letter,
                        number & special character.
                    </p>
                    <div className="flex flex-col space-y-4 max-w-sm">
                        <div>
                            <label
                                htmlFor="currentPassword"
                                className="text-gray-600 block mb-1"
                            >
                                Current Password
                            </label>
                            <Input
                                ariaLabel="Current Password"
                                name="currentPassword"
                                id="currentPassword"
                                type="password"
                                required
                                autoComplete="off"
                                placeholder="Current password..."
                                onChange={setCurrentPassword}
                                value={currentPassword}
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="newPassword"
                                className="text-gray-600 block mb-1"
                            >
                                New Password
                            </label>
                            <Input
                                ariaLabel="New Password"
                                name="newPassword"
                                id="newPassword"
                                type="password"
                                required
                                autoComplete="off"
                                placeholder="New password..."
                                onChange={setNewPassword}
                                value={newPassword}
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="confirmNewPassword"
                                className="text-gray-600 block mb-1"
                            >
                                Confirm New Password
                            </label>
                            <Input
                                ariaLabel="Confirm New Password"
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                type="password"
                                required
                                autoComplete="off"
                                placeholder="Confirm New password..."
                                onChange={setConfirmNewPassword}
                                value={confirmNewPassword}
                            />
                        </div>
                        {passwordsDontMatch && (
                            <div className="text-red-500 font-semibold">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="mr-2"
                                />
                                Passwords don't match.
                            </div>
                        )}

                        {passwordDoesntMeetRequirements && (
                            <div className="text-red-500 font-semibold">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="mr-2"
                                />
                                Password does not meet requirements.
                            </div>
                        )}

                        {notAllFieldsPopulated && (
                            <div className="text-red-500 font-semibold">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className="mr-2"
                                />
                                All fields are required.
                            </div>
                        )}
                    </div>
                    <div className="mt-5 flex space-x-2">
                        <Button
                            color="teal"
                            onClick={() => validateInput() && changePassword()}
                        >
                            <FontAwesomeIcon icon={faLock} className="mr-2" />
                            Change Password
                        </Button>
                        <Button color="gray" isLink to="/user/profile">
                            Cancel
                        </Button>
                    </div>
                </Panel>
            </Container>
        </div>
    );
}

export default ChangePassword;
