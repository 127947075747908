import Tippy from "@tippyjs/react";
import React from "react";

function ResourceLink(props) {
    console.log(props.data);
    return (
        <Tippy placement="top" content={props.data.material_type === 'Videos' ? props.data.name : props.data.filename}>
            <a
                href={`${process.env.REACT_APP_API}/resource_link/${props.data.id}`}
                target="_blank"
                className="text-teal-500 hover:text-teal-700 cursor-pointer"
            >
                {props.value}
            </a>
        </Tippy>
    );
}

export default ResourceLink;
