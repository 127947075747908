import moment from "moment";
import React from "react";
import Badge from "../../Badge/Badge";

function CourseStatus(props) {
    if(moment(props.data.startDate).add(props.data.duration, 'days').isBefore(moment())) {
        return <Badge color="green" text="Complete" />;
    }

    if (moment().isSameOrAfter(moment(props.data.startDate))) {
        return <Badge color="orange" text="In progress" />;
    }

    return <Badge color="teal" text="Not started" />;
}

export default CourseStatus;
