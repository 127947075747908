import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

import Button from "../Button/Button";
import { CSVLink } from "react-csv";


function RosterExport({ roster, dateArray, title }) {

  const csvArray = (roster) => {
    if (roster) {
      return roster.map((student) => {
        let st = {
          student_id: student.student_id,
          user_id: student.user_id,
          email: student.email,
          name: student.student_name,
          company: student.company_name,
        }
        student.roster.map((r) => {
          st[`${r.date} AM`] = r.am ? r.am : false;
          st[`${r.date} PM`] = r.pm ? r.pm : false;
        })
        return st;
      });
    } else {
      return [];
    }
  };

  return (
    <Button color="green">
      <CSVLink
        data={csvArray(roster)}
        filename={`${dateArray[0]} ${title}.csv`}
        className="btn btn-primary"
      >      <FontAwesomeIcon
          icon={faFileExport}
        /> Export as CSV
      </CSVLink>
      </Button>
  )

}

export default RosterExport;