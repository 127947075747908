import React, { useState } from "react";
import { Helmet } from "react-helmet";
import ToastComponent from "../../Toast/ToastComponent";
import Button from "../../Button/Button";
import logo from "../../../images/Trellix_LOGO_Color_Graphite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faLock,
    faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axios from "axios";

function PasswordReset(props) {
    /**
     * Extract token from URL
     */
    const parts = window.location.pathname.split("/");
    const token = parts.pop() || parts.pop();

    /**
     * Set state params for passwords
     */
    const [somethingWentWrong, setSomethingWentWrong] = useState(false);
    const [updatedPassword, setUpdatedPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const validatePasswords = () => {
        let errors = [];
        let validated = true;
        /**
         * Password checks
         */
        if (!password) {
            errors.push(<li key={1}>Password is required.</li>);
            validated = false;
        }

        if (password && password.length < 8) {
            errors.push(
                <li key={2}>Password must be at least eight (8) characters.</li>
            );
            validated = false;
        }

        if (
            password &&
            !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i.test(
                password
            )
        ) {
            errors.push(<li key={3}>Password does not meet requirements.</li>);
            validated = false;
        }

        /**
         * Confirm Password checks
         */
        if (!confirmPassword) {
            errors.push(<li key={4}>Confirm Password is required.</li>);
            validated = false;
        }

        if (confirmPassword !== password) {
            errors.push(<li key={5}>Passwords do not match.</li>);
            validated = false;
        }

        if (!validated) {
            toast.error((t) => (
                <div>
                    <div>Please address the following errors:</div>
                    <div className="text-sm mt-1">
                        <ul className="list-inside list-disc">{errors}</ul>
                    </div>
                </div>
            ));
        }

        return validated;
    };

    const submitForm = () => {
        axios
            .post(`${process.env.REACT_APP_API}/users/update_password.json`, {
                token: token,
                password: password,
                password2: confirmPassword,
            })
            .then(({ data }) => {
                if (data.status === "Password updated") {
                    setUpdatedPassword(true);
                    toast.success("Updated password");
                    setTimeout(() => {
                        window.location = "/login";
                    }, 3000);
                } else {
                    toast.error(data.status);
                    setSomethingWentWrong(true);
                }
            });
    };

    if (somethingWentWrong) {
        return (
            <div className="font-body bg-gray-100 min-h-screen flex justify-center items-center">
                <Helmet title="Updated Password | Training Labs 2.0 | Trellix" />

                <ToastComponent />

                <div className="max-w-lg w-full py-12 px-6">
                    <img
                        src={logo}
                        className="mx-auto h-12 w-auto"
                        alt="Trellix Logo"
                    />
                    <p className="mt-2 text-lg text-center text-gray-700">
                        Reset Password | Training Labs 2.0
                    </p>
                    <div className="mt-5 flex flex-col space-y-5 text-center">
                        <FontAwesomeIcon
                            size="3x"
                            icon={faTimesCircle}
                            className="text-red-500 mx-auto"
                        />
                        <p className="text-red-500">
                            Something went wrong, please refresh and try again.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (updatedPassword) {
        return (
            <div className="font-body bg-gray-100 min-h-screen flex justify-center items-center">
                <Helmet title="Updated Password | Training Labs 2.0 | Trellix" />

                <ToastComponent />

                <div className="max-w-lg w-full py-12 px-6">
                    <img
                        src={logo}
                        className="mx-auto h-12 w-auto"
                        alt="Trellix Logo"
                    />
                    <p className="mt-2 text-lg text-center text-gray-700">
                        Reset Password | Training Labs 2.0
                    </p>
                    <div className="mt-5 flex flex-col space-y-5 text-center">
                        <FontAwesomeIcon
                            size="3x"
                            icon={faCheckCircle}
                            className="text-green-500 mx-auto"
                        />
                        <p className="text-green-500">
                            Password successfully reset. Redirecting...
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="font-body bg-gray-100 min-h-screen flex justify-center items-center">
            <Helmet title="Reset Password | Training Labs 2.0 | Trellix" />

            <ToastComponent />

            <div className="max-w-lg w-full py-12 px-6">
                <img
                    src={logo}
                    className="mx-auto h-12 w-auto"
                    alt="Trellix Logo"
                />
                <p className="mt-2 text-lg text-center text-gray-700">
                    Reset Password | Training Labs 2.0
                </p>
                <form
                    className="mt-5"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validatePasswords() && submitForm();
                    }}
                >
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input
                                aria-label="Password"
                                name="password"
                                type="password"
                                required=""
                                className="border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-4 py-3 border text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </div>
                        <div className="-mt-px relative">
                            <input
                                aria-label="Confirm Password"
                                name="confirmPassword"
                                type="password"
                                required=""
                                className="border-gray-300 placeholder-gray-500 appearance-none rounded-none relative block w-full px-4 py-3 border text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                                placeholder="Confirm Password"
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                                value={confirmPassword}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <Button block size="md" type="submit" color="gray">
                            <div className="sm:text-sm">
                                <span className="absolute left-0 inset-y pl-3">
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className="text-gray-600"
                                    />
                                </span>
                                Reset Password
                            </div>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PasswordReset;
