import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "../../Button/Button";
import Input from "../../Form/Input";
import Modal from "../../Modal/Modal";
import Tippy from "@tippyjs/react";
import Table from "../../Table/Table";
import StudentActions from "../../CellRenderers/Vms/StudentActions";
import TemplateName from "../../CellRenderers/Vms/TemplateName";
import LastBoot from "../../CellRenderers/Vms/LastBoot";
import VmTypeDetails from "../../CellRenderers/Vms/VmTypeDetails";
import StatusIndicator from "../../CellRenderers/Vms/StatusIndicator";

function VmListSummary(props) {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [editName, setEditName] = useState(props.data.description);

  const gridOptions = {
    getRowNodeId(data) {
        return data.id;
    },
    paginationPageSize: 15,
    columnDefs: [
        {
            headerName: "Name",
            field: "description",
        },
        {
            headerName: "Type",
            field: "vm_type",
            sort: "asc",
            width: 250,
            cellRendererFramework: VmTypeDetails,
        },
        {
            headerName: "Status",
            field: "status",
            cellRendererFramework: StatusIndicator,
        },
        {
            headerName: "Last Boot",
            field: "lastboot",
            cellRendererFramework: LastBoot,
        },
        {
            headerName: "IP Address",
            field: "ipaddress",
        },
        {
            headerName: "Actions",
            field: "full_status",
            cellRendererFramework: StudentActions,
            cellRendererParams: {
              refreshVms: props.getCourse,
            }
        },
    ],
};


  return (
    <div>        
          <div
            className="text-teal-500 hover:text-teal-700 cursor-pointer"
            onClick={() => setInfoModalOpen(true)}
          >
            {props.data.vms.length}
          </div>
      <Modal
        isOpen={infoModalOpen}
        setIsOpen={setInfoModalOpen}
        headerContent={`${props.data.name} (${props.data.uid}) VMS`}
        capMaxSize={false}
      >
        <div>
        <Table gridOptions={gridOptions} rowData={props.data.vms} />
        </div>
      </Modal>
    </div>
  );
}

VmListSummary.defaultProps = {
  showModal: false,
};

export default VmListSummary;
