import React from "react";
import axios from "axios";
import moment from "moment";
import { useState, useRef } from "react";
import toast from "react-hot-toast";
import { useEffect } from "react/cjs/react.development";
import Toggle from "../../Toggle/Toggle";

class CourseRosterToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            amIsEnabled:
                this.props.data.roster.find(
                    (item) =>
                        item.date === this.props.date
                )?.am === true,
            pmIsEnabled:
                this.props.data.roster.find(
                    (item) =>
                        item.date === this.props.date 
                )?.pm === true,
        };
    }

    toggleAction(field) {
        /**
         * Search for a roster entry for this user for this date
         */
        const entryExists =
            this.props.data.roster.find(
                (item) =>
                    item.date === this.props.date &&
                    item.user_id === this.props.data.student_id
            ) !== undefined;

        /**
         * If there is no roster entry for this user, for this date,
         * then we will create a new one. If there is a roster entry,
         * we will update the entry.
         */
        if (entryExists) {
            this.updateRosterEntry(field);
        } else {
            this.createRosterEntry(field);
        }
    }

    createRosterEntry(field) {
        let submitPromise = axios
            .post(`${process.env.REACT_APP_API}/rosters.json`, {
                course_id: this.props.data.course_id,
                user_id: this.props.data.student_id,
                date: this.props.date,
                [field]: this.state[`${field}IsEnabled`],
            })
            .then(() => this.props.getCourse());

        console.log("Submitpromis", submitPromise);
        toast.promise(submitPromise, {
            success: "Added roster entry",
            error: "Failed to add roster entry",
            loading: "Adding roster entry...",
        });
    }

    updateRosterEntry(field) {
        const roster_id = this.props.data.roster.find(
            (item) =>
                item.date === this.props.date &&
                item.user_id === this.props.data.student_id
        ).id;

        let togglePromise = axios
            .put(`${process.env.REACT_APP_API}/rosters/${roster_id}.json`, {
                [field]: this.state[`${field}IsEnabled`],
            })
            .then(() => this.props.getCourse());

        toast.promise(togglePromise, {
            loading: "Toggling...",
            success: `Toggled ${field.toUpperCase()} value`,
            error: `Failed to toggle ${field.toUpperCase()} value`,
        });
    }

    render() {
        return (
            <div
                className="flex justify-between space-x-2"
                style={{ width: 82 }}
            >
                <div>
                    <Toggle
                        size="sm"
                        isEnabled={this.state.amIsEnabled}
                        onClick={() =>
                            this.setState(
                                { amIsEnabled: !this.state.amIsEnabled },
                                () => this.toggleAction("am")
                            )
                        }
                    />
                </div>
                <div>
                    <Toggle
                        size="sm"
                        isEnabled={this.state.pmIsEnabled}
                        onClick={() =>
                            this.setState(
                                { pmIsEnabled: !this.state.pmIsEnabled },
                                () => this.toggleAction("pm")
                            )
                        }
                    />
                </div>
            </div>
        );
    }
}

export default CourseRosterToggle;
