import { faEye, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import React from "react";

function RenderResources({ title, resources, curriculumName }) {
    let resourcesArray = resources
        .filter((item) => item.material_type === title)
        .filter((item) => item.name !== `${curriculumName}.zip`)
        .map((item, index) => (

            <div key={index} className="flex my-2">
                <div className="w-4">
                    <Tippy
                        placement="top"
                        content={
                            !item.internalOnly
                                ? "Accessible publicly"
                                : "Only accessible through lab network"
                        }
                    >
                        <span>
                            <FontAwesomeIcon
                                fixedWidth
                                icon={!item.internalOnly ? faEye : faLock}
                                className={
                                    !item.internalOnly
                                        ? "text-gray-400"
                                        : "text-red-500"
                                }
                            />
                        </span>
                    </Tippy>
                </div>
                <div className="flex-grow pl-3">
                    <Tippy placement="top" content={ item.material_type === 'Videos' ? item.name : item.filename}>
                        <a
                            href={`${process.env.REACT_APP_API}/resource_link/${item.id}`}
                            className="text-teal-500 hover:text-teal-700"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {item.name}
                        </a>
                    </Tippy>
                </div>
            </div>
        ));

    return (
        <div>
            <h3 className="font-semibold text-lg mb-2">{title}</h3>
            <div className="text-sm">
                {resourcesArray.length ? (
                    resourcesArray
                ) : (
                    <div>There are no resources for this course.</div>
                )}
            </div>
        </div>
    );
}

export default RenderResources;
