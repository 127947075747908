import { PropertyKeys } from "ag-grid-community";
import React, { useState, useEffect } from "react";
import Table from "../../Table/Table";
import Link from "../../Link/Link";

function ListCurriculumRoles(props) {

  const gridOptions = {
    getRowNodeId(data) {
        return data.id;
    },
    paginationPageSize: 15,
    columnDefs: [
        {
            headerName: "Role",
            field: "role",
        },
    ],
};

  return (
    <Table
      gridOptions={gridOptions}
      rowData={props.studentRoles}
      />
  );

}

export default ListCurriculumRoles;