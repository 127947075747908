import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Alert from "../../components/Alert/Alert";
import Container from "../../components/Layout/Container";
import Loader from "../../components/Loader/Loader";
import { Link } from 'react-router-dom';
import Panel from "../../components/Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarWeek,
    faClipboard,
    faGraduationCap,
    faHandPaper,
    faPlusSquare,
    faStop,
    faUserTie,
    faDownload,
    faEdit,
    faSync,
    faClipboardList,
    faCalendarCheck,
    faWindowMaximize,
} from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import Tippy from "@tippyjs/react";
import TimingRow from "../../components/Pages/Course/TimingRow";
import Textarea from "../../components/Form/Textarea";
import Button from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import RenderResources from "../../components/Pages/Curriculum/RenderResources";
import toast from "react-hot-toast";
import AwaitingActions from "../../components/CellRenderers/Courses/AwaitingActions";
import EnroledActions from "../../components/CellRenderers/Courses/EnroledActions";
import RegisterLink from "../../components/CellRenderers/Courses/RegisterLink";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "../../components/Modal/Modal";
import CourseRosterModal from "../../components/Pages/Course/CourseRosterModal";
import Username from "../../components/CellRenderers/Users/Username";
import MDEditor from "@uiw/react-md-editor";
import Toggle from "../../components/Toggle/Toggle";
import VmListSummary from "../../components/CellRenderers/Vms/VmListSummary";

function CourseView(props) {
    const [courseInformation, setCourseInformation] = useState(null);
    const [enrolStudentsText, setEnrolStudentsText] = useState("");
    const [enroledStudents, setEnroledStudents] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [courseRosterModalOpen, setCourseRosterModalOpen] = useState(false);
    const [descShowMore, setDescShowMore] = useState(false);

    const getCourse = () => {
        axios
            .get(
                `${process.env.REACT_APP_API}/courses/${props.match.params.id}.json`
            )
            .then(({ data }) => {
                setCourseInformation(data);
            })
            .catch((error) => console.error(error));
    };

    const renderDownloadAllButton = (resources, curriculum_name) => {
        let resourceLink = resources.find(
            (item) => item.name === `${curriculum_name}.zip`
        );

        if (resourceLink) {
            return (
                <a
                    href={`${process.env.REACT_APP_API}/resource_link/${resourceLink.id}`}
                >
                    <Button color="green">
                        <FontAwesomeIcon icon={faDownload} className="mr-2" />
                        Download All
                    </Button>
                </a>
            );
        }

        return null;
    };

    /**
     * Render the confirm button inside the modal footer
     */
    const getConfirmButton = () => (
        <Button color="green" onClick={() => syncCompanyName()}>
            Confirm
        </Button>
    );

    const syncCompanyName = () => {
        let removePromise = axios
            .get(
                `${process.env.REACT_APP_API}/courses/update_users_company/${courseInformation.course.id}.json`
            )
            .then(({ data }) => {
                getCourse();
                setConfirmModalOpen(false);
            });

        toast.promise(removePromise, {
            loading: "Syncing company name...",
            success: "Sync was successful",
            error: "Failed to sync",
        });
    };

    /**
     * Get the course as soon as the component mounts
     */
    useEffect(getCourse, [props.match.params.id]);
    /**
     * Set up the gridOptions for the enroled users table
     */
    const enroledGridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Name",
                field: "name",
                cellRendererFramework: Username,
            },
            {
                headerName: "UID",
                field: "uid",
            },
            {
                headerName: "Company",
                field: "company",
            },
            {
                headerName: "Email",
                field: "email",
            },
            {
                headerName: "VM Count",
                field: "vms",
                cellRendererFramework: VmListSummary,
                cellRendererParams: {
                    getCourse: getCourse,
                }
            },
            {
                headerName: "Actions",
                field: "actions",
                cellRendererFramework: EnroledActions,
                cellRendererParams: {
                    getCourse: getCourse,
                    course: courseInformation,
                    token: props.token,
                },
            },
        ],
    };

    /**
     * Set up the gridOptions for the awaiting registration table
     */
    const awaitingGridOptions = {
        getRowNodeId(data) {
            return data.id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Email",
                field: "email",
            },
            {
                headerName: "Token",
                field: "token",
                cellRendererFramework: RegisterLink,
            },
            {
                headerName: "Created",
                field: "created_at",
            },
            {
                headerName: "Last Sent",
                field: "updated_at",
            },
            {
                headerName: "Actions",
                field: "action",
                cellRendererFramework: AwaitingActions,
                cellRendererParams: {
                    getData: getCourse,
                },
            },
        ],
    };

    const enrolStudents = () => {
        let enrolPromise = axios
            .post(`${process.env.REACT_APP_API}/tokens.json`, {
                token: {
                    email: enrolStudentsText,
                    course: props.match.params.id,
                },
            })
            .then(({ data }) => {
                setEnroledStudents(data.email);
            })
            .then(() => getCourse())
            .then(() => setEnrolStudentsText(""));

        toast.promise(enrolPromise, {
            loading: "Enroling...",
            success: "Enroled students",
            error: "Failed enroling students",
        });
    };

    const renderEmailPills = (text) => {
        const regex =
            /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
        const found = text.match(regex);
        const deduplicated = [...new Set(found)];

        if (!deduplicated) {
            return (
                <div className="text-gray-600">
                    Unable to find any email addresses in the provided text.
                </div>
            );
        }

        return deduplicated.map((email, index) => (
            <div className="py-1 px-3 mt-3 mr-3 rounded-full bg-teal-100 text-teal-700 text-xs font-bold">
                {email}
            </div>
        ));
    };

    return (
        <div className="w-full">
            <Helmet>
                <title>Courses</title>
            </Helmet>

            <Breadcrumbs
                noMarginBottom
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Course Management",
                        to: "/courses",
                    },
                    {
                        name: "View",
                        to: `/courses/:id`,
                    },
                ]}
            />

            {courseInformation ? (
                <div>
                    <div className="bg-teal-100 text-teal-700 w-full p-10 text-center">
                        <h1 className="font-extrabold text-2xl mb-4">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faGraduationCap}
                                className="mr-3"
                            />
                            {courseInformation.curriculum.title}
                        </h1>
                        <h2 className="text-lg font-semibold leading-3">
                            {courseInformation.course.customer_name}
                        </h2>
                    </div>
                    <div className="mt-4">
                        <Container>
                            <Panel>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8">
                                    <div>
                                        <MDEditor.Markdown source={descShowMore ? courseInformation.curriculum.description : courseInformation.curriculum.description.substring(0,
                                            courseInformation.curriculum.description.indexOf("#"))} height="10" />

                                        <p align="right" style={{ color: "blue" }} onClick={() => setDescShowMore(!descShowMore)}>{descShowMore ? "show less <<" : "show more >>"}</p>

                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <p className="text-lg font-semibold text-gray-700 mb-1">
                                                Instructor(s)
                                            </p>
                                            <div className="grid grid-cols-3 gap-4">
                                                {courseInformation.course_instructors.map(
                                                    (instructor, index) => (
                                                        <div key={index}>
                                                            {instructor.name}{" "}
                                                            {instructor.primary && (
                                                                <Tippy content="Primary">
                                                                    <div className="inline-block">
                                                                        <FontAwesomeIcon
                                                                            fixedWidth
                                                                            icon={
                                                                                faUserTie
                                                                            }
                                                                            className="text-teal-500 ml-1"
                                                                        />
                                                                    </div>
                                                                </Tippy>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                            <div>
                                                <p className="text-lg font-semibold text-gray-700 mb-1">
                                                    Course Type
                                                </p>
                                                <div>
                                                    {
                                                        courseInformation.course
                                                            .course_type_name
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <p className="text-lg font-semibold text-gray-700 mb-1">
                                                    Start Date & Duration
                                                </p>
                                                <div>
                                                    {`${courseInformation.course.startDate} for ${courseInformation.curriculum.duration} days`}
                                                </div>
                                            </div>

                                            <div>
                                                <p className="text-lg font-semibold text-gray-700 mb-1">
                                                    Timezone
                                                </p>
                                                <div>
                                                    {
                                                        courseInformation.course
                                                            .timeZone
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                <p className="text-lg font-semibold text-gray-700 mb-1">
                                                    Delivery
                                                </p>
                                                <div>
                                                    {
                                                        courseInformation.course
                                                            .delivery_method_name
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <p className="text-lg font-semibold text-gray-700 mb-1">
                                                Schedule
                                            </p>
                                            <div>
                                                {courseInformation.timings.map(
                                                    (day, index) => (
                                                        <TimingRow
                                                            key={index}
                                                            id={day.id}
                                                            dayNumber={
                                                                day.dayNumber
                                                            }
                                                            startTime={
                                                                day.startTime
                                                            }
                                                            endTime={
                                                                day.endTime
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                            <div className="mt-4">
                                                <a
                                                    href={`${process.env.REACT_APP_API}/course/ics?course=${courseInformation.course.id}`}
                                                >
                                                    <Button
                                                        block
                                                        color="indigo"
                                                        size="md"
                                                    >
                                                        <div className="relative text-center">
                                                            <div className="absolute top-0 left-0">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faCalendarWeek
                                                                    }
                                                                />
                                                            </div>
                                                            Download ICS
                                                        </div>
                                                    </Button>
                                                </a>
                                            </div>
                                            <div className="mt-4">
                                                <Button
                                                    block
                                                    isLink
                                                    to={`/courses/${courseInformation.course.id}/edit?redirectTo=/courses/${courseInformation.course.id}`}
                                                    color="teal"
                                                    size="md"
                                                >
                                                    <div className="relative text-center">
                                                        <div className="absolute top-0 left-0">
                                                            <FontAwesomeIcon
                                                                icon={faEdit}
                                                            />
                                                        </div>
                                                        Edit
                                                    </div>
                                                </Button>
                                            </div>
                                            <div className="mt-4">
                                                <CopyToClipboard
                                                    text={
                                                        courseInformation.course
                                                            .survey
                                                    }
                                                    onCopy={() =>
                                                        toast.success(
                                                            "Copied Survey URL to Clipboard"
                                                        )
                                                    }
                                                >
                                                    <Button
                                                        block
                                                        color="blue"
                                                        size="md"
                                                    >
                                                        <div className="relative text-center">
                                                            <div className="absolute top-0 left-0">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faClipboardList
                                                                    }
                                                                />
                                                            </div>
                                                            Copy Survey Link
                                                        </div>
                                                    </Button>
                                                </CopyToClipboard>
                                            </div>
                                            <div className="mt-4">
                                                <Link to={`/student/course_resources/${courseInformation.course.id}`} target="_blank">
                                                    <Button
                                                        block
                                                        color="indigo"
                                                        size="md"
                                                    >
                                                        <div className="relative text-center">
                                                            <div className="absolute top-0 left-0">
                                                                <FontAwesomeIcon icon={faWindowMaximize} />
                                                            </div>
                                                            View As Student
                                                        </div>
                                                    </Button>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Panel>

                            {courseInformation.curriculum.notes && (
                                <Panel>
                                    <p className="text-lg font-semibold text-gray-700 mb-1">
                                        Instructor Notes
                                    </p>
                                    <MDEditor.Markdown source={courseInformation.curriculum.notes} />
                                </Panel>
                            )}

                            <Panel>
                                <div className="grid grid-cols-4 grid-rows-1 gap-4 mb-4">
                                    <RenderResources
                                        title={"Guides"}
                                        resources={courseInformation.resources}
                                    />
                                    <RenderResources
                                        title={"Lab Materials"}
                                        resources={courseInformation.resources}
                                    />
                                    <RenderResources
                                        title={"Instructor"}
                                        resources={courseInformation.resources}
                                        curriculumName={courseInformation.curriculum.curriculum_name}
                                    />
                                    <RenderResources
                                        title={"Videos"}
                                        resources={courseInformation.resources}
                                        curriculumName={courseInformation.curriculum.curriculum_name}
                                    />

                                </div>
                                <div className="text-right">
                                    {renderDownloadAllButton(
                                        courseInformation.resources,
                                        courseInformation.curriculum
                                            .curriculum_name
                                    )}
                                </div>
                            </Panel>

                            <Panel>
                                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                                    <div>
                                        <div className="flex flex-col h-full">
                                            <p className="text-lg font-semibold text-gray-700 mb-1">
                                                Shipping Address
                                            </p>
                                            {courseInformation.course
                                                .shippingAddress ? (
                                                <CopyToClipboard
                                                    text={
                                                        courseInformation.course
                                                            .shippingAddress
                                                    }
                                                    onCopy={() =>
                                                        toast.success("Copied")
                                                    }
                                                >
                                                    <div className="flex-grow whitespace-pre-wrap break-words font-mono p-4 border border-gray-300 bg-gray-100 rounded-md relative cursor-pointer">
                                                        <div className="absolute top-0 right-0 p-3">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faClipboard
                                                                }
                                                                className="text-teal-500 hover:text-teal-700"
                                                            />
                                                        </div>
                                                        {
                                                            courseInformation
                                                                .course
                                                                .shippingAddress
                                                        }
                                                    </div>
                                                </CopyToClipboard>
                                            ) : (
                                                <div>No address provided.</div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex flex-col h-full">
                                            <p className="text-lg font-semibold text-gray-700 mb-1">
                                                Training Address / Remote
                                                Connection Details
                                            </p>
                                            {courseInformation.course
                                                .trainingAddress ? (
                                                <CopyToClipboard
                                                    text={
                                                        courseInformation.course
                                                            .trainingAddress
                                                    }
                                                    onCopy={() =>
                                                        toast.success("Copied")
                                                    }
                                                >
                                                    <div className="flex-grow whitespace-pre-wrap break-words font-mono p-4 border border-gray-300 bg-gray-100 rounded-md relative cursor-pointer">
                                                        <div className="absolute top-0 right-0 p-3">
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faClipboard
                                                                }
                                                                className="text-teal-500 hover:text-teal-700"
                                                            />
                                                        </div>
                                                        {
                                                            courseInformation
                                                                .course
                                                                .trainingAddress
                                                        }
                                                    </div>
                                                </CopyToClipboard>
                                            ) : (
                                                <div>No address provided.</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Panel>

                            <Panel>
                                <p className="text-lg font-semibold text-gray-700">
                                    Students
                                </p>
                                <p className="text-gray-600 mb-1">
                                    Please enter a list of email addresses for
                                    students you want to enrol in this course.
                                    You can paste any block of text, we will
                                    automatically parse out any emails found.
                                </p>

                                {courseInformation.students.length +
                                    courseInformation.waiting_registration
                                        .length >=
                                    courseInformation.course.maxNumStudents || props.token.claims.includes("guest-instructor") ? (
                                    <div className="mb-3">
                                        <Alert color="red" icon={faHandPaper}>
                                            {props.token.claims.includes("guest-instructor") ?
                                                <div>As a Guest Instructor you can not add students to this course.</div>
                                                :
                                                <div>
                                                    Maximum number of students reached
                                                    (Enroled + Awaiting Registration).
                                                    Please edit the course to allow more
                                                    students to be enroled.
                                                </div>
                                            }
                                        </Alert>
                                    </div>
                                ) : (
                                    <div>
                                        <Textarea
                                            id="enrolStudents"
                                            name="enrolStudents"
                                            placeholder="Enter email addresses..."
                                            value={enrolStudentsText}
                                            onChange={setEnrolStudentsText}
                                        />
                                        {enrolStudentsText && (
                                            <div className="flex flex-wrap mb-3">
                                                {renderEmailPills(
                                                    enrolStudentsText
                                                )}
                                            </div>
                                        )}
                                        <div className="my-3">
                                            <Button
                                                color="green"
                                                onClick={() => enrolStudents()}
                                            >
                                                <FontAwesomeIcon
                                                    fixedWidth
                                                    icon={faPlusSquare}
                                                    className="mr-2"
                                                />
                                                Enrol Students
                                            </Button>
                                        </div>
                                    </div>
                                )}

                                {enroledStudents && (
                                    <Alert
                                        title="Successfully enroled students"
                                        color="green"
                                        className="mb-4"
                                    >
                                        <div className="grid grid-cols-4 gap-3 w-full">
                                            {enroledStudents.map(
                                                (student, index) => (
                                                    <div key={index}>
                                                        {student}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </Alert>
                                )}

                                <p className="text-lg font-semibold text-gray-700 mb-3">
                                    Students awaiting registration
                                </p>
                                <Table
                                    gridOptions={awaitingGridOptions}
                                    rowData={
                                        courseInformation.waiting_registration
                                    }
                                />

                                <div className="flex my-3">
                                    <div className="flex-grow">
                                        <p className="text-lg font-semibold text-gray-700 mb-3">
                                            Enroled Students (
                                            {courseInformation.students.length}{" "}
                                            /{" "}
                                            {
                                                courseInformation.course
                                                    .maxNumStudents
                                            }{" "}
                                            students){" "}
                                            {courseInformation
                                                .waiting_registration.length >
                                                0 &&
                                                `[${courseInformation.waiting_registration.length} awaiting registration]`}
                                        </p>
                                    </div>
                                    <div className="flex-shrink-0 flex flex-row space-x-2 my-auto">
                                        <Tippy
                                            placement="top"
                                            content="Update the course roster"
                                        >
                                            <span>
                                                <Button
                                                    color="cyan"
                                                    onClick={() =>
                                                        setCourseRosterModalOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCalendarCheck}
                                                        className="mr-2"
                                                    />
                                                    Roster
                                                </Button>
                                            </span>
                                        </Tippy>
                                        <Tippy
                                            placement="top"
                                            content="Sync Company Name to Students"
                                        >
                                            <span>
                                                <Button
                                                    color="orange"
                                                    onClick={() =>
                                                        setConfirmModalOpen(
                                                            !confirmModalOpen
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSync}
                                                        className="mr-2"
                                                    />
                                                    Sync Company
                                                </Button>
                                            </span>
                                        </Tippy>
                                    </div>
                                </div>

                                <Table
                                    gridOptions={enroledGridOptions}
                                    rowData={courseInformation.students}
                                />

                                <Modal
                                    isOpen={confirmModalOpen}
                                    setIsOpen={setConfirmModalOpen}
                                    closeWording={"Cancel"}
                                    headerContent={"Are you sure?"}
                                    footerContent={getConfirmButton()}
                                >
                                    This action will overwrite all enroled users
                                    "Company" field to match the "Customer Name"
                                    ({courseInformation.course.customer_name})
                                    of this course.
                                    <br />
                                    <br />
                                    Are you sure you want to continue?
                                </Modal>
                            </Panel>
                        </Container>
                    </div>

                    <CourseRosterModal
                        courseRosterModalOpen={courseRosterModalOpen}
                        setCourseRosterModalOpen={setCourseRosterModalOpen}
                        course={courseInformation}
                        getCourse={getCourse}
                    />
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
}

export default withRouter(CourseView);
