import React from "react";
import PropType from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function Input({
    ariaLabel,
    name,
    id,
    type,
    required,
    placeholder,
    onChange,
    value,
    step,
    autoComplete,
    disabled,
    hasError,
}) {
    return (
        <div className="relative">
            <input
                className={`${
                    hasError
                        ? "border-red-500 focus:border-red-700 focus:shadow-outline-red"
                        : "border-gray-300  focus:border-blue-300 focus:shadow-outline-blue"
                } placeholder-gray-400 appearance-none relative block w-full px-4 py-2 border text-gray-900 rounded-md focus:outline-none focus:z-10 disabled:cursor-not-allowed`}
                aria-label={ariaLabel}
                name={name}
                id={id}
                type={type}
                required={required}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                step={step}
                autoComplete={autoComplete}
                disabled={disabled}
            />
            {hasError && (
                <div className="absolute inset-y-0 right-0 py-2 px-4">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-red-500"
                    />
                </div>
            )}
        </div>
    );
}

Input.propTypes = {
    ariaLabel: PropType.string,
    name: PropType.string.isRequired,
    id: PropType.string.isRequired,
    type: PropType.string.isRequired,
    required: PropType.bool.isRequired,
    placeholder: PropType.string.isRequired,
    onChange: PropType.func.isRequired,
    value: PropType.string.isRequired,
    disabled: PropType.bool,
    autoComplete: PropType.string,
    hasError: PropType.bool,
};

Input.defaultProps = {
    ariaLabel: "no-aria-label",
    type: "text",
    disabled: false,
    required: false,
    autoComplete: "on",
    hasError: false,
    step: undefined,
    disabled: false,
};

export default Input;
