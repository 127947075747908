import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "../../Button/Button";
import Panel from "../../Panel/Panel";
import Alert from "../../Alert/Alert";
import extractCnFromDn from "../../../util/extractCnFromDn";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function verifyGeneratedCode(
    e,
    code,
    setToken,
    setSomethingWentWrong,
    setLoggedIn,
    needsToRedirect
) {
    e.preventDefault();

    axios
        .post(`${process.env.REACT_APP_API}/user/otpverify.json`, {
            code: code,
        })
        .then(({ data }) => {
            if (data.status.includes("OTP Verified")) {
                /**
                 * If the user needs to be redirected, redirect them here
                 */
                if (needsToRedirect !== null) {
                    console.log("Attempting to redirect user...");
                    window.location = needsToRedirect;
                }

                let parsedGroups = data.info.groups.map((group) =>
                    extractCnFromDn(group)
                );
                setToken({
                    profile: data.info.user,
                    claims: parsedGroups,
                });
            } else {
                setSomethingWentWrong(true);
                setLoggedIn(false);
            }
        })
        .catch((error) => {
            setSomethingWentWrong(true);
            setLoggedIn(false);
        });
}

export default function VerifyOtp({
    setLoggedIn,
    setToken,
    needsToRedirect,
    setSomethingWentWrong,
}) {
    const [verifyCode, setVerifyCode] = useState("");

    const codeInputRef = useRef();

    useEffect(() => {
        codeInputRef.current.focus();
    });

    return (
        <div className="mt-5 text-center sm:text-sm">
            <Panel>
                <p>
                    Please verify your login by entering the six digit code
                    generated by your authenticator application and clicking
                    "Verify" below.
                </p>
                <form className="mt-5">
                    <div className="rounded-md shadow-sm">
                        <div>
                            <input
                                aria-label="Verify code"
                                name="verify"
                                type="text"
                                required=""
                                className="border-gray-300 placeholder-gray-500 appearance-none relative block w-full px-4 py-3 border text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                                placeholder="Six digit code..."
                                onChange={(e) => setVerifyCode(e.target.value)}
                                value={verifyCode}
                                autoComplete="off"
                                ref={codeInputRef}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <Button
                            onClick={(e) =>
                                verifyGeneratedCode(
                                    e,
                                    verifyCode,
                                    setToken,
                                    setSomethingWentWrong,
                                    setLoggedIn,
                                    needsToRedirect
                                )
                            }
                            block
                            size="md"
                            color="gray"
                        >
                            Verify
                        </Button>
                    </div>
                </form>
            </Panel>
        </div>
    );
}
