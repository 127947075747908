import React from "react";
import Table from "../../Table/Table";
import CourseLink from "./CourseLink";
import CourseStatus from "../../CellRenderers/CourseResources/CourseStatus";

function CoursesTaken({ coursesTaught }) {
    const gridOptions = {
        getRowNodeId(data) {
            return data.course_id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Course",
                field: "curriculumName",
                cellRendererFramework: CourseLink,
            },
            {
                headerName: "Start Date",
                field: "startDate",
                sort: "desc",
            },
            {
                headerName: "Duration",
                field: "duration",
                width: 70
            },
            {
                headerName: "Status",
                field: "",
                width: 150,
                suppressSizeToFit: true,
                cellRendererFramework: CourseStatus,
            },
        ],
    };
    return <Table gridOptions={gridOptions} rowData={coursesTaught} />;
}

export default CoursesTaken;
