import React, { useState } from "react";
import Button from "../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axios from "axios";

function ForgotPassword({ setHasForgotPassword }) {
    const [emailAddress, setEmailAddress] = useState("");

    const verifyEmailAddress = () => {
        if (emailAddress === "" || !emailAddress.includes("@")) {
            toast.error("Please enter a valid email address");
            return false;
        }

        return true;
    };

    const submitForm = () => {
        let resetPromise = axios
            .post(
                `${process.env.REACT_APP_API}/users/set_forgot_token.json?email=${emailAddress}`
            )
            .then(({ data }) => setHasForgotPassword(false));

        toast.promise(resetPromise, {
            loading: "Sending reset email...",
            success: "Reset email sent",
            error: "Failed to send reset email",
        });
    };

    return (
        <form
            className="mt-5"
            onSubmit={(e) => {
                e.preventDefault();
                verifyEmailAddress() && submitForm();
            }}
        >
            <div className="rounded-md shadow-sm">
                <div>
                    <input
                        aria-label="Email Address"
                        name="emailAddress"
                        type="text"
                        required=""
                        className="border-gray-300 placeholder-gray-500 appearance-none relative block w-full px-4 py-3 border text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
                        placeholder="Enter your email address.."
                        onChange={(e) => setEmailAddress(e.target.value)}
                        value={emailAddress}
                    />
                </div>
            </div>
            <div className="mt-5">
                <div className="grid grid-cols-5 gap-3">
                    <div className="col-span-4">
                        <Button block size="md" type="submit" color="gray">
                            <div className="sm:text-sm">
                                <span className="absolute left-0 inset-y pl-3">
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="text-gray-600"
                                    />
                                </span>
                                Send password reset link
                            </div>
                        </Button>
                    </div>
                    <div>
                        <Button
                            block
                            size="md"
                            onClick={() => setHasForgotPassword(false)}
                            color="red"
                        >
                            <div className="sm:text-sm">Cancel</div>
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ForgotPassword;
