import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../../components/Breadcrumbs";
import Container from "../../components/Layout/Container";
import Loader from "../../components/Loader/Loader";
import ExpandablePanel from "../../components/Pages/Resources/ExpandablePanel";
import Panel from "../../components/Panel/Panel";
import groupByKey from "../../util/groupByKey";

const renderResourceList = (resources, getResources, searchString) => {
    let list = Object.keys(resources).map((resource, index) => {
        if (resource.toLowerCase().includes(searchString.toLowerCase())) {
            return (
                <ExpandablePanel
                    key={index}
                    title={resource}
                    resources={resources[resource]}
                    getResources={getResources}
                />
            );
        }

        return null;
    });

    if (list.every((element) => element === null)) {
        return (
            <div className="flex justify-center items-center h-32 col-span-4">
                <h1 className="text-2xl text-gray-600">
                    No resources found for "{searchString}", please try again.
                </h1>
            </div>
        );
    }

    return list;
};

function Resources() {
    const [resources, setResources] = useState(null);
    const [searchString, setSearchString] = useState("");

    const getResources = () => {
        axios
            .get(`${process.env.REACT_APP_API}/resources.json`)
            .then(({ data }) => {
                let formattedResources = groupByKey(
                    data.resources,
                    "curriculum_name",
                    { omitKey: true }
                );

                setResources(formattedResources);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {
        getResources();
    }, []);

    if (!resources) {
        return <Loader text={"Loading resources"} />;
    }

    return (
        <div className="w-full">
            <Helmet>
                <title>Resources</title>
            </Helmet>
            <Breadcrumbs
                crumbs={[
                    {
                        name: "Home",
                        to: "/",
                    },
                    {
                        name: "Resource Management",
                        to: "/resources",
                    },
                ]}
            />
            <Container>
                <Panel>
                    <div className="flex flex-row items-center mb-4">
                        <div>
                            <h1 className="text-xl font-bold">Resources</h1>
                        </div>
                        <div className="ml-auto">
                            <input
                                type="text"
                                className="bg-white border border-gray-300 rounded-md px-3 py-1"
                                placeholder="Search resources..."
                                onChange={(e) =>
                                    setSearchString(e.target.value)
                                }
                                value={searchString}
                            />
                        </div>
                    </div>
                    {renderResourceList(resources, getResources, searchString)}
                </Panel>
            </Container>
        </div>
    );
}

export default Resources;
