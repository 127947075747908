import React, { useState } from "react";
import Modal from "../../Modal/Modal";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import enGb from "date-fns/locale/en-GB";
import moment from "moment";
import Button from "../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast from "react-hot-toast";
import Table from "../../Table/Table";
import RosterToggle from "../../CellRenderers/Courses/RosterToggle";
registerLocale("en-gb", enGb);

function RosterModal({
    rosterModalOpen,
    setRosterModalOpen,
    user,
    course,
    getCourse,
}) {
    const [entryDate, setEntryDate] = useState(new Date());
    const [amAttended, setAmAttended] = useState(false);
    const [pmAttended, setPmAttended] = useState(false);
    const maxDate = moment(course.course.startDate, "YYYY-MM-DD")
        .add(course.curriculum.duration, "days")
        .toDate();

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    const submitEntry = () => {
        let submitPromise = axios
            .post(`${process.env.REACT_APP_API}/rosters.json`, {
                course_id: course.course.id,
                user_id: user.user_id,
                date: entryDate,
                am: amAttended,
                pm: pmAttended,
            })
            .then(() => getCourse())
            .then(() => setRosterModalOpen(false));

        toast.promise(submitPromise, {
            success: "Added roster entry",
            error: "Failed to add roster entry",
            loading: "Adding roster entry...",
        });
    };

    const usersPreviousRoster = course.roster
        .filter((item) => item.user_id === user.user_id)
        .map((item) => {
            return {
                date: item.date,
                am: item.am,
                pm: item.pm,
                user_id: item.user_id,
                roster_id: item.id,
            };
        });

    /**
     * Disable days that already have information
     */
    const excludeDates = usersPreviousRoster.map((item) => {
        return new Date(item.date);
    });
    const excludeDatesMoment = excludeDates.map((date) =>
        moment(date).format("YYYY-MM-DD")
    );

    /**
     * Set up the gridOptions for the table
     */
    const gridOptions = {
        getRowNodeId(data) {
            return data.roster_id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Date",
                field: "date",
                sort: "desc",
            },
            {
                headerName: "AM",
                field: "am",
                cellRendererFramework: RosterToggle,
                cellRendererParams: {
                    field: "am",
                },
            },
            {
                headerName: "PM",
                field: "pm",
                cellRendererFramework: RosterToggle,
                cellRendererParams: {
                    field: "pm",
                },
            },
        ],
    };

    return (
        <Modal
            isOpen={rosterModalOpen}
            setIsOpen={setRosterModalOpen}
            closeWording={"Done"}
            closeButtonColor={"green"}
            headerContent={`Roster entries for ${user.name}`}
        >
            <div className="min-h-screen">
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <div>
                        <p className="font-semibold text-sm text-gray-600 mb-1">
                            Entry Date
                        </p>
                        <ReactDatePicker
                            selected={entryDate}
                            onChange={(date) => setEntryDate(date)}
                            minDate={new Date(course.course.startDate)}
                            maxDate={maxDate}
                            filterDate={isWeekday}
                            excludeDates={excludeDates}
                            dateFormat={"yyyy-MM-dd"}
                            locale={"en-gb"}
                        />
                    </div>
                    <div>
                        <p className="font-semibold text-sm text-gray-600 mb-1">
                            AM / PM
                        </p>
                        <div className="flex">
                            <div
                                className={`w-1/2 cursor-pointer border py-2 px-3 rounded-l-md select-none ${
                                    amAttended
                                        ? "bg-green-100 border-green-300 text-green-700"
                                        : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                                onClick={() => setAmAttended(!amAttended)}
                            >
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={amAttended ? faCheckCircle : faCircle}
                                    className="mr-2"
                                />
                                AM
                            </div>
                            <div
                                className={`w-1/2 cursor-pointer border py-2 px-3 rounded-r-md select-none ${
                                    pmAttended
                                        ? "bg-green-100 border-green-300 text-green-700"
                                        : "bg-gray-100 border-gray-300 text-gray-700"
                                }`}
                                onClick={() => setPmAttended(!pmAttended)}
                            >
                                <FontAwesomeIcon
                                    fixedWidth
                                    icon={pmAttended ? faCheckCircle : faCircle}
                                    className="mr-2"
                                />
                                PM
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="font-semibold text-sm text-gray-600 mb-1">
                            Enter
                        </p>
                        <Button
                            color="green"
                            size="md"
                            block
                            onClick={() => submitEntry()}
                            disabled={excludeDatesMoment.includes(
                                moment(entryDate).format("YYYY-MM-DD")
                            )}
                        >
                            Submit
                        </Button>
                    </div>
                </div>

                <Table
                    insidePanel={false}
                    gridOptions={gridOptions}
                    rowData={usersPreviousRoster}
                />
            </div>
        </Modal>
    );
}

export default RosterModal;
