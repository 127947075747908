import React from "react";
import StudentCertificateDownload from "../../CellRenderers/Users/StudentCertificateDownload";
import Table from "../../Table/Table";
import CourseLink from "./CourseLink";
import CourseStatus from "../../CellRenderers/CourseResources/CourseStatus";

function CoursesTaken({ coursesTaken, token }) {
    const gridOptions = {
        getRowNodeId(data) {
            return data.course_id;
        },
        paginationPageSize: 15,
        columnDefs: [
            {
                headerName: "Course",
                field: "curriculumName",
                cellRendererFramework: CourseLink,
            },
            {
                headerName: "Start Date",
                field: "startDate",
                sort: "desc",
            },
            {
                headerName: "Duration",
                field: "duration",
                width: 70
            },
            {
                headerName: "Status",
                field: "",
                width: 150,
                suppressSizeToFit: true,
                cellRendererFramework: CourseStatus,
            },

            {
                headerName: "Actions",
                field: "",
                cellRendererFramework: StudentCertificateDownload,
            },
        ],
    };

    return <Table gridOptions={gridOptions} rowData={coursesTaken} />;
}

export default CoursesTaken;
