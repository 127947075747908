import { useState } from "react";
import lscache from "lscache";

export default function useToken() {
    const getToken = () => {
        const userToken = lscache.get("token");
        return userToken;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = (userToken) => {
        lscache.set("token", userToken, 60 * 4);
        setToken(userToken);
    };

    const removeToken = () => {
        lscache.flush();
    };

    return {
        removeToken,
        setToken: saveToken,
        token,
    };
}
