function Badge({ color, text, round, className }) {
    return (
        <div
            className={`${
                round
                    ? "rounded-full h-6 w-6 inline-flex items-center justify-center"
                    : "rounded inline-block px-2 py-1"
            } bg-${color}-100 text-${color}-700 font-bold text-sm ${className}`}
        >
            {text}
        </div>
    );
}

Badge.defaultProps = {
    color: "red",
    text: "Default Text",
    round: false,
    className: "",
};

export default Badge;
