import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Button/Button";
import Tippy from "@tippyjs/react";

function CloudActions(props) {
    return (
        <Tippy content="Web UI">
            <a
                target="_blank"
                href={`${props.data.url}`}
            >
                <Button color="teal">
                    <FontAwesomeIcon fixedWidth icon={faGlobe} />
                </Button>
            </a>
        </Tippy>
    );
}

export default CloudActions;
