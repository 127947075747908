import React from "react";
import PropType from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function Textarea({
    ariaLabel,
    name,
    id,
    required,
    placeholder,
    onChange,
    value,
    rows,
    hasError,
    description,
}) {
    return (
        <div className="relative">
            <textarea
                className={`${
                    hasError
                        ? "border-red-500 focus:border-red-700 focus:shadow-outline-red"
                        : "border-gray-300  focus:border-blue-300 focus:shadow-outline-blue"
                } placeholder-gray-400 appearance-none relative block w-full px-4 py-2 border text-gray-900 rounded-md focus:outline-none focus:z-10`}
                aria-label={ariaLabel}
                name={name}
                id={id}
                required={required}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                value={value}
                rows={rows}
            ></textarea>

            {hasError && (
                <div className="absolute inset-y-0 right-0 py-2 px-4">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-red-500"
                    />
                </div>
            )}
            {description && <div className="text-gray-600 text-xs mt-1">{description}</div>}
        </div>
    );
}

Textarea.propTypes = {
    ariaLabel: PropType.string,
    name: PropType.string.isRequired,
    id: PropType.string.isRequired,
    required: PropType.bool.isRequired,
    placeholder: PropType.string.isRequired,
    onChange: PropType.func.isRequired,
    value: PropType.string.isRequired,
    disabled: PropType.bool,
    hasError: PropType.bool,
    description: PropType.string,
};

Textarea.defaultProps = {
    ariaLabel: "no-aria-label",
    rows: 3,
    disabled: false,
    required: false,
    hasError: false,
    description: null,
};

export default Textarea;
